import firebase from 'firebase/app'
import React, { ReactElement, useCallback, useMemo } from 'react'
import useSession from '../auth/useSession'
import { Game, gameResource, GameSettings } from '../entities/Game'
import { gameplayStateResource } from '../entities/GameplayState'
import { playerSessionResource, setUserTeam } from '../entities/PlayerSession'
import { TBUser } from '../entities/TBUser'
import { gameTeamResource } from '../entities/Team'
import useDatabase from '../firebase/useDatabase'
import useRecord from '../firebase/useRecord'
import useRecordActions from '../firebase/useRecordActions'
import { getPromptAssets, getQueryAssets } from '../gameplay/handlers'
import generateGameId from '../utils/generateGameId'
import parseRemoteGameDescription from './remote/parseRemoteGameDescription'
import {
	fetchRemoteGameDescription,
	useRemoteGameDescription,
} from './remote/useRemoteGameDescriptions'
import { GameState } from './GameState'

type ChildrenFunction = (gameState: GameState) => ReactElement

interface Props {
	gameId: string | undefined
	mode: 'play' | 'create' | 'host' | 'spectate'
	allowedToHostAll: boolean
	onGameIdChange(gameId: string): void
	children: ChildrenFunction
	onCreate(gameId: string): void
}

/** Warning: do not call any hooks in the children function */
export function WithGameState({
	gameId,
	mode,
	allowedToHostAll,
	onGameIdChange,
	children,
	onCreate,
}: Props) {
	const session = useSession()
	const game = useRecord(gameResource, gameId)
	const recordActions = useRecordActions()

	const createGameAndUser = useCallback(
		async (
			gameName: string,
			userName: string | undefined,
			descriptionId: string
		) => {
			const gameId = await generateGameId()

			let resolvedUser: TBUser

			if (!session.loggedIn) {
				if (!userName)
					throw new Error('Must provide user name if no user logged in')
				resolvedUser = await session.logInAsGuest(userName)
			} else {
				resolvedUser = session.user
			}

			const { settings, assets } = await getRemoteGameInfo(descriptionId)

			// Actually create the game
			const ownerId = resolvedUser.id
			await recordActions.setRecord(gameResource, gameId, {
				name: gameName,
				creatorId: ownerId,
				createdOn: firebase.database.ServerValue.TIMESTAMP,
				ended: false,
				hostIds: [ownerId],
				settings,
				assets,
			})

			// Now go to the game
			onCreate(gameId)
		},
		[onCreate, recordActions, session]
	)

	if (mode === 'create') {
		return children({
			type: 'createGame',
			user: session.loggedIn ? session.user : undefined,
			createGame: createGameAndUser,
		})
	} else if (mode === 'host') {
		if (session.loggedIn) {
			if (game === undefined) {
				return children({
					type: 'hostLoadingInfo',
				})
			} else if (game) {
				if (allowedToHostAll || game.hostIds.includes(session.user.id)) {
					return (
						<HostGameFlow game={game} user={session.user} children={children} />
					)
				} else {
					return children({
						type: 'hostingNotAllowed',
					})
				}
			}
		}
	}

	if (session.loggedIn) {
		if (gameId) {
			if (game === null) {
				return children({
					type: 'selectGame',
					status: 'invalid',
					user: session.user,
					code: gameId,
					setCode: onGameIdChange,
				})
			} else if (game === undefined) {
				return children({
					type: 'selectGame',
					status: 'pending',
					user: session.user,
					code: gameId,
					setCode: onGameIdChange,
				})
			} else {
				if (game.ended) {
					return children({
						type: 'selectedGameEnded',
						game,
						user: session.user,
					})
				} else {
					if (mode === 'play') {
						if (game.hostIds.includes(session.user.id)) {
							return children({
								type: 'selectedGameNotPlayable',
								game,
								user: session.user,
							})
						} else {
							return (
								<SelectTeamFlow
									game={game}
									user={session.user}
									children={children}
								/>
							)
						}
					} else if (mode === 'spectate') {
						return (
							<SpectateFlow
								game={game}
								user={session.user}
								children={children}
							/>
						)
					} else {
						throw new Error('This should not happen')
					}
				}
			}
		} else {
			return children({
				type: 'selectGame',
				status: 'initial',
				user: session.user,
				code: undefined,
				setCode: onGameIdChange,
			})
		}
	} else {
		if (session.loading) {
			return children({
				type: 'initialLoad',
			})
		} else {
			return children({
				type: 'selectGame',
				status: 'initial',
				user: undefined,
				code: gameId,
				setCode: onGameIdChange,
			})
		}
	}
}

function HostGameFlow({
	game,
	user,
	children,
}: {
	game: Game
	user: TBUser
	children: ChildrenFunction
}) {
	const remoteGameDescription = useRemoteGameDescription(
		game.settings.descriptionId
	)
	const gameDescription = useMemo(() => {
		if (!remoteGameDescription) return undefined
		return parseRemoteGameDescription(remoteGameDescription)
	}, [remoteGameDescription])

	if (!gameDescription) {
		return children({
			type: 'hostLoadingInfo',
		})
	}

	return children({
		type: 'hostGame',
		game,
		user,
		gameDescription,
	})
}

function SelectTeamFlow({
	game,
	user,
	children,
}: {
	game: Game
	user: TBUser
	children: ChildrenFunction
}) {
	const playerSession = useRecord(playerSessionResource, user.id)
	const team = useRecord(
		[gameTeamResource, { gameId: game.id }],
		playerSession?.teamId
	)
	const gameplayState = useRecord(gameplayStateResource, game.id)
	const db = useDatabase()

	if (team === undefined || gameplayState === undefined) {
		return children({
			type: 'gameSelectedLoadingInfo',
			game,
			user,
		})
	}

	if (team) {
		return children({
			type: 'playing',
			game,
			user,
			team,
			gameplayState: gameplayState ?? undefined,
			leaveGame() {
				setUserTeam(db, game.id, user.id, undefined)
			},
		})
	} else {
		return children({
			type: 'selectTeam',
			game,
			user,
		})
	}
}

function SpectateFlow({
	game,
	user,
	children,
}: {
	game: Game
	user: TBUser
	children: ChildrenFunction
}) {
	const gameplayState = useRecord(gameplayStateResource, game.id)

	if (gameplayState === undefined) {
		return children({
			type: 'gameSelectedLoadingInfo',
			game,
			user,
		})
	}

	return children({
		type: 'spectating',
		user,
		game,
		gameplayState: gameplayState ?? undefined,
	})
}

async function getRemoteGameInfo(id: string) {
	const gameDescription = await fetchRemoteGameDescription(id)

	const settings: GameSettings = {
		descriptionId: id,
		pointMultiplier: gameDescription.settings?.pointsMultiplier,
	}

	const assets = parseRemoteGameDescription(gameDescription).slides.map(
		(slide) => {
			const assets: string[] = []
			if (slide.music) {
				assets.push(slide.music.url)
			}
			if (slide.type === 'info') {
				assets.push(...getQueryAssets(slide.query))
			} else if (slide.type === 'challenge') {
				assets.push(...getQueryAssets(slide.challengeDescription.query))
				for (const prompt of slide.challengeDescription.prompts) {
					assets.push(...getPromptAssets(prompt))
				}
			}
			return assets
		}
	)

	return { settings, assets }
}
