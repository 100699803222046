import { StateLink } from './StateLink'

export default function linkToProps(
	link: StateLink<string> | StateLink<string | undefined> | undefined
) {
	if (!link)
		return {
			// Need this so react doesn't complain
			// about switching from uncontrolled to controlled.
			value: '',
			onChange() {},
		}
	return {
		value: link[0] ?? '',
		onChange(e: any) {
			link[1](e.target.value)
		},
	}
}
