import React, {
	createContext,
	FunctionComponent,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import useElementRef from '../utils/useElementRef'
import useResizeObserver from '../utils/useResizeObserver'
import styles from './FancyBackground.module.css'
import Color from 'color'

interface Ripple {
	x: number
	y: number
	startTime: number
	// element: SVGCircleElement
	speed: number
}

interface Size {
	width: number
	height: number
}

const FBContext = createContext<FancyBackgroundActions | undefined>(undefined)

const rippleColor = Color('#d3b8e8').alpha(0.2).rgb().toString()

class Sim {
	ripples: Ripple[] = []
	context: CanvasRenderingContext2D
	size: Size
	scale: number

	constructor(context: CanvasRenderingContext2D, scale: number) {
		this.context = context
		this.size = { width: 0, height: 0 }
		this.scale = scale
	}

	update() {
		const scale = this.scale
		const time = new Date().getTime()
		const toRemove: number[] = []
		const ripples = this.ripples
		const len = ripples.length
		const { width, height } = this.size
		const maxRadius = Math.max(width, height) * 1.5
		const ctx = this.context
		ctx.clearRect(0, 0, width, height)

		for (let i = 0; i < len; ++i) {
			const ripple = ripples[i]
			const { startTime, x, y, speed } = ripple
			const dt = (time - startTime) / 1000

			// I ran into this issue when the browser was out of focus for a while.
			if (dt > 0) {
				const radius = dt * speed
				const lineWidth = dt * 20 + 10

				ctx.beginPath()
				ctx.arc(x * scale, y * scale, radius * scale, 0, Math.PI * 2)
				ctx.strokeStyle = rippleColor
				ctx.lineWidth = lineWidth * scale
				ctx.stroke()

				if (radius > maxRadius) {
					toRemove.push(i)
				}
			}
		}

		for (const i of toRemove) {
			ripples.splice(i, 1)
		}
	}

	addRipple(x: number, y: number, speed: number) {
		const element = document.createElementNS(
			'http://www.w3.org/2000/svg',
			'circle'
		)
		element.className.baseVal = styles.circle

		// this.element.appendChild(element)

		this.ripples.push({
			x,
			y,
			startTime: new Date().getTime(),
			// element,
			speed,
		})
	}
}

export interface FancyBackgroundActions {
	addRipple(x: number, y: number, speed: number): void
	setBackgroundColor(color: string | undefined): void
}

function useElementBoundingClientRect<T extends HTMLElement>() {
	// const elRef = useRef<T | null>(null)
	const [el, ref] = useElementRef<T>()
	const [rect, setRect] = useState<DOMRect>()
	useResizeObserver(el, () => {
		if (el) {
			const newRect = el.getBoundingClientRect()
			setRect((rect) => {
				if (rect) {
					if (
						rect.x === newRect.x &&
						rect.y === newRect.y &&
						rect.width === newRect.width &&
						rect.height === newRect.height
					) {
						return rect
					}
				}
				return newRect
			})
		}
	})

	return [rect, ref, el] as const
}

const canvasScale = 0.4

const FancyBackground: FunctionComponent = ({ children }) => {
	// const [svg, svgRef] = useElementRef<SVGSVGElement>()
	const [canvas, canvasRef] = useElementRef<HTMLCanvasElement>()
	const [elementBounds, elRef] = useElementBoundingClientRect<HTMLDivElement>()
	const [bgColor, setBgColor] = useState<string>()

	// const elRef = useRef<HTMLDivElement | null>(null)
	const simRef = useRef<Sim>()
	// const sizeRef = useRef<{ width: number; height: number } | undefined>()
	// useResizeObserver(elRef.current, () => {
	// 	if (elRef.current) {
	// 		const size = elRef.current.getBoundingClientRect()
	// 		sizeRef.current = size
	// 		simRef.current!.size = size
	// 	}
	// })

	const width = elementBounds?.width,
		height = elementBounds?.height

	const theRef = useMemo<FancyBackgroundActions>(() => {
		return {
			addRipple(x: number, y: number, speed: number) {
				const sim = simRef.current

				if (sim) {
					sim.addRipple(x, y, speed)
				}
			},
			setBackgroundColor(color: string | undefined) {
				setBgColor(color)
			},
		}
	}, [])

	useEffect(() => {
		const context = canvas?.getContext('2d')
		if (context) {
			const sim = new Sim(context, canvasScale)
			simRef.current = sim

			let h: any

			const update = () => {
				h = requestAnimationFrame(() => {
					sim.update()
					update()
				})
			}

			update()

			////
			return () => {
				cancelAnimationFrame(h)
				// DISPOSE
			}
		}
	}, [canvas])

	useEffect(() => {
		if (simRef.current && width != null && height != null) {
			simRef.current.size = { width, height }
		}
	}, [height, width])

	const bgStyle = useMemo(() => {
		if (!bgColor) return undefined
		return {
			background: `linear-gradient(#e8e6f6, ${bgColor})`,
		}
	}, [bgColor])

	return (
		<FBContext.Provider value={theRef}>
			<div ref={elRef} className={styles.style} style={bgStyle}>
				<canvas
					ref={canvasRef}
					width={width ? width * canvasScale : undefined}
					height={height ? height * canvasScale : undefined}
					className={styles.canvas}
				/>
			</div>
			{children}
		</FBContext.Provider>
	)
}

export default FancyBackground

export function useBGActions() {
	const actions = useContext(FBContext)
	if (!actions) throw new Error('aaa')
	return actions
}

export function useBGColor(color: string | undefined) {
	const actions = useBGActions()

	useEffect(() => {
		actions.setBackgroundColor(color)

		return () => {
			actions.setBackgroundColor(undefined)
		}
	}, [actions, color])
}

// export function RippleSource({
// 	children,
// }: {
// 	children: (ref: any) => ReactNode
// }) {
// 	const actions = useBGActions()
// 	const ref = useRef<HTMLDivElement | null>(null)

// 	useEffect(() => {
// 		const onTick = () => {
// 			if (!ref.current) return
// 			const rect = ref.current.getBoundingClientRect()
// 			actions.addRipple(
// 				ref.current!.offsetLeft + rect.width / 2,
// 				ref.current!.offsetTop + rect.height / 2,
// 				100
// 			)
// 		}

// 		const h = setInterval(onTick, 1500)

// 		onTick()

// 		return () => {
// 			clearInterval(h)
// 		}
// 	}, [actions])

// 	return children(ref) as ReactElement
// }
