import React from 'react'
import { GameplayStateView } from '../../entities/GameplayState'
import Button from '../../ui/Button'
export function SlideControls({
	currentView,
	numSlides,
	onIncrement,
	onEndGame,
}: {
	currentView: GameplayStateView
	numSlides: number
	onIncrement(amount: number): void
	onEndGame(): void
}) {
	return (
		<div>
			{currentView.slideIndex < numSlides - 1 ? (
				<Button mb={2} tone="green" onClick={() => onIncrement(1)}>
					Next
				</Button>
			) : (
				<Button mb={2} tone="pink" onClick={() => onEndGame()}>
					End Game
				</Button>
			)}
			{currentView.slideIndex !== 0 && (
				<Button flat tone="grayLight" onClick={() => onIncrement(-1)}>
					Previous
				</Button>
			)}
		</div>
	)
}
