export type ListenerFunction<T> = (value: T) => void
export type ListenersController<T> = {
	add(fn: (value: T) => void): () => void
	dispatch(value: T): void
}

export default function createListeners<T>(): ListenersController<T> {
	const listeners: ListenerFunction<T>[] = []

	return {
		add(fn) {
			listeners.push(fn)
			return () => {
				const index = listeners.indexOf(fn)
				if (index >= 0) {
					listeners.splice(index, 1)
				}
			}
		},
		dispatch(value) {
			const listenersCopy = [...listeners]
			for (const fn of listenersCopy) {
				fn(value)
			}
		},
	}
}
