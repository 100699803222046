import React, { useState } from 'react'
import LargeHeader from '../../components/LargeHeader'
import { TBUser } from '../../entities/TBUser'
import useFetchGames from '../../game/remote/useRemoteGameDescriptions'
import Button from '../../ui/Button'
import Input from '../../ui/Input'
import Select from '../../ui/Select'
import useAsyncTask from '../../utils/useAsyncTask'

interface Props {
	user: TBUser | undefined
	onCreateGame(
		gameName: string,
		userName: string | undefined,
		descriptionId: string
	): Promise<void>
}

export default function CreateGameScreen({ user, onCreateGame }: Props) {
	const [userName, setUserName] = useState<string>()
	const [gameName, setGameName] = useState<string>()
	const selectedGameId = useState<string>()

	const games = useFetchGames()

	const createTask = useAsyncTask()

	const createGame = async () => {
		if (!user && !userName) return
		if (!gameName) return
		if (!games) return
		const [id] = selectedGameId
		if (id == null) return

		createTask.start(onCreateGame(gameName, userName, id))
	}

	return (
		<>
			<LargeHeader mb={4} title="Create a new game" />
			{!user && (
				<Input
					mb={2}
					valueState={[userName, setUserName]}
					placeholder="Your name"
				/>
			)}
			<Input
				valueState={[gameName, setGameName]}
				placeholder="Name your game"
				mb={2}
			/>
			<Select
				mb={4}
				selectedState={selectedGameId}
				placeholder="Select one"
				options={games?.map((g) => ({
					value: g._id,
					label: g.name,
				}))}
			/>
			<Button
				tone="green"
				disabled={createTask.status === 'pending'}
				onClick={createGame}
			>
				Create
			</Button>
		</>
	)
}
