import { GameSlideDescription } from '../../gameplay/core/GameSlideDescription'

export default function getSlideIndexOfType(
	slides: GameSlideDescription[],
	type: GameSlideDescription['type'],
	slideIndex: number
) {
	let challengeIndex = -1
	for (let i = 0; i <= slideIndex; ++i) {
		const slide = slides[i]
		if (slide.type === type) {
			++challengeIndex
		}
	}
	return challengeIndex
}
