import { normalizeResourceAndOptions } from './FBResource'
import setRecord from './setRecord'
import { FBResourceAndOptions, FBValue, NewRecord } from './types'

export default function addRecord<T, V extends FBValue, O>(
	db: firebase.database.Database,
	r: FBResourceAndOptions<T, V, O>,
	newRecord: NewRecord<T>
) {
	const path = normalizeResourceAndOptions(r)[1]

	const ref = db.ref(path)

	const newId = ref.push().key

	if (!newId) throw new Error('Did not get a new id')

	return setRecord(db, r, newId, newRecord)
}
