import firebase from 'firebase'
import { useCallback } from 'react'
import {
	CompletedChallengeTeamInfo,
	GameplayState,
	GameplayStateView,
	gameplayStateResource,
} from '../../entities/GameplayState'
import { TeamChallengeResponse } from '../../entities/PendingChallengeResponse'
import useDatabase from '../../firebase/useDatabase'
import { useEditableRecord } from '../../firebase/useRecord'
import { GameSlideDescription } from '../../gameplay/core/GameSlideDescription'
import getChallengeFromDescription from '../../gameplay/helpers/getChallengeFromDescription'
import { NewRecord } from '../../libraries/firebase-utils/types'
import getSlideIndexOfType from './getSlideIndexOfType'
import { Scoring } from './HostActiveChallengeView'

export default function useHostActions(
	slides: GameSlideDescription[],
	gameId: string
) {
	const [gameplayState, setGameplayState] = useEditableRecord(
		gameplayStateResource,
		gameId
	)
	const db = useDatabase()

	// undefined means unstarted
	// null means ended
	// number means in play
	const gameplaySlideIndex = gameplayState
		? gameplayState.currentView
			? gameplayState.currentView.slideIndex
			: null
		: undefined

	const setGameplaySlideIndex = useCallback(
		(index: number) => {
			const slide = slides[index]

			if (slide) {
				// Reset the buzz for the new question before we use it
				// TODO: Refactor this ref string
				db.ref(`/buzz/${gameId}/${index}`).set(null)

				const last = index >= slides.length - 1

				setGameplayState((state) => {
					let currentView: GameplayStateView

					let musicStartTime:
						| typeof firebase.database.ServerValue.TIMESTAMP
						| Date
						| undefined

					const id = index + ''
					if (slide.type === 'challenge') {
						currentView = {
							type: 'challenge',
							slideIndex: index,
							challengeIndex: getSlideIndexOfType(slides, 'challenge', index),
							challenge: getChallengeFromDescription(
								slide.challengeDescription,
								1
							),
							responsesByTeam: {},
							last,
							// TODO
							startTime: firebase.database.ServerValue.TIMESTAMP as Date,
						}
					} else if (slide.type === 'info') {
						currentView = {
							type: 'info',
							slideIndex: index,
							query: slide.query,
							last,
							// TODO
							startTime: firebase.database.ServerValue.TIMESTAMP as Date,
						}
					} else {
						console.error('Unknown slide type', slide)
						return state
					}

					if (slide.music) {
						const { url, fill, loop } = slide.music
						currentView.music = {
							url,
							startOffset: 1000,
							loop: loop ?? false,
							fill: fill ?? false,
							key: 'slide-' + id,
						}

						// Start the song now
						musicStartTime = firebase.database.ServerValue.TIMESTAMP
					} else {
						// Find the most recent slide that had fill music
						let recent: number = -1
						for (let i = index - 1; i >= 0; --i) {
							const slide = slides[i]
							if (slide.music) {
								if (slide.music.fill) {
									recent = i
								}
								break
							}
						}

						if (recent >= 0) {
							const { url, loop } = slides[recent].music!
							currentView.music = {
								url,
								key: 'slide-' + recent,
								fill: true,
								loop: loop ?? false,
								startOffset: 1000,
							}

							// If there's already music playing, use that anchor
							// otherwise start now.
							musicStartTime =
								state?.musicStartTime || firebase.database.ServerValue.TIMESTAMP
						} else {
							// There's no music
							musicStartTime = undefined
						}
					}

					let newState: NewRecord<GameplayState>

					if (state) {
						newState = {
							...state,
							currentView,
							// TODO
							musicStartTime: musicStartTime as Date,
							status: 'respondingActive',
						}
					} else {
						newState = {
							status: 'respondingActive',
							currentView,
							completedChallenges: {},
							// TODO
							musicStartTime: musicStartTime as Date,
							startTime: firebase.database.ServerValue.TIMESTAMP,
						}
					}

					return newState
				})
			}
		},
		[db, gameId, setGameplayState, slides]
	)

	const endGame = useCallback(() => {
		// TODO: Delete all the pendingChallengeResponses for this game
		db.ref().update({
			[`/games/${gameId}/ended`]: true,
			[`/gameStates/${gameId}`]: null,
		})
	}, [db, gameId])

	const endQuestion = useCallback(
		async (
			pendingResponses: TeamChallengeResponse[] | undefined,
			scoring: Scoring
		) => {
			if (!gameplayState) return
			if (gameplayState.currentView.type !== 'challenge') {
				console.error('Cannot end question if it not a challenge')
			}

			const slideIndex = gameplayState.currentView.slideIndex
			const slide = slides[slideIndex]
			if (slide.type !== 'challenge') {
				console.error(
					'Current view type is challenge but current slide type ',
					slide.type
				)
				return
			}

			const byTeam: { [teamId: string]: CompletedChallengeTeamInfo } = {}

			for (const teamId in scoring) {
				const teamScoring = scoring[teamId]
				const response = pendingResponses?.find((p) => p.teamId === teamId)

				byTeam[teamId] = {
					challengeResponse: response,
					promptScores: teamScoring.map((s) => ({
						correct: s.correct ?? false, // No decision means wrong answer
						points: s.points,
					})),
				}
			}

			setGameplayState((state) => {
				if (!state) return state
				return {
					...state,
					status: 'questionEnded',
					completedChallenges: {
						...state.completedChallenges,
						[slideIndex]: {
							challengeDescription: slide.challengeDescription,
							responsesByTeam: byTeam,
						},
					},
				}
			})
		},
		[gameplayState, setGameplayState, slides]
	)

	// TODO: Finalize
	const resetGame = useCallback(() => {
		db.ref().update({
			[`/gameStates/${gameId}`]: null,
			[`/pendingChallengeResponses/${gameId}`]: null,
			[`/completedChallengeResponses/${gameId}`]: null,
		})
	}, [db, gameId])

	const setAnsweringPaused = useCallback(
		(paused: boolean) => {
			setGameplayState((state) => {
				if (!state) return state
				// Just to be sure this isn't done at the wrong time.
				if (
					state.status !== 'respondingActive' &&
					state.status !== 'respondingInactive'
				) {
					return state
				}

				return {
					...state,
					status: paused ? 'respondingInactive' : 'respondingActive',
				}
			})
		},
		[setGameplayState]
	)

	const resetQuestion = useCallback(() => {
		if (gameplaySlideIndex) {
			setGameplaySlideIndex(gameplaySlideIndex)
		}
	}, [gameplaySlideIndex, setGameplaySlideIndex])

	return {
		gameplayState,
		setGameplaySlideIndex,
		gameplaySlideIndex,
		gameplaySlideDescription: gameplaySlideIndex
			? slides[gameplaySlideIndex]
			: undefined,
		setAnsweringPaused,
		resetQuestion,
		endQuestion,
		resetGame,
		endGame,
	}
}
