import React, { useEffect, useState } from 'react'
import LargeHeader from '../../components/LargeHeader'
import MessagePane from '../../components/MessagePane'
import { Game } from '../../entities/Game'
import { GameplayState } from '../../entities/GameplayState'
import { TBUser } from '../../entities/TBUser'
import { Team } from '../../entities/Team'
import UserNameFormatter from '../../formatters/UserNameFormatter'
import { ActiveGameView } from './ActiveGameView'
import { IntroView } from './IntroView'
import Song from '../../libraries/sound-manager/Song'

const introDuration = 1000 * (process.env.NODE_ENV === 'production' ? 5 : 3)

export interface Props {
	game: Game
	user: TBUser
	// If there's no team it's a spectator
	team?: Team
}

export default function PlayGameScreen({
	game,
	user,
	team,
	gameplayState,
}: Props & {
	gameplayState: GameplayState | undefined
}) {
	const [introDetails, setIntroDetails] = useState<{ endTime: number }>()

	useEffect(() => {
		if (introDetails) {
			const timeLeft = introDetails.endTime - new Date().getTime()
			if (timeLeft > 0) {
				const h = setTimeout(() => {
					setIntroDetails(undefined)
				}, timeLeft)

				return () => clearTimeout(h)
			}
		}
	})

	useEffect(() => {
		if (gameplayState && !introDetails) {
			const now = new Date().getTime()
			const gameStartTime = gameplayState.startTime.getTime() + introDuration
			if (now < gameStartTime) {
				setIntroDetails({ endTime: gameStartTime })
			}
		}
	}, [gameplayState, introDetails])

	return introDetails ? (
		<IntroView endTime={introDetails.endTime} />
	) : gameplayState ? (
		<ActiveGameView
			game={game}
			gameplayState={gameplayState}
			user={user}
			team={team}
		/>
	) : (
		<>
			<Song
				source="/assets/music/casual.mp3"
				startTime={game.createdOn.getTime()}
				loop
			/>
			<LargeHeader
				mb={4}
				title={game.name}
				subtitle={
					<>
						By <UserNameFormatter value={game.creatorId} />
					</>
				}
			/>
			<MessagePane title="You're in!">
				Turn up your volume while we wait for{' '}
				<UserNameFormatter value={game.creatorId} /> to start the game. Make
				sure your device isn't set to vibrate.
			</MessagePane>
		</>
	)
}
