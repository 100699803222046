/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ComponentProps, ReactNode, useState } from 'react'
import linkToProps from '../utils/linkToProps'
import { StateLink } from '../utils/StateLink'
import Box from './Box'
import sharedStyles from './sharedStyles'
import styled from './styled'
import theme from './theme'

interface Props {
	label?: ReactNode
	placeholder?: string
	valueState?: StateLink<string | undefined>
	disabled?: boolean
	autoFocus?: boolean
	noAutoThings?: boolean
}

const inputStyle = [
	{
		display: 'block',
		width: '100%',
		border: 'none',
		outline: 'none',
		background: 'none',
		':focus': {
			// borderColor: 'red',
		},
	},
	{
		padding: theme.space[sharedStyles.padding],
		fontSize: 18,
	},
]

const Input = styled<Props>(
	({
		css,
		label,
		placeholder,
		valueState,
		disabled,
		autoFocus,
		noAutoThings,
	}) => {
		const [focused, setFocused] = useState(false)

		const inputProps: ComponentProps<'input'> = {}

		if (noAutoThings) {
			// https://davidwalsh.name/disable-autocorrect
			inputProps.autoComplete = 'off'
			inputProps.autoCorrect = 'off'
			inputProps.autoCapitalize = 'off'
			inputProps.spellCheck = 'false'
		}

		return (
			<Box css={css}>
				{label && (
					<Box css={{ fontSize: 16 }} p={1}>
						{label}
					</Box>
				)}
				<Box
					css={[
						{
							// boxShadow: sharedStyles.boxShadow,
							backgroundColor: sharedStyles.colors.white,
							transition: 'border-color 0.3s ease',
						},
						sharedStyles.boxBase,
						{
							borderWidth: 2,
							borderStyle: 'solid',
							borderColor: 'rgba(0,0,0,0.1)',
						},
						focused
							? {
									borderColor: sharedStyles.colors.blue,
							  }
							: {
									':hover': {
										borderColor: sharedStyles.colors.gray,
									},
							  },
					]}
				>
					<input
						{...inputProps}
						{...linkToProps(valueState)}
						onFocus={() => setFocused(true)}
						onBlur={() => setFocused(false)}
						placeholder={placeholder}
						css={[inputStyle]}
						disabled={disabled}
						autoFocus={autoFocus}
					/>
				</Box>
			</Box>
		)
	}
)

export default Input
