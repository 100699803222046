import { equals } from 'ramda'
import { useCallback, useState } from 'react'
import { SetRecordAction } from '../libraries/firebase-utils/setRecord'
import {
	FBId,
	FBResourceAndOptions,
	FBValue,
} from '../libraries/firebase-utils/types'
import useMemoWith from '../utils/useMemoWith'
import useRecordActions from './useRecordActions'
import { useWatchRecord, useWatchRecords } from './useWatchRecord'

export default function useRecord<T, V extends FBValue, O>(
	r: FBResourceAndOptions<T, V, O>,
	id: FBId | undefined
) {
	r = useMemoFBResourceAndOptions(r)

	const [record, setRecord] = useState<T | null | undefined>(undefined)

	// TODO: Switch so that null is loading
	const onChange = useCallback(
		(record: T | undefined) => setRecord(record ?? null),
		[]
	)

	useWatchRecord(r, id, onChange)

	// If user didn't provide id we don't want it to seem like
	// it's loading.
	return id ? record : null
}

export function useEditableRecord<T, V extends FBValue, O>(
	r: FBResourceAndOptions<T, V, O>,
	id: FBId | undefined
) {
	r = useMemoFBResourceAndOptions(r)
	const recordActions = useRecordActions()

	const record = useRecord(r, id)

	const setter = useCallback(
		(action: SetRecordAction<T>) => {
			if (!id) return
			recordActions.setRecord(r, id, action)
		},
		[id, r, recordActions]
	)

	return [record, setter] as const
}

export function useRecords<T, V extends FBValue, O>(
	r: FBResourceAndOptions<T, V, O>
) {
	r = useMemoFBResourceAndOptions(r)

	const [records, setRecords] = useState<T[] | null | undefined>(undefined)

	const onChange = useCallback(
		(records: T[] | undefined) => setRecords(records ?? null),
		[]
	)

	useWatchRecords(r, onChange)

	return records
}

/////

export function useMemoFBResourceAndOptions<T, V extends FBValue, O>(
	r: FBResourceAndOptions<T, V, O>
) {
	return useMemoWith(r, (a, b) => {
		if (Array.isArray(a)) {
			if (Array.isArray(b)) {
				return a[0] === b[0] && equals(a[1], b[1])
			} else {
				return false
			}
		} else {
			return equals(a, b)
		}
	})
}
