import React, { ReactElement } from 'react'
import { ChallengePrompt } from '../gameplay/core/types'
import { getPromptHandler } from '../gameplay/handlers'
import { StateLink } from '../utils/StateLink'

interface Props {
	prompt: ChallengePrompt
	responseLink: StateLink<any> | undefined
	disabled?: boolean
	staticMode?: boolean
}

export default function PromptView({
	prompt,
	disabled = false,
	responseLink,
	staticMode,
}: Props) {
	const handler = getPromptHandler(prompt.type)

	if (!handler) return <>Invalid prompt type: {prompt.type}</>

	if (!staticMode || !handler.renderStaticView) {
		return handler.renderInputView(
			{
				data: prompt.data,
				points: prompt.points,
			},
			responseLink,
			disabled || staticMode || false
		) as ReactElement
	} else {
		return handler.renderStaticView(prompt.data, prompt.points) as ReactElement
	}
}

export function PromptResponseView({
	promptType,
	promptData,
	response,
}: {
	promptType: string
	promptData: any
	response: any
}) {
	return getPromptHandler(promptType).renderUserResponse(
		response,
		promptData
	) as ReactElement
}
