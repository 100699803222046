import React from 'react'
import Box from '../ui/Box'
import styled from '../ui/styled'
import sharedStyles from './sharedStyles'

interface Props {}

const Label = styled<Props>(({ css, children }) => {
	return (
		<Box css={[sharedStyles.textBase, { fontWeight: 600, fontSize: 20 }, css]}>
			{children}
		</Box>
	)
})

export default Label
