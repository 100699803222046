import {
	deserializeIdMap,
	FBIdMap,
	serializeIdMap,
} from '../libraries/firebase-utils/FBIdMap'
import { FBResource } from '../libraries/firebase-utils/FBResource'

export interface Team {
	readonly id: string
	readonly gameId: string
	name: string
	memberIds: string[]
}

export const gameTeamResource: FBResource<
	Team,
	{
		name: string
		members: FBIdMap
	},
	{
		gameId: string
	}
> = {
	path: ({ gameId }) => `teams/${gameId}`,
	deserialize: ({ name, members }, id, { gameId }) => ({
		id,
		name,
		memberIds: deserializeIdMap(members),
		gameId,
		// TEMP
		members: {},
	}),
	serialize: ({ name, memberIds }) => {
		return {
			name,
			members: serializeIdMap(memberIds),
		}
	},
}
