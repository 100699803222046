import {
	ChallengePromptDescription,
	ChallengePrompt,
} from '../../gameplay/core/types'
import { getPromptHandler } from '../../gameplay/handlers'
import React from 'react'
import Box from '../../ui/Box'
import ScoreFormatter from '../../game/ScoreFormatter'

export default function HostAnswersView({
	prompts,
	promptDescriptions,
}: {
	promptDescriptions: ChallengePromptDescription[]
	prompts?: ChallengePrompt[]
}) {
	return (
		<>
			{promptDescriptions.map((p, i) => {
				const handle = getPromptHandler(p.type)
				return (
					<Box mb={1} key={i}>
						<div>
							{handle.renderLabel?.(p.data)}
							{prompts && (
								<>
									&nbsp;(
									<ScoreFormatter
										value={prompts[i].points}
										context="n-points"
									/>
									)
								</>
							)}
						</div>
						<strong>
							<div>
								{handle.renderCorrectAnswer(
									promptDescriptions[i].answer,
									p.data
								)}
							</div>
						</strong>
					</Box>
				)
			})}
		</>
	)
}
