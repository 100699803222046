import { GameQueryHandler } from '../core/handlerTypes'
import { GameQuery } from '../core/types'

export default function createGameQuery<T, TT>(
	queryHandler: GameQueryHandler<T, TT>,
	data: T
): GameQuery<TT> {
	return {
		type: queryHandler.type,
		data,
	}
}
