import { uniq } from 'ramda'
import { Asset } from './Asset'
import loadUrls from './loadUrls'
import createListeners, {
	ListenersController,
} from '../../utils/createListeners'

export type AssetStatus = 'inQueue' | 'pending' | 'loaded' | undefined

function createAssetManager() {
	let queue: string[][] = []
	const assets = new Map<string, Asset>()
	const urlStatusListeners = new Map<string, ListenersController<AssetStatus>>()

	let currentBatch: ReturnType<typeof loadUrls> | undefined

	function processQueue() {
		if (currentBatch) return
		if (queue.length <= 0) return

		const urls = queue.shift()!

		currentBatch = loadUrls(urls, {
			onDone() {
				currentBatch = undefined
				processQueue()
			},
			onAssetLoaded(url, asset) {
				assets.set(url, asset)
				urlStatusListeners.get(url)?.dispatch(getUrlStatus(url))
			},
		})

		if (currentBatch) {
			for (const url of urls) {
				urlStatusListeners.get(url)?.dispatch(getUrlStatus(url))
			}
		}
	}

	function isUrlInSystem(url: string) {
		return getUrlStatus(url) !== undefined
	}

	function add(urls: string[]) {
		urls = uniq(urls)
		urls = urls.filter((url) => !isUrlInSystem(url))
		if (urls.length > 0) {
			queue.push(urls)
			processQueue()
		}
	}
	function remove(url: string) {
		if (!isUrlInSystem(url)) return false

		// NOTE: The url should only ever be in just one of these
		// but we remove from all just in case.

		for (const urls of queue) {
			const index = urls.indexOf(url)
			if (index >= 0) {
				urls.splice(index, 1)
			}
		}

		currentBatch?.cancelPendingUrl(url)
		assets.delete(url)

		return true
	}

	function dispose() {
		queue = []
		assets.clear()
		if (currentBatch) {
			currentBatch.cancel()
			currentBatch = undefined
		}
	}

	function getUrlStatus(url: string) {
		for (const urls of queue) {
			if (urls.includes(url)) return 'inQueue'
		}
		if (currentBatch) {
			if (currentBatch.isUrlPending(url)) return 'pending'
		}
		if (assets.has(url)) return 'loaded'

		return undefined
	}

	function watchUrl(url: string, fn: (status: AssetStatus) => void) {
		fn(getUrlStatus(url))

		let listeners = urlStatusListeners.get(url)
		if (!listeners) {
			listeners = createListeners<AssetStatus>()
			urlStatusListeners.set(url, listeners)
		}

		return listeners.add(fn)
	}

	return { add, remove, watchUrl, dispose }
}

export default createAssetManager()
