import Color from 'color'
import React from 'react'
import { FaCheck, FaTimes, FaTrash } from 'react-icons/fa'
import { TeamChallengeResponse } from '../../entities/PendingChallengeResponse'
import TeamNameFormatter from '../../formatters/TeamNameFormatter'
import ScoreFormatter from '../../game/ScoreFormatter'
import { ChallengePrompt } from '../../gameplay/core/types'
import Card from '../../ui/Card'
import CardContent from '../../ui/CardContent'
import CardHeader from '../../ui/CardHeader'
import IconButton from '../../ui/IconButton'
import Item from '../../ui/Item'
import sharedStyles from '../../ui/sharedStyles'
import { PromptResponseView } from '../../views/PromptView'
export function TeamPromptResponseView({
	response,
	prompts,
	onRemoveResponse,
	onScoreAnswer,
	isAnswerCorrect,
	teamId,
	gameId,
}: {
	teamId: string
	gameId: string
	response: TeamChallengeResponse | undefined
	prompts: ChallengePrompt[]
	onRemoveResponse(teamId: string): void
	onScoreAnswer(teamId: string, index: number, correct: boolean): void
	isAnswerCorrect(teamId: string, promptIndex: number): boolean | undefined
}) {
	return (
		<Card mb={3} flat>
			<CardHeader
				title={
					<>
						<TeamNameFormatter value={teamId} gameId={gameId} />
					</>
				}
				accessory={
					response ? (
						<IconButton
							onClick={() => {
								if (
									window.confirm(
										"This will clear out the team's response and let them answer again. Are you sure?"
									)
								) {
									onRemoveResponse(teamId)
								}
							}}
						>
							<FaTrash />
						</IconButton>
					) : (
						'No response yet'
					)
				}
			/>
			{prompts.map((prompt, i) => {
				const answerIsCorrect = isAnswerCorrect(teamId, i)
				const res = response?.promptResponses[i]

				return (
					<CardContent
						css={[
							{
								borderTop: '1px solid',
								borderColor: sharedStyles.colors.grayLight,
							},
							res === undefined && {
								opacity: 0.6,
								backgroundColor: 'rgba(0,0,0,0.1)',
							},
							answerIsCorrect === true
								? skin.item.correctStyle
								: answerIsCorrect === false
								? skin.item.incorrectStyle
								: undefined,
						]}
						key={i}
					>
						<Item
							title={
								res !== undefined ? (
									<>
										<>{i + 1}</>.{' '}
										<PromptResponseView
											promptType={prompt.type}
											promptData={prompt.data}
											response={res}
										/>
									</>
								) : (
									<>No response</>
								)
							}
							subtitle={
								<ScoreFormatter context="n-points" value={prompt.points} />
							}
							accessory={
								<>
									<IconButton
										mr={4}
										css={[
											skin.button.style,
											answerIsCorrect === false
												? skin.button.activeStyle
												: skin.button.inactiveStyle,
										]}
										color={answerIsCorrect === false ? 'red' : undefined}
										onClick={() => onScoreAnswer(teamId, i, false)}
									>
										<FaTimes />
									</IconButton>
									<IconButton
										css={[
											skin.button.style,
											answerIsCorrect === true
												? skin.button.activeStyle
												: skin.button.inactiveStyle,
										]}
										color={answerIsCorrect === true ? 'green' : undefined}
										onClick={() => onScoreAnswer(teamId, i, true)}
									>
										<FaCheck />
									</IconButton>
								</>
							}
						/>
					</CardContent>
				)
			})}
		</Card>
	)
}
const skin = {
	button: {
		style: {
			width: 40,
			height: 30,
		},
		activeStyle: {},
		inactiveStyle: {
			opacity: 0.5,
		},
	},
	item: {
		correctStyle: {
			backgroundColor: Color(sharedStyles.colors.green).alpha(0.1).toString(),
		},
		incorrectStyle: {
			backgroundColor: Color(sharedStyles.colors.red).alpha(0.1).toString(),
		},
	},
}
