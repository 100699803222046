import { ReactNode, cloneElement, isValidElement } from 'react'

export default function joinElements(
	elements: ReactNode[],
	separator: ReactNode
) {
	const out: ReactNode[] = []
	for (let i = 0; i < elements.length; ++i) {
		out.push(elements[i])
		if (i < elements.length - 1) {
			if (isValidElement(separator)) {
				out.push(
					cloneElement(separator, {
						key: 'je-sep-' + i,
					})
				)
			} else {
				out.push(separator)
			}
		}
	}

	return out
}
