/** @jsx jsx */
import { jsx } from '@emotion/core'
import MessagePane from '../../components/MessagePane'
import stringifyRichText, { RichText } from '../../ui/RichText'
import RichTextView from '../../views/RichTextView'
import { GameQueryHandler } from '../core/handlerTypes'
import getFileTypeForPath from '../../utils/getFileTypeForPath'

const simpleQuery: GameQueryHandler<{ text?: RichText; media?: string }> = {
	type: 'simple',
	getAssetUrls: ({ media }) => (media ? [media] : []),
	render: ({ text, media }) => {
		return (
			<MessagePane mb={4} media={media && <Media source={media} />}>
				{text && <RichTextView value={text} />}
			</MessagePane>
		)
	},
	getSummary({ text }) {
		return stringifyRichText(text)
	},
}

export default simpleQuery

function Media({ source }: { source: string }) {
	if (getFileTypeForPath(source) !== 'image') return null

	return (
		<div css={{ textAlign: 'center', backgroundColor: 'rgba(0,0,0,0.7)' }}>
			<img
				css={{
					maxWidth: '100%',
					display: 'block',
					maxHeight: 400,
					margin: '0 auto',
				}}
				src={source}
				alt={source}
			/>
		</div>
	)
}
