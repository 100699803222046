import { useState, useEffect } from 'react'
import sanityClient from '@sanity/client'
import remoteConfig from './remoteConfig'
import { RemoteGameDescription } from './remoteTypes'

const client = sanityClient(remoteConfig)

export default function useRemoteGameDescriptions() {
	const [games, setGames] = useState<
		Pick<RemoteGameDescription, '_id' | 'name'>[] | null | undefined
	>(null)

	useEffect(() => {
		client
			.fetch("*[_type == 'game']{ _id, name, settings }")
			.then((data: any) => {
				setGames(data)
			})
	}, [])

	return games
}

export function useRemoteGameDescription(id: string) {
	const [game, setGame] = useState<RemoteGameDescription | null | undefined>(
		null
	)

	useEffect(() => {
		fetchRemoteGameDescription(id).then(setGame)
	}, [id])

	return game
}

export function fetchRemoteGameDescription(
	id: string
): Promise<RemoteGameDescription> {
	return client
		.fetch(`*[_type == 'game' && _id == '${id}']`)
		.then((d) => d?.[0])
}
