import { FBCollection, FBValue } from './types'

export function deserializeAny(value: string | null): any {
	if (value == null) return undefined
	return JSON.parse(value)
}

function serializeAny<T>(value: T): string
function serializeAny<T>(value: T | undefined): string | null
function serializeAny<T>(value: T) {
	if (value == null) return null
	return JSON.stringify(value)
}

export { serializeAny }

export function deserializeObjectMap<T extends FBValue, O>(
	collection: FBCollection<T>,
	deserialize: (value: T, key: string) => O
) {
	const out: { [id: string]: O } = {}

	if (collection) {
		for (const key in collection) {
			out[key] = deserialize(collection[key], key)
		}
	}
	return out
}

export function serializeObjectMap<T, O extends FBValue>(
	map: { [id: string]: T },
	serialize: (value: T) => O
): FBCollection<O> {
	const out: FBCollection<O> = {}

	for (const key in map) {
		out[key] = serialize(map[key])
	}

	return out
}

// export function deserializeArray<T extends FBValue, O>(
// 	array: FBValue[],
// 	deserialize: (value: T) => O
// ) {
// 	return array.ma
// 	return Object.keys(collection).map((id) => collection[id])
// }

// export function serializeArray<T, O extends FBValue>(
// 	array: T[],
// 	serialize: (value: T) => O
// ): FBCollection<O> {
// 	return
// 	const out: FBCollection<O> = {}

// 	for (const value in map) {
// 		out[key] = serialize(map[key])
// 	}

// 	return out
// }
