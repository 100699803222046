/** @jsx jsx */
import { jsx } from '@emotion/core'
import styled from './styled'
import { ComponentProps } from 'react'

const Box = styled<
	ComponentProps<'div'> & { component?: keyof JSX.IntrinsicElements }
>(({ css, component = 'div', ...props }) => {
	return jsx(component as string, {
		...props,
		css,
	})
})

export default Box
