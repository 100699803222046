import color from 'color'

const padding = 4 as const
const boxShadow = 'inset 0 -5px 0 0 rgba(0, 0, 0, 0.1)'
const borderRadius = 20

const colors = {
	pink: '#FF8AAE',
	purple: '#C265EB',
	blue: '#2787FF',
	red: '#F3304E',
	white: '#ffffff',
	grayLight: '#C5D4E7',
	gray: '#8496AD',
	grayDark: '#445974',
	green: '#00CD75',
	hotPink: '#FF0092',
}

export default {
	padding,
	boxShadow,
	borderRadius,
	textBase: {
		fontSize: 18,
		lineHeight: 1.4,
	},
	boxBase: {
		borderRadius,
	},
	controlBase: {
		fontSize: 24,
		fontWeight: 600,
	},
	buttonAnimationStyle: {
		transition: 'all 0.3s ease',
		':hover': {
			transition: 'all 0.1s ease',
		},
		':active': {
			transform: 'translateY(4px)',
		},
	},
	colors,
	darkenColor(colorName: keyof typeof colors, step: number) {
		const c = colors[colorName]

		return color(c)
			.darken(step * 0.2)
			.hex()
	},
}

// export const controlStyle = {
// 	height: 65,
// 	fontSize: 18,
// }
