import React, { ReactElement, ReactNode, useCallback, useState } from 'react'

export function RevealMenu({
	children,
	renderMenu,
}: {
	children: ReactElement
	renderMenu: (
		props: {
			anchorEl: Element | undefined
			open: boolean
			onClose: () => void
		},
		close: () => void
	) => ReactNode
}) {
	const [el, setEl] = useState<Element>()

	const close = useCallback(() => {
		setEl(undefined)
	}, [])

	return (
		<>
			{React.cloneElement(children, {
				onClick(e: any) {
					setEl(e.target)
				},
			})}
			{renderMenu(
				{
					anchorEl: el,
					open: !!el,
					onClose: close,
				},
				close
			)}
		</>
	)
}
