import invariant from 'invariant'

// Just a little object with a cancel method
// and a cancel callback.
export default class CancelToken {
	_listeners = []
	canceled = false
	cancel = () => {
		if (!this.canceled) {
			this.canceled = true
			const listeners = this._listeners
			this._listeners = null

			listeners.forEach((fn) => fn())
		}
	}
	onCancel = (fn) => {
		if (this._listeners) this._listeners.push(fn)
		else fn()
	}

	static error = new Error('CancelToken::canceled')
	static isCancelError(error) {
		return error === this.error
	}
}

/**
 *
 * Just like new Promise(executor), but accepts a cancelToken.
 * Note that the executor function must return a function to be
 * called if the task gets canceled. Note that it will only
 * get called if the task it cancelled. It will not get called
 * if the task ends normally.
 *
 * All canceled promises are rejected with the CancelToken.error object.
 *
 * @param {*} cancelToken
 * @param {*} executor
 */
export function newCancellablePromise(cancelToken, executor) {
	if (!cancelToken) {
		return new Promise(executor)
	}

	return new Promise((resolve, reject) => {
		let done = false

		cancelToken.onCancel(() => {
			if (done) return
			done = true
			dispose()
			reject(CancelToken.error)
		})

		const dispose = executor(
			function () {
				if (done) return
				done = true
				resolve.apply(undefined, arguments)
			},
			function () {
				if (done) return
				done = true
				reject.apply(undefined, arguments)
			}
		)

		invariant(
			typeof dispose === 'function',
			'newCancellablePromise, executor must return a dispose function. Got ' +
				dispose
		)
	})
}

// Returns a new promise that gets rejected
// when the cancelToken is canceled with
// the CancelToken.error object when the
// cancelToken is invoked.
export function wrapPromiseWithCancelToken(promise, cancelToken, dispose) {
	if (!cancelToken) return promise

	return newCancellablePromise(cancelToken, (resolve, reject) => {
		promise.then(resolve, reject)
		return dispose || (() => {})
	})
}
