import { RemoteAssetRef } from './remoteTypes'
import remoteConfig from './remoteConfig'

export function getRemoteAssetUrl(ref?: RemoteAssetRef<any>) {
	if (!ref) return undefined

	const { dataset, projectId } = remoteConfig

	const getUrl = (type: string, file: string) =>
		`https://cdn.sanity.io/${type}s/${projectId}/${dataset}/${file}`

	if (ref._type === 'image') {
		const [type, id, size, ext] = ref.asset._ref.split('-')
		return getUrl(type, `${id}-${size}.${ext}`)
	} else {
		const [type, id, ext] = ref.asset._ref.split('-')
		return getUrl(type, `${id}.${ext}`)
	}
}
