import React, {
	ReactElement,
	ReactNode,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { FaCheck } from 'react-icons/fa'
import LargeHeader from '../../components/LargeHeader'
import MessagePane from '../../components/MessagePane'
import { Game } from '../../entities/Game'
import { CompletedChallengeInfo } from '../../entities/GameplayState'
import { gameTeamResource } from '../../entities/Team'
import { useRecords } from '../../firebase/useRecord'
import TeamNameFormatter from '../../formatters/TeamNameFormatter'
import UserNameFormatter from '../../formatters/UserNameFormatter'
import playSound, { SoundName } from '../../game/playSound'
import { getPromptHandler } from '../../gameplay/handlers'
import Box from '../../ui/Box'
import ChallengeTeamAnswersView from './ChallengeTeamAnswersView'
import { LeaderBoard } from './LeaderBoard'
import { useBGColor } from '../../views/FancyBackground'
import sharedStyles from '../../ui/sharedStyles'
import Card from '../../ui/Card'
import CardHeader from '../../ui/CardHeader'
import CardContent from '../../ui/CardContent'

interface Slide {
	duration: number
	render(): ReactNode
}

function getCurrentSlide(slides: Slide[], dt: number) {
	let sum = 0
	for (let i = 0; i < slides.length; ++i) {
		const slideDuration = slides[i].duration
		if (dt < sum + slideDuration) return i
		sum += slideDuration
	}

	return slides.length - 1
}

interface Props {
	info: CompletedChallengeInfo
	game: Game
	teamId?: string
	challengeIndex: number
	buzzMode?: boolean
	showLeaderboard?: boolean
	endMode?: boolean
}

export function PostChallengeView({
	info,
	teamId,
	challengeIndex,
	game,
	buzzMode,
	endMode,
	showLeaderboard,
}: Props) {
	const [slideIndex, setSlideIndex] = useState(0)
	const startTimeRef = useRef(new Date().getTime())

	const teams = useRecords([gameTeamResource, { gameId: game.id }])

	const slides = useMemo(() => {
		if (!teams) return undefined
		const slides: Slide[] = []

		if (!buzzMode) {
			slides.push({
				duration: 2,
				render: () => (
					<>
						<PlaySound name="yourBuzz" />
						{/* <LargeHeader mb={4} title={<>Question #{challengeIndex + 1}</>} /> */}
						<LargeHeader title="And the answer is..." />
					</>
				),
			})
		} else {
			const winner = teams?.find((team) => {
				const perfectScore = info.responsesByTeam[team.id]?.promptScores.every(
					(r) => r.correct
				)
				return perfectScore
			})

			// There should always be a winner, but we check just in case.
			if (winner) {
				if (winner.id === teamId) {
					// If this is the player that won
					slides.push({
						duration: 2,
						render: () => (
							<>
								<PlaySound name="ding" />
								<BackgroundColor color={sharedStyles.colors.green} />
								<LargeHeader
									title={
										<>
											<FaCheck /> Great job!
										</>
									}
								/>
							</>
						),
					})
				} else {
					slides.push({
						duration: 2,
						render: () => (
							<>
								<PlaySound name="yourBuzz" />
								<LargeHeader
									title={
										<>
											<TeamNameFormatter gameId={game.id} value={winner.id} />{' '}
											got it
										</>
									}
								/>
							</>
						),
					})
				}
			}
		}

		slides.push({
			duration: 1 + info.challengeDescription.prompts.length * 2,
			render: () => (
				<>
					{/* <LargeHeader mb={5} title={<>Question #{challengeIndex + 1}</>} /> */}
					{info.challengeDescription.prompts.map((prompt, i) => {
						const handler = getPromptHandler(prompt.type)
						const label = handler.renderLabel?.(prompt.data) ?? 'Answer'

						return (
							<MessagePane mb={3} key={i} title={label}>
								<Box css={{ fontSize: 25 }}>
									{handler.renderCorrectAnswer(prompt.answer, prompt.data)}
								</Box>
							</MessagePane>
						)
					})}
				</>
			),
		})

		slides.push({
			duration: 10,
			render() {
				if (!teams) return null
				return (
					<>
						<LargeHeader mb={5} title="Let's see how everyone did" />
						<ChallengeTeamAnswersView
							activeTeamId={teamId}
							teams={teams}
							info={info}
						/>
					</>
				)
			},
		})

		if (showLeaderboard) {
			slides.push({
				duration: 10,
				render() {
					return (
						<>
							<LargeHeader
								mb={4}
								title={game.name}
								subtitle={
									!endMode ? (
										<>
											<UserNameFormatter value={game.creatorId} /> is getting
											ready for the next question.
										</>
									) : (
										<>Thanks for playing!</>
									)
								}
							/>
							<Card>
								<CardHeader title="Final scores" />
								<CardContent>
									<LeaderBoard gameId={game.id} />
								</CardContent>
							</Card>
						</>
					)
				},
			})
		}

		return slides
	}, [
		buzzMode,
		endMode,
		game.creatorId,
		game.id,
		game.name,
		info,
		showLeaderboard,
		teamId,
		teams,
	])

	useEffect(() => {
		if (!slides) return

		const h = setInterval(() => {
			const time = new Date().getTime()
			const dt = time - startTimeRef.current

			setSlideIndex(getCurrentSlide(slides, dt / 1000))
		}, 100)

		return () => clearInterval(h)
	}, [slides])

	return (slides?.[slideIndex]?.render() as ReactElement) ?? null
}

// These are just helpers so we don't have to turn each screen into a component.

function PlaySound({ name }: { name: SoundName }) {
	useEffect(() => {
		playSound(name)
	}, [name])

	return null
}

function BackgroundColor({ color }: { color: string }) {
	useBGColor(color)

	return null
}
