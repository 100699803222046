import { useEffect, useRef } from 'react'
import usePrevious from './usePrevious'

type OnChange<T> = (prevValue: T) => void

interface WatchOptions {
	triggerInitially?: boolean
	equalityFunction?(a: any, b: any): boolean
}

export default function useWatchValue<T>(
	value: T,
	onChange: OnChange<T>,
	options?: WatchOptions
) {
	useWatchValues(
		[value],
		(arr) => {
			onChange(arr[0])
		},
		options
	)
}

export function useWatchValues<T extends Array<any>>(
	values: T,
	onChange: OnChange<T>,
	options?: WatchOptions
) {
	const initialRef = useRef(false)

	const prevValues = usePrevious(values, values)
	useEffect(() => {
		if (!initialRef.current) {
			initialRef.current = true
			if (options?.triggerInitially) {
				onChange(prevValues)
				return
			}
		}
		if (!isEqualArray(values, prevValues, options?.equalityFunction)) {
			onChange(prevValues)
		}
	})
}

function isEqualArray<T>(
	arrA: T[],
	arrB: T[],
	isEqual: (a: T, b: T) => boolean = (a, b) => a === b
) {
	if (arrA === arrB) return true

	if (!Array.isArray(arrA) || !Array.isArray(arrB)) return false

	const len = arrA.length
	if (len !== arrB.length) return false

	for (let i = 0; i < len; ++i) {
		if (!isEqual(arrA[i], arrB[i])) return false
	}

	return true
}
