import { TBUser } from '../entities/TBUser'
import { Game } from '../entities/Game'
import { Team } from '../entities/Team'
import { GameplayState } from '../entities/GameplayState'
import { GameDescription } from '../gameplay/core/GameDescription'

export type GameState =
	| {
			type: 'initialLoad'
	  }
	| {
			type: 'selectGame'
			status: 'initial' | 'pending' | 'invalid'
			code: string | undefined
			user: TBUser | undefined
			setCode(code: string): void
	  }
	| ({
			game: Game
			user: TBUser
	  } & (
			| {
					type: 'selectedGameEnded'
			  }
			| {
					type: 'selectedGameNotPlayable'
			  }
			| {
					type: 'gameSelectedLoadingInfo'
			  }
			| {
					type: 'selectTeam'
			  }
			| {
					type: 'playing'
					team: Team
					gameplayState: GameplayState | undefined
					leaveGame(): void
			  }
			| {
					type: 'spectating'
					gameplayState: GameplayState | undefined
			  }
	  ))
	| {
			type: 'createGame'
			user: TBUser | undefined
			createGame(
				name: string,
				userName: string | undefined,
				descriptionId: string
			): Promise<void>
	  }
	| {
			type: 'hostLoadingInfo'
	  }
	| {
			type: 'hostGame'
			game: Game
			user: TBUser
			gameDescription: GameDescription
	  }
	| {
			type: 'hostingNotAllowed'
	  }

export function getGameStateGame(gameState: GameState) {
	switch (gameState.type) {
		case 'selectedGameEnded':
		case 'selectedGameNotPlayable':
		case 'gameSelectedLoadingInfo':
		case 'selectTeam':
		case 'playing':
		case 'spectating':
		case 'hostGame':
			return gameState.game
		default:
			return undefined
	}
}

export function getGameStateGameplayState(gameState: GameState) {
	switch (gameState.type) {
		case 'selectedGameEnded':
		case 'selectedGameNotPlayable':
		case 'gameSelectedLoadingInfo':
		case 'selectTeam':
		case 'playing':
		case 'spectating':
		case 'hostGame':
			return gameState.game
		default:
			return undefined
	}
}
