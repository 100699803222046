/** @jsx jsx */
import styled from './styled'
import React from 'react'
import { StateLink, useControlledState } from '../utils/StateLink'
import { jsx } from '@emotion/core'
import sharedStyles from './sharedStyles'
import theme from './theme'

interface Props<T = any> {
	options?: { value: T; label: string }[]
	selectedState?: StateLink<T>
	placeholder?: string
}

const Select = styled<Props>(({ options, selectedState, placeholder, css }) => {
	const [value, setValue] = useControlledState(selectedState)

	const selectedIndex = options?.findIndex((o) => o.value === value) ?? -1

	return (
		<select
			css={[
				{
					width: '100%',
					display: 'block',
					appearance: 'none',
					backgroundColor: 'rgba(255,255,255,0.5)',
					border: '2px solid rgba(0,0,0,0.1)',
					backdropFilter: 'blur(10px)',
					outline: 'none',
					padding: theme.space[sharedStyles.padding],
					borderRadius: sharedStyles.borderRadius,
					backgroundImage:
						"url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E')",
					backgroundRepeat: 'no-repeat',
					backgroundPosition: `right ${
						theme.space[sharedStyles.padding]
					}px top 50%`,
					backgroundSize: '.65em auto',
				},
				sharedStyles.controlBase,
				css,
			]}
			value={selectedIndex >= 0 ? selectedIndex : undefined}
			onChange={(e) => setValue(options?.[Number(e.target.value)].value)}
		>
			{options ? (
				<React.Fragment>
					{selectedIndex < 0 && <option>{placeholder}</option>}
					{options?.map((item, i) => (
						<option key={i} value={i}>
							{item.label}
						</option>
					))}
				</React.Fragment>
			) : (
				<option disabled>Loading...</option>
			)}
		</select>
	)
})

export default Select
