import React from 'react'
import { gameTeamResource } from '../entities/Team'
import { useRecords } from '../firebase/useRecord'
import ActivityIndicator from '../ui/ActivityIndicator'
import Card from '../ui/Card'
import CardContent from '../ui/CardContent'
import TeamItem from './TeamItem'

export default function TeamsView({ gameId }: { gameId: string }) {
	let teams = useRecords([gameTeamResource, { gameId }])

	return (
		<div>
			{teams === undefined ? (
				<Card>
					<CardContent>
						<ActivityIndicator />
					</CardContent>
				</Card>
			) : teams == null ? (
				<Card>
					<CardContent>No teams yet</CardContent>
				</Card>
			) : (
				teams.map((team) => (
					<Card key={team.id} flat mb={3}>
						<CardContent>
							<TeamItem team={team} emptySubtitle="No members yet" />
						</CardContent>
					</Card>
				))
			)}
		</div>
	)
}
