import React, { ReactNode, ComponentProps } from 'react'
import Box from './Box'
import sharedStyles from './sharedStyles'
import styled from './styled'

interface Props extends Omit<ComponentProps<typeof Box>, 'color' | 'title'> {
	color?: keyof typeof sharedStyles.colors
	title?: ReactNode
}

const Alert = styled<Props>(
	({ css, title, children, color: colorName = 'red', ...props }) => {
		const color = sharedStyles.colors[colorName]

		return (
			<Box
				p={sharedStyles.padding}
				css={[
					css,
					sharedStyles.boxBase,
					sharedStyles.textBase,
					{
						// paddingLeft: sharedStyles.padding,
						// paddingRight: sharedStyles.padding,
						paddingTop: 16,
						paddingBottom: 16,
					},
					{
						backgroundColor: color, // Color(color).mix(Color('#ffffff'), 0.9).hex(),
						color: sharedStyles.colors.white, // Color(color).mix(Color('#000000'), 0.5).hex(),
					},
				]}
				{...props}
			>
				{title && (
					<Box mb={2} css={{ fontWeight: 'bold' }}>
						{title}
					</Box>
				)}
				{children}
			</Box>
		)
	}
)

export default Alert
