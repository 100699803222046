import React, { useCallback, useMemo, useState } from 'react'
import { GameplayStateChallengeView } from '../../entities/GameplayState'
import {
	pendingChallengeResponseResource,
	TeamChallengeResponse,
} from '../../entities/PendingChallengeResponse'
import { gameTeamResource, Team } from '../../entities/Team'
import { useRecords } from '../../firebase/useRecord'
import useRecordActions from '../../firebase/useRecordActions'
import playSound from '../../game/playSound'
import { useBuzz } from '../../game/useBuzz'
import { GameChallengeDescription } from '../../gameplay/core/types'
import { getPromptHandler } from '../../gameplay/handlers'
import Button from '../../ui/Button'
import Card from '../../ui/Card'
import CardHeader from '../../ui/CardHeader'
import sharedStyles from '../../ui/sharedStyles'
import immutable from '../../utils/immutable'
import useWatchValue from '../../utils/useWatchValue'
import { BuzzTeamResponsesView } from './BuzzTeamResponsesView'
import { TeamResponsesView } from './TeamResponsesView'

export interface Scoring {
	[teamId: string]: {
		correct: boolean | undefined
		points: number
	}[]
}

interface Props {
	gameId: string
	challengeView: GameplayStateChallengeView
	challengeDescription: GameChallengeDescription
	respondingDisabled: boolean
	onRespondingDisabledChange: (value: boolean) => void
	onEndQuestion(
		pendingResponses: TeamChallengeResponse[] | undefined,
		scoring: Scoring
	): void
	onResetQuestion(): void
}

export default function HostActiveChallengeView({
	gameId,
	challengeView,
	challengeDescription,
	respondingDisabled,
	onRespondingDisabledChange,
	onEndQuestion,
	onResetQuestion,
}: Props) {
	const recordActions = useRecordActions()
	const teams = useRecords([gameTeamResource, { gameId }])

	const slideIndex = challengeView.slideIndex
	const challenge = challengeView.challenge
	const buzzMode = challenge.settings.buzzIn
	const penalizeWrongAnswers = challenge.settings.penalizeWrongAnswers

	const [customScoring, setCustomScoring] = useState<{
		[teamId: string]: (boolean | undefined)[]
	}>({})

	const scoreAnswer = (
		teamId: string,
		responseIndex: number,
		correct: boolean
	) => {
		setCustomScoring((s) => immutable.set(s, [teamId, responseIndex], correct))
	}

	const pendingResponses = useRecords([
		pendingChallengeResponseResource,
		{ gameId, slideIndex },
	])

	const removeResponse = (id: string) => {
		recordActions.setRecord(
			[pendingChallengeResponseResource, { gameId, slideIndex }],
			id,
			undefined
		)
	}

	const responseScoringByTeam = useMemo(() => {
		const obj: Scoring = {}

		teams?.forEach((team, i) => {
			const teamId = team.id
			const teamPendingResponses = pendingResponses?.find(
				(r) => r.teamId === teamId
			)
			const teamCustomScoring = customScoring[teamId]

			obj[teamId] = challengeDescription.prompts.map((promptDescription, i) => {
				const prompt = challenge.prompts[i]
				let correct: boolean | undefined

				const custom = teamCustomScoring?.[i]

				if (custom !== undefined) {
					// If the host scored it we use that
					correct = custom
				} else {
					// See if the team answered it
					if (teamPendingResponses) {
						const teamResponse = teamPendingResponses.promptResponses[i]
						const handler = getPromptHandler(promptDescription.type)

						// If we can check it automatically we do it.
						if (handler.isResponseCorrect) {
							// If we can check if it's correct, we do that
							if (teamResponse != null) {
								correct = handler.isResponseCorrect(
									teamResponse,
									promptDescription.answer
								)
							} else {
								// If the team responded with null,
								// it's always wrong
								correct = false
							}
						}
					}
				}

				let points: number
				if (correct) {
					points = prompt.points
				} else {
					// We don't want to subtract points when
					// correct is undefined since that means
					// the user didn't attempt to answer.
					if (penalizeWrongAnswers && correct === false) {
						points = -prompt.points
					} else {
						points = 0
					}
				}

				return {
					correct,
					points,
				}
			})
		})

		return obj
	}, [
		penalizeWrongAnswers,
		challenge.prompts,
		challengeDescription.prompts,
		customScoring,
		pendingResponses,
		teams,
	])

	const { buzzedIn, clearBuzz } = useBuzz(gameId, slideIndex)

	const isAnswerCorrect = (teamId: string, promptIndex: number) =>
		responseScoringByTeam?.[teamId]?.[promptIndex]?.correct

	const endQuestion = useCallback(
		(force?: boolean) => {
			if (teams === undefined) {
				alert('Cannot end question while loading teams')
				return
			}

			if (
				teams &&
				!force &&
				!confirmUserWantsToEndQuestion(
					pendingResponses ?? undefined,
					teams,
					responseScoringByTeam
				)
			) {
				return
			}

			onEndQuestion(pendingResponses ?? undefined, responseScoringByTeam)
		},
		[onEndQuestion, pendingResponses, responseScoringByTeam, teams]
	)

	const numTeamsResponded = pendingResponses?.length ?? 0

	// Play a sound when a team responds to let the host know
	useWatchValue(numTeamsResponded, (prev) => {
		if (numTeamsResponded > prev) {
			playSound('softDing')
		}
	})

	return (
		<>
			{buzzMode ? (
				buzzedIn ? (
					<BuzzTeamResponsesView
						userId={buzzedIn.user}
						teamId={buzzedIn.team}
						onScoreAnswer={scoreAnswer}
						onClearBuzz={clearBuzz}
						onEndQuestion={() => endQuestion(true)}
						challengeDecription={challengeDescription}
					/>
				) : (
					<Card
						mb={3}
						flat
						css={{
							height: 200,
							backgroundColor: sharedStyles.colors.grayDark,
							color: sharedStyles.colors.white,
						}}
					>
						<CardHeader>Waiting for someone to buzz in</CardHeader>
					</Card>
				)
			) : (
				<>
					<CardHeader
						title={
							<>
								Responses {numTeamsResponded + ''} /{' '}
								{(teams?.length ?? '?') + ''}
							</>
						}
						accessory={
							!respondingDisabled ? (
								<button onClick={() => onRespondingDisabledChange(true)}>
									Pause
								</button>
							) : (
								<button onClick={() => onRespondingDisabledChange(false)}>
									Resume
								</button>
							)
						}
					/>
					{teams && (
						<TeamResponsesView
							pendingResponses={pendingResponses ?? undefined}
							onRemoveResponse={removeResponse}
							onScoreAnswer={scoreAnswer}
							isAnswerCorrect={isAnswerCorrect}
							challengePropmpts={challenge.prompts}
							teams={teams}
							gameId={gameId}
						/>
					)}
				</>
			)}
			{!buzzedIn && (
				<>
					{challenge.settings.buzzIn && (
						<Button mb={3} tone="red" onClick={onResetQuestion}>
							Reset question
						</Button>
					)}
					<Button mb={3} tone="red" onClick={() => endQuestion()}>
						End this question
					</Button>
				</>
			)}
		</>
	)
}

function confirmUserWantsToEndQuestion(
	pendingResponses: TeamChallengeResponse[] | undefined,
	teams: Team[],
	responseScoringByTeam: Scoring
) {
	let prompted = false

	if (!pendingResponses || pendingResponses.length !== teams.length) {
		prompted = true
		if (
			!window.confirm('Not all teams have submitted responses. Are you sure?')
		) {
			return false
		}
	}

	if (!prompted) {
		if (!window.confirm('Are you sure?')) {
			return false
		}
	}

	return true
}
