import { ChallengePromptHandler } from '../core/handlerTypes'
import { ChallengePromptDescription } from '../core/types'

export default function createPromptDescription<T, A, TT>(
	handler: ChallengePromptHandler<T, any, A, TT>,
	data: T,
	answer: A,
	points?: number
): ChallengePromptDescription<TT> {
	return {
		type: handler.type,
		data,
		answer,
		points,
	}
}
