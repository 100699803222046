import 'firebase/analytics'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import React, { ReactNode, useEffect, useState } from 'react'
import FirebaseContext from './FirebaseContext'

export default function FirebaseProvider({
	children,
}: {
	children: ReactNode
}) {
	const [app, setApp] = useState<firebase.app.App>()

	useEffect(() => {
		const app = firebase.initializeApp({
			apiKey: 'AIzaSyAUox85bP8PY2EkkPM5Q5Qe_DemkahVdlM',
			authDomain: 'trivia-b1395.firebaseapp.com',
			databaseURL: 'https://trivia-b1395.firebaseio.com',
			projectId: 'trivia-b1395',
			storageBucket: 'trivia-b1395.appspot.com',
			messagingSenderId: '684075727564',
			appId: '1:684075727564:web:1e92e4fc5376e495c1a069',
			measurementId: 'G-QDZF8RJ6VC',
		})

		firebase.analytics()

		setApp(app)
	}, [])

	if (!app) return null

	return (
		<FirebaseContext.Provider value={firebase}>
			{children}
		</FirebaseContext.Provider>
	)
}
