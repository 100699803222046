import { User } from 'firebase'
import React, {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { Profile, profileResource } from '../entities/Profile'
import { TBUser } from '../entities/TBUser'
import FirebaseContext from '../firebase/FirebaseContext'
import { useEditableRecord } from '../firebase/useRecord'
import useRecordActions from '../firebase/useRecordActions'
import { Session } from './Session'

export const SessionContext = createContext<Session | undefined>(undefined)

export function SessionProvider(props: { children?: ReactNode }) {
	const firebase = useContext(FirebaseContext)!
	const [user, setUser] = useState<User | undefined | null>(null)

	const recordActions = useRecordActions()

	useEffect(() => {
		firebase.auth().onAuthStateChanged((user) => {
			setUser(user ?? undefined)
		})
	})

	const [profile] = useEditableRecord(profileResource, user?.uid)

	const session = useMemo<Session>(() => {
		const createUser = (user: User, profile: Profile) => {
			return {
				id: user.uid,
				displayName: user.displayName,
				profile,
				guest: user.isAnonymous,
			} as TBUser
		}

		if (user && profile) {
			return {
				loggedIn: true,
				user: createUser(user, profile),
				logOut() {
					return firebase.auth().signOut()
				},
			}
		} else {
			return {
				loggedIn: false,
				loading: user === null || (!!user && profile === undefined),
				async logInAsGuest(name: string) {
					const cred = await firebase.auth().signInAnonymously()
					if (!cred.user) throw new Error('No user')

					await recordActions.setRecord(
						profileResource,
						cred.user.uid,
						(profile) => {
							return {
								...profile,
								name,
							}
						}
					)

					const profile = await recordActions.getRecord(
						profileResource,
						cred.user.uid
					)

					if (!profile) {
						throw new Error('Could not load user profile')
					}

					return createUser(cred.user, profile)
				},
			}
		}
	}, [firebase, profile, recordActions, user])

	return <SessionContext.Provider value={session} {...props} />
}
