// @ts-nocheck
type KeyPathArray = (number | string)[]
type KeyPath = KeyPathArray | string
type Input = object | any[]

function get(obj: Input, keyPath: KeyPath): any {
	if (obj == null) return undefined
	keyPath = normalizeKeyPath(keyPath)
	for (let key of keyPath) {
		if (obj == null) break
		obj = obj[key]
	}
	return obj
}

function set<O extends Input>(object: O, keyPath: KeyPath, value: any): O {
	return transform(object, keyPath, (_) => value)
}

export function transform<O extends Input>(
	object: O,
	keyPath: KeyPath,
	fn: (value: any) => any,
	...args: any[]
) {
	const originalKeyPath = args[0]
	if (object != null && !Array.isArray(object) && typeof object !== 'object') {
		throw new Error(
			'Cannot immutable set ' +
				originalKeyPath +
				" because there is a value of type '" +
				typeof object +
				"' (" +
				object +
				')'
		)
	}

	const [firstKey, ...nextKeyPath] = normalizeKeyPath(keyPath)

	let value = object && object[firstKey]

	if (nextKeyPath.length > 0) {
		value = transform(value, nextKeyPath, fn, originalKeyPath || keyPath)
	} else {
		value = fn(value)
	}

	if (!object) {
		if (typeof firstKey === 'number') {
			object = []
		} else {
			object = {}
		}
	} else {
		// Quick exist.
		if (object[firstKey] === value) {
			return object
		}

		if (Array.isArray(object)) {
			object = [...object]
		} else if (typeof object === 'object') {
			object = { ...object }
		} else {
			throw new Error('We should never get here')
		}
	}

	object[firstKey] = value

	return object
}

function _map(mapFn, collection, fn) {
	if (!collection) return collection

	let changed = false

	const newCollection = mapFn(collection, (value, ...args) => {
		const newValue = fn(value, ...args)
		if (newValue !== value) {
			changed = true
		}
		return newValue
	})

	return changed ? newCollection : collection
}

export function assign(object, members) {
	if (!object) return object

	let changed

	for (let key in members) {
		if (object[key] !== members[key]) {
			if (!changed) {
				changed = true
				object = Object.assign({}, object)
			}
			object[key] = members[key]
		}
	}

	return object
}

/**
 * @example
 * normalizeKeyPath('x.y.z[0].f')
 * // => ['x', 'y', 'z', 0, 'f']
 */
function normalizeKeyPath(keyPath: KeyPath): KeyPathArray {
	if (typeof keyPath === 'string') {
		const specialKey = '$ $ D Bloop'
		let hasDigits = false
		keyPath = keyPath.replace(/\[([0-9]+)\]/g, function (a, b) {
			hasDigits = true
			return '.' + specialKey + b
		})
		let arr = keyPath.split('.')
		if (hasDigits) {
			arr.forEach((str, i) => {
				if (str.substr(0, specialKey.length) === specialKey) {
					arr[i] = parseInt(str.substr(specialKey.length), 10)
				}
			})
		}
		return arr
	} else if (typeof keyPath === 'number') {
		return [keyPath]
	} else {
		return keyPath
	}
}

const arrayMap = (value, ...args) => Array.prototype.map.apply(value, args)

export default {
	get,
	set,
	assign,
	map: _map.bind(null, arrayMap),
	transform,
}
