import Box from '../ui/Box'
import styled from '../ui/styled'
import React, { ReactNode } from 'react'

interface Props {
	title?: ReactNode
	subtitle?: ReactNode
}

const LargeHeader = styled<Props>(({ css, title, subtitle }) => {
	return (
		<Box css={[{ textAlign: 'center' }, css]}>
			{title && (
				<Box css={{ fontSize: 28, color: '#423D5B', fontWeight: 800 }}>
					{title}
				</Box>
			)}
			{subtitle && (
				<Box css={{ fontSize: 20, color: '#423D5B', fontWeight: 500 }}>
					{subtitle}
				</Box>
			)}
		</Box>
	)
})

export default LargeHeader
