import { FBId } from './types'

export interface FBIdMap {
	[id: string]: true
}

export function deserializeIdMap(map: FBIdMap | null) {
	// Empty objects are null in the db
	return map ? Object.keys(map) : []
}
export function serializeIdMap(ids: FBId[]) {
	const map: FBIdMap = {}
	for (const id of ids) {
		map[id] = true
	}
	return map
}
