import { Asset } from './Asset'
import CancelToken from '../../utils/CancelToken'

const fetchByType: {
	[K in Asset['type']]: (
		url: string,
		cancelToken?: CancelToken
	) => Promise<() => void>
} = {
	image(url, cancelToken) {
		return new Promise((resolve, reject) => {
			const image = new Image()
			// console.log('LOAD IMAGE', url)
			image.src = url

			const dispose = () => {
				image.src = ''
			}

			if (image.complete) {
				// console.log('IMAGE LOADED from cache')
				resolve(dispose)
			} else {
				image.onload = () => {
					// console.log('IMAGE LOADED')
					resolve(dispose)
				}
				image.onerror = (error) => {
					// console.log('IMAGE ERROR', error)
					reject(new Error('Image load error - ' + error))
				}
			}

			cancelToken?.onCancel(() => {
				dispose()
				reject(CancelToken.error)
			})
		})
	},
	sound(url, cancelToken) {
		return new Promise((resolve, reject) => {
			const sound = new Howl({
				src: url,
				preload: true,
				onload() {
					if (cancelToken?.canceled) return
					resolve(dispose)
				},
				onloaderror(error) {
					if (cancelToken?.canceled) return
					reject(new Error('Howl error - ' + error))
				},
			})

			const dispose = () => {
				sound.unload()
			}

			cancelToken?.onCancel(() => {
				dispose()
				reject(CancelToken.error)
			})
		})
	},
}

export default function fetchAssetByType(
	type: Asset['type'],
	url: string,
	cancelToken: CancelToken
) {
	return fetchByType[type](url, cancelToken)
}
