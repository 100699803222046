import React, { ComponentProps } from 'react'
import Box from './Box'
import sharedStyles from './sharedStyles'
import styled from './styled'

interface Props extends ComponentProps<typeof Box> {
	flat?: boolean
	buttonMode?: boolean
}

const Card = styled<Props>(({ css, children, flat, buttonMode, ...props }) => {
	return (
		<Box
			{...props}
			css={[
				!flat && { boxShadow: sharedStyles.boxShadow },
				sharedStyles.boxBase,
				{
					backgroundColor: '#ffffff',
				},
				buttonMode && [
					{
						userSelect: 'none',
						cursor: 'pointer',
						':hover': {
							backgroundColor: sharedStyles.darkenColor('grayLight', -0.5),
						},
						':active': {
							backgroundColor: sharedStyles.darkenColor('grayLight', 0),
						},
					},
					sharedStyles.buttonAnimationStyle,
				],
				css,
			]}
		>
			{children}
		</Box>
	)
})

export default Card
