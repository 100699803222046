import React from 'react'
import Box from './Box'
import sharedStyles from './sharedStyles'
import styled from './styled'

const CardContent = styled(({ children, css }) => {
	return (
		<Box p={sharedStyles.padding} css={[sharedStyles.textBase, css]}>
			{children}
		</Box>
	)
})

export default CardContent
