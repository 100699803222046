import { times } from 'ramda'
import React, { useMemo } from 'react'
import ScoreFormatter from '../../game/ScoreFormatter'
import Button from '../../ui/Button'
import Label from '../../ui/Label'
import { StateLink } from '../../utils/StateLink'
import { ChallengePromptHandler } from '../core/handlerTypes'

function shuffle<T extends any[]>(array: T) {
	const shuffled = [...array]
	for (let i = 0; i < 10; ++i) {
		const index = Math.floor(Math.random() * array.length)
		const temp = shuffled[index]
		shuffled[index] = shuffled[0]
		shuffled[0] = temp
	}
	return shuffled
}

const multipleChoicePrompt: ChallengePromptHandler<string[], number, number> = {
	type: 'multipleChoice',
	interactive: true,
	submitImmediately: true,
	getAssetUrls: () => [],
	renderInputView: ({ data, points }, responseLink, disabled) => {
		return (
			<>
				<Label mb={2}>
					For <ScoreFormatter context="n-points" value={points} />
				</Label>
				<View disabled={disabled} answers={data} selectedState={responseLink} />
			</>
		)
	},
	isResponseCorrect(userAnswer, correctAnswer) {
		return userAnswer === correctAnswer
	},
	renderUserResponse(index, answers) {
		return answers[index]
	},
	renderCorrectAnswer(index, answers) {
		return answers[index]
	},
}

export default multipleChoicePrompt

function View({
	answers,
	selectedState,
	disabled,
}: {
	answers: string[]
	selectedState: StateLink<number> | undefined
	disabled: boolean
}) {
	const indices = useMemo(() => shuffle(times((i) => i, answers.length)), [
		answers.length,
	])
	const [selectedIndex, setSelectedIndex] = selectedState ?? []

	return (
		<>
			{indices.map((realIndex, i) => {
				const title = answers[realIndex]

				return (
					<Button
						mb={2}
						tone={tones[i % tones.length]}
						key={realIndex}
						// If not disabled there's no need to show the
						// previous answer
						active={disabled && realIndex === selectedIndex}
						disabled={!selectedState || disabled}
						onClick={() => {
							setSelectedIndex?.(realIndex)
						}}
					>
						{title}
					</Button>
				)
			})}
		</>
	)
}

const tones = ['blue', 'purple', 'pink', 'green'] as const
