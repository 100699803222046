import useDatabase from '../firebase/useDatabase'
import { useState, useLayoutEffect, useCallback } from 'react'

interface Buzz {
	active: { user: string; team: string } | null
	previous?: { user: string; team: string }[]
}

export function useBuzz(gameId: string, slideIndex: number) {
	const db = useDatabase()

	const [buzzedIn, setBuzzedIn] = useState<Buzz>()

	useLayoutEffect(() => {
		const fn = (snapshot: firebase.database.DataSnapshot) => {
			setBuzzedIn(snapshot.val() ?? undefined)
		}
		const ref = db.ref(`/buzz/${gameId}/${slideIndex}`)
		ref.on('value', fn)

		return () => {
			ref.off('value', fn)
		}
	}, [db, gameId, slideIndex])

	const buzzIn = useCallback(
		(currentUserId: string, teamId: string) => {
			const ref = db.ref(`/buzz/${gameId}/${slideIndex}`)
			ref.transaction((prev: Buzz | null) => {
				// Not allowed to overwrite someone else
				if (prev) {
					if (prev.active) return prev
					if (prev.previous?.find((p) => p.user === currentUserId)) return prev
				}

				const out: Buzz = prev
					? {
							...prev,
							active: { user: currentUserId, team: teamId },
					  }
					: {
							active: { user: currentUserId, team: teamId },
							previous: [],
					  }
				return out
			})
		},
		[db, gameId, slideIndex]
	)

	const clearBuzz = useCallback(() => {
		const ref = db.ref(`/buzz/${gameId}/${slideIndex}`)
		ref.transaction((prev: Buzz | null) => {
			if (!prev) return prev

			const active = prev.active

			return {
				active: null,
				previous: active ? [...(prev.previous || []), active] : prev.previous,
			} as Buzz
		})
	}, [db, gameId, slideIndex])

	const didUserAlreadyBuzz = useCallback(
		(userId: string) => {
			if (buzzedIn?.previous?.find((a) => a.user === userId)) {
				return true
			}
			return false
		},
		[buzzedIn]
	)

	return {
		buzzedIn: buzzedIn?.active ?? undefined,
		buzzIn,
		clearBuzz,
		didUserAlreadyBuzz,
	}
}

export type BuzzState = ReturnType<typeof getBuzzState>

export function getBuzzState(
	buzzedIn: { user: string; team: string } | undefined,
	didUserAlreadyBuzz: (id: string) => boolean,
	userId: string
) {
	if (buzzedIn) {
		if (buzzedIn.user === userId) {
			return {
				type: 'yourBuzz',
			} as const
		} else {
			return {
				type: 'otherBuzzed',
				userId: buzzedIn.user,
				teamId: buzzedIn.team,
			} as const
		}
	} else {
		if (didUserAlreadyBuzz(userId)) {
			return {
				type: 'alreadyBuzzed',
			} as const
		} else {
			return {
				type: 'canBuzz',
			} as const
		}
	}
}
