import { Asset } from './Asset'
import CancelToken from '../../utils/CancelToken'
import getFileTypeForPath from '../../utils/getFileTypeForPath'
import fetchAssetByType from './fetchAssetByType'

interface PendingItem {
	url: string
	cancel(): void
}

export default function loadUrls(
	urls: string[],
	{
		onDone,
		onAssetLoaded,
	}: {
		onDone(): void
		onAssetLoaded(url: string, asset: Asset): void
	}
) {
	const pendingItems: PendingItem[] = []

	const afterLoad = (url: string, result: Asset | Error) => {
		const index = pendingItems.findIndex((item) => item.url === url)
		if (index < 0) return

		// Remove it from the batch?
		pendingItems.splice(index, 1)

		// Add it as an asset
		if (result instanceof Error) {
			if (CancelToken.isCancelError(result)) {
				// No problem
				console.log('Asset canceled before it finished loading', url)
			} else {
				if (process.env.NODE_ENV !== 'production') {
					console.error('Error loading asset', url, result)
				}
			}
		} else {
			onAssetLoaded(url, result)
		}

		if (pendingItems.length <= 0) {
			onDone()
		}
	}

	urls.forEach((url) => {
		const type = getFileTypeForPath(url)
		if (type) {
			const cancelToken = new CancelToken()
			fetchAssetByType(type, url, cancelToken).then(
				(dispose) => {
					afterLoad(url, {
						type: 'image',
						dispose,
					})
				},
				(error) => {
					afterLoad(url, error)
				}
			)

			pendingItems.push({
				url,
				cancel() {
					cancelToken.cancel()
				},
			})
		} else {
			if (process.env.NODE_ENV !== 'production') {
				console.warn(
					'Url added to assets manager could not be resolved to a file type so it is ignored',
					url
				)
			}
		}
	})

	// If none of the items were loadable we return undefined.
	if (pendingItems.length <= 0) return undefined

	return {
		cancel: () => pendingItems.forEach((p) => p.cancel()),
		isUrlPending: (url: string) =>
			pendingItems.find((p) => p.url === url) ? true : false,
		cancelPendingUrl(url: string) {
			const item = pendingItems.find((p) => p.url === url)
			if (item) {
				// This will trigger an error which should
				// cause the item to be removed from pendingItems
				item.cancel()
				return true
			} else {
				return false
			}
		},
	}
}
