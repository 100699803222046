import React, { useCallback, useEffect, useState } from 'react'
import UserNameFormatter from '../formatters/UserNameFormatter'
import { GameChallenge } from '../gameplay/core/types'
import { getPromptHandler } from '../gameplay/handlers'
import Alert from '../ui/Alert'
import Box from '../ui/Box'
import Button from '../ui/Button'
import Disabled from '../ui/Disabled'
import { getWatchedStateLink, mapStateLink } from '../utils/StateLink'
import PromptView from './PromptView'
import QueryView from './QueryView'

interface Props {
	challenge: GameChallenge
	respondingDisabled?: boolean
	submittedResponses: any[] | undefined | null
	onSubmit?(responses: any[]): void
	hostId: string
	hidePrompts?: boolean
}

export default function ChallengeView({
	challenge,
	respondingDisabled,
	onSubmit,
	submittedResponses,
	hostId,
	hidePrompts,
}: Props) {
	const promptResponsesLink = useState<any[] | undefined>()
	const [shouldSubmitNext, setSubmitNext] = useState(false)

	const shouldSubmitImmediately = challenge.prompts.every(
		(p) => getPromptHandler(p.type).submitImmediately
	)

	const shouldShowSubmit =
		!!onSubmit &&
		!shouldSubmitImmediately &&
		challenge.prompts.some((p) => getPromptHandler(p.type).interactive)

	const submit = useCallback(() => {
		if (!promptResponsesLink) return
		const responses = promptResponsesLink[0]
		onSubmit?.(responses ?? [])
	}, [onSubmit, promptResponsesLink])

	useEffect(() => {
		if (shouldSubmitNext) {
			submit()
			setSubmitNext(false)
		}
	}, [shouldSubmitNext, submit])

	return (
		<>
			<Box mb={4}>
				<QueryView query={challenge.query} />
			</Box>
			{!hidePrompts && (
				<Disabled
					contentEnabled={
						(!respondingDisabled && !submittedResponses) || !onSubmit
					}
					mb={4}
				>
					{challenge.prompts.map((p, i) => (
						<Box key={i} mb={2}>
							<PromptView
								key={i}
								prompt={p}
								disabled={!!submittedResponses}
								staticMode={!onSubmit}
								responseLink={
									// When the responses are loading disable it
									submittedResponses === undefined
										? undefined
										: getWatchedStateLink(
												mapStateLink(
													promptResponsesLink,
													(arr) => arr?.[i],
													(v, arr) => {
														const newArr = arr ? [...arr] : []
														newArr[i] = v
														return newArr
													}
												),
												() => {
													if (shouldSubmitImmediately) {
														setSubmitNext(true)
													}
												}
										  )
								}
							/>
						</Box>
					))}
				</Disabled>
			)}

			{shouldShowSubmit &&
				(submittedResponses ? (
					<Alert color="blue" title="Your answer is in!">
						Sit back and relax while <UserNameFormatter value={hostId} /> scores
						the answers.
					</Alert>
				) : respondingDisabled ? (
					<Alert color="red" title="Time's up!">
						This question is over.
					</Alert>
				) : (
					<Button tone="green" disabled={!promptResponsesLink} onClick={submit}>
						Submit
					</Button>
				))}
		</>
	)
}
