import { useContext, useEffect } from 'react'
import SoundManagerContext from './SoundManagerContext'

interface Props {
	source?: string
	startTime?: number
	loop?: boolean
}
export default function Song({ source, startTime, loop }: Props) {
	const soundManager = useContext(SoundManagerContext)

	useEffect(() => {
		if (source && soundManager) {
			const uid = Math.random() + ''
			// console.log('SET THE SONG', loop, soundManager, source, startTime)
			const stop = soundManager.setSong(source, startTime, loop, uid)
			return () => {
				stop()
			}
		}
	}, [loop, soundManager, source, startTime])

	return null
}
