import React from 'react'
import Input from '../../ui/Input'
import { ChallengePromptHandler } from '../core/handlerTypes'
import CardContent from '../../ui/CardContent'
import Card from '../../ui/Card'
import Label from '../../ui/Label'
import ScoreFormatter from '../../game/ScoreFormatter'

const inputPrompt: ChallengePromptHandler<
	{ title: string },
	string,
	string[]
> = {
	type: 'text',
	interactive: true,
	submitImmediately: false,
	getAssetUrls: () => [],
	renderInputView: ({ data, points }, responseLink, disabled) => {
		const label = (
			<Label>
				{data.title} (<ScoreFormatter value={points} context="n-points" />)
			</Label>
		)
		return (
			<>
				{responseLink ? (
					<Input
						label={label}
						disabled={disabled}
						placeholder="Write answer here"
						noAutoThings
						// @ts-ignore
						valueState={responseLink}
					/>
				) : (
					<Input label={label} disabled placeholder="Loading.." />
				)}
			</>
		)
	},
	renderStaticView(data, points) {
		return (
			<Card>
				<CardContent>
					{data.title} (<ScoreFormatter context="n-points" value={points} />)
				</CardContent>
			</Card>
		)
	},
	isResponseCorrect(userAnswer, correctAnswers) {
		userAnswer = normalizeString(userAnswer)

		return correctAnswers.some((a) => normalizeString(a) === userAnswer)
	},
	renderUserResponse(response) {
		return response
	},
	renderCorrectAnswer(answers) {
		return answers[0]
	},
	renderLabel({ title }) {
		return title
	},
}

export default inputPrompt

function normalizeString(string: string) {
	string = string.toLowerCase()
	string = string.replace(/[ |*|?|"|']/g, '')
	return string
}
