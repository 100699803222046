import { usePreviousWith } from './usePrevious'

export default function useMemoWith<T>(
	value: T,
	equalityFunction: (a: T, b: T) => boolean
) {
	return usePreviousWith((prevValue: T) => {
		if (prevValue === value) return value
		if (!prevValue || !value) return prevValue
		if (equalityFunction(prevValue, value)) return prevValue
		return value
	}, value)
}
