import React from 'react'
import MessagePane from '../../components/MessagePane'
import { GameplayStateChallengeView } from '../../entities/GameplayState'
import Button from '../../ui/Button'
import { LeaderBoard } from '../play/LeaderBoard'
import {
	ChallengePromptDescription,
	ChallengePrompt,
} from '../../gameplay/core/types'
import HostAnswersView from './HostAnswersView'
import Box from '../../ui/Box'

interface Props {
	numSlides: number
	onNext(peek: boolean): void
	challengeView: GameplayStateChallengeView
	onResetQuestion(): void
	gameId: string
	promptDescriptions: ChallengePromptDescription[]
	prompts: ChallengePrompt[]
	lastSlide: boolean
}

export default function HostChallengeEndedView({
	onResetQuestion,
	gameId,
	prompts,
	promptDescriptions,
	onNext,
	lastSlide,
}: Props) {
	return (
		<>
			<MessagePane mb={3} title="Question ended">
				<HostAnswersView
					prompts={prompts}
					promptDescriptions={promptDescriptions}
				/>
				<LeaderBoard showLastRoundPoints gameId={gameId} />
			</MessagePane>
			{!lastSlide ? (
				<Box mb={2} css={{ display: 'flex' }}>
					<Button mr={2} onClick={() => onNext(true)} tone="grayLight">
						Peek next slide &rarr;
					</Button>
					<Button onClick={() => onNext(false)} tone="green">
						Present next slide &rarr;
					</Button>
				</Box>
			) : (
				<Button mb={2} disabled tone="green">
					Reached the end
				</Button>
			)}
			<Button
				flat
				onClick={() => {
					if (
						window.confirm(
							'This will clear out all team responses and start the question from scratch. Are you sure?'
						)
					) {
						onResetQuestion()
					}
				}}
			>
				Start question over
			</Button>
		</>
	)
}
