import { remove } from 'ramda'
import React, {
	createContext,
	FunctionComponent,
	useCallback,
	useMemo,
	useState,
	ContextType,
	useRef,
	useEffect,
} from 'react'
import immutable from '../../utils/immutable'
import { useBGActions } from '../../views/FancyBackground'
import { usePreviousWith } from '../../utils/usePrevious'

const logoImage = require('./logo@2x.png')

const naturalWidth = 154
const naturalHeight = 116

export const logoNaturalSize = { width: naturalWidth, height: naturalHeight }

interface LogoTransform {
	x: number
	y: number
	scale: number
}

function isTransformEqual(a: LogoTransform, b: LogoTransform) {
	return a.x === b.x && a.y === b.y && a.scale === b.scale
}

let count = 0

interface ProxyInternal {
	id: number
	transform: LogoTransform | undefined
	name: string
}

export interface LogoProxy {
	setTransform(newTransform: Partial<LogoTransform> | undefined): void
	setVisible(visible: boolean): void
	deregister(): void
}

export const LogoContext = createContext<
	((name: string) => LogoProxy) | undefined
>(undefined)

const LogoProvider: FunctionComponent = ({ children }) => {
	const [proxies, setProxies] = useState<ProxyInternal[]>([])

	const currentProxy = proxies[proxies.length - 1]

	type T = Exclude<ContextType<typeof LogoContext>, undefined>

	const context = useCallback<T>((name: string) => {
		const id = ++count

		setProxies((proxies) => {
			return [
				...proxies,
				{
					id,
					name,
					transform: undefined,
				},
			]
		})

		return {
			setTransform(newTransform) {
				setProxies((proxies) => {
					const index = proxies.findIndex((p) => p.id === id)
					if (index < 0)
						throw new Error(
							'Attempting to set transform after proxy was removed'
						)

					return immutable.transform(
						proxies,
						[index, 'transform'],
						(prevTransform: LogoTransform | undefined) => {
							if (!prevTransform || !newTransform)
								return prevTransform === newTransform
									? prevTransform
									: newTransform

							const merged = {
								...prevTransform,
								...newTransform,
							}

							// Important so that the whole object doesn't change
							return isTransformEqual(prevTransform, merged)
								? prevTransform
								: merged
						}
					)
				})
			},
			setVisible(visible) {
				// setCount((c) => (visible ? c + 1 : c - 1))
			},
			deregister() {
				setProxies((proxies) => {
					const index = proxies.findIndex((p) => p.id === id)
					if (index < 0)
						throw new Error(
							'Attempting to set transform after proxy was removed'
						)

					return remove(index, 1, proxies)
				})
			},
		}
	}, [])

	const logoStyle = useMemo(() => {
		if (!currentProxy?.transform) return undefined
		const { x, y, scale } = currentProxy.transform

		return {
			position: 'fixed',
			transform: `translate(${x}px, ${y}px) scale(${scale},${scale})`,
			transformOrigin: 'top left',
			transitionDuration: '0.4s',
		} as const
	}, [currentProxy])

	const actions = useBGActions()

	// TEMP
	const transformRef = useRef(currentProxy)
	useEffect(() => {
		transformRef.current = currentProxy
	}, [currentProxy])

	useEffect(() => {
		const onTick = () => {
			const t = transformRef.current
			if (t?.transform) {
				actions.addRipple(
					t.transform.x + t.transform.scale * naturalWidth * 0.5,
					t.transform.y + t.transform.scale * naturalHeight * 0.5,
					100
				)
			}
		}

		const h = setInterval(onTick, 1500)

		onTick()

		return () => {
			clearInterval(h)
		}
	}, [actions])

	const cachedLogoStyle = usePreviousWith((style: typeof logoStyle) => {
		return logoStyle ?? style
	})

	return (
		<LogoContext.Provider value={context}>
			{children}
			{cachedLogoStyle && (
				<img
					src={logoImage}
					alt="Triviabang"
					width={167}
					style={cachedLogoStyle}
				/>
			)}
		</LogoContext.Provider>
	)
}

export default LogoProvider
