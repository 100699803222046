import {
	deserializeRecord,
	deserializeRecords,
	normalizeResourceAndOptions,
} from './FBResource'
import { FBId, FBResourceAndOptions, FBValue } from './types'

export default function watchRecord<T, V extends FBValue, O>(
	db: firebase.database.Database,
	r: FBResourceAndOptions<T, V, O>,
	id: FBId,
	onChange: (value: T | undefined) => void
) {
	const [resource, path, options] = normalizeResourceAndOptions(r)
	const callback = (snapshot: firebase.database.DataSnapshot) => {
		const value = snapshot.val() as FBValue
		onChange(deserializeRecord(resource, id, value, options))
	}
	const ref = db.ref(path + '/' + id)
	ref.on('value', callback)
	return () => {
		ref.off('value', callback)
	}
}

export function watchRecords<T, V extends FBValue, O>(
	db: firebase.database.Database,
	r: FBResourceAndOptions<T, V, O>,
	onChange: (value: T[] | undefined) => void
) {
	const [resource, path, options] = normalizeResourceAndOptions(r)

	const callback = (snapshot: firebase.database.DataSnapshot) => {
		const value = snapshot.val()
		onChange(deserializeRecords(resource, value, options))
	}

	const ref = db.ref(path)

	ref.on('value', callback)

	return () => {
		ref.off('value', callback)
	}
}
