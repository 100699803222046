import {
	GameChallenge,
	GameChallengeDescription,
	GameQuery,
} from '../gameplay/core/types'
import {
	deserializeFBDate,
	serializeFBDate,
} from '../libraries/firebase-utils/FBDate'
import {
	FBResource,
	FBResourceRecordType,
	FBResourceValueType,
} from '../libraries/firebase-utils/FBResource'
import {
	deserializeAny,
	deserializeObjectMap,
	serializeAny,
	serializeObjectMap,
} from '../libraries/firebase-utils/serializing'
import { FBCollection } from '../libraries/firebase-utils/types'
import { pendingChallengeResponseResource } from './PendingChallengeResponse'

export interface CompletedChallengeInfo {
	challengeDescription: GameChallengeDescription
	responsesByTeam: {
		[teamId: string]: CompletedChallengeTeamInfo | undefined
	}
}

export interface CompletedChallengeTeamInfo {
	// If this is undefined it means the host scored the challenge without
	// a response being submitted.
	challengeResponse:
		| FBResourceRecordType<typeof pendingChallengeResponseResource>
		| undefined
	promptScores: {
		correct: boolean
		points: number
	}[]
}

export interface GameplayStateMusic {
	url: string
	startOffset: number
	loop: boolean
	fill: boolean
	key: string
}

interface GameplayStateViewBase {
	slideIndex: number
	startTime: Date
	music?: GameplayStateMusic
	last: boolean
}

export interface GameplayStateChallengeView extends GameplayStateViewBase {
	type: 'challenge'
	// May not be the same as the id number if
	// there are slides.
	challengeIndex: number
	challenge: GameChallenge
	responsesByTeam: {
		[teamId: string]: any
	}
}

export interface GameplayStateInfoView extends GameplayStateViewBase {
	type: 'info'
	query: GameQuery
}

// TODO: Rename to GameSlide?
export type GameplayStateView =
	| GameplayStateInfoView
	| GameplayStateChallengeView

export interface GameplayState {
	readonly gameId: string
	startTime: Date
	musicStartTime?: Date
	status:
		| 'respondingActive'
		| 'respondingInactive'
		| 'questionEnded'
		| 'gameEnded'
	currentView: GameplayStateView
	completedChallenges: {
		[challengeIndex: number]: CompletedChallengeInfo
	}
}

export function isGameplayStateInPlay(status: GameplayState['status']) {
	return status === 'respondingActive' || status === 'respondingInactive'
}

export const gameplayStateResource: FBResource<
	GameplayState,
	{
		status: GameplayState['status']
		startTime: number
		musicStartTime: number | null
		currentView: {
			slideIndex: number
			music: {
				url: string
				startOffset: number
				loop: boolean
				fill: boolean
				key: string
			} | null
			startTime: number
			last: boolean
		} & (
			| {
					type: 'info'
					query: string
			  }
			| {
					type: 'challenge'
					index: number
					description: string
					responses: FBCollection<string | null>
			  }
		)
		completedChallenges: FBCollection<{
			challenge: string
			responses: FBCollection<{
				response: FBResourceValueType<
					typeof pendingChallengeResponseResource
				> | null
				promptScores: {
					correct: boolean
					points: number
				}[]
			}>
		}>
	}
> = {
	path: 'gameStates',
	deserialize(
		{ currentView, completedChallenges, status, startTime, musicStartTime },
		gameId
	) {
		return {
			status,
			gameId,
			startTime: deserializeFBDate(startTime),
			musicStartTime: musicStartTime
				? deserializeFBDate(musicStartTime)
				: undefined,
			currentView:
				currentView.type === 'info'
					? {
							type: 'info',
							slideIndex: currentView.slideIndex,
							query: deserializeAny(currentView.query),
							music: currentView.music ?? undefined,
							startTime: deserializeFBDate(currentView.startTime),
							last: currentView.last,
					  }
					: {
							type: 'challenge',
							slideIndex: currentView.slideIndex,
							challengeIndex: currentView.index,
							challenge: deserializeAny(currentView.description),
							responsesByTeam: deserializeObjectMap(
								currentView.responses,
								deserializeAny
							),
							music: currentView.music ?? undefined,
							startTime: deserializeFBDate(currentView.startTime),
							last: currentView.last,
					  },
			completedChallenges: deserializeObjectMap(completedChallenges, (v) => ({
				challengeDescription: deserializeAny(v.challenge),
				responsesByTeam: deserializeObjectMap(v.responses, (v, teamId) => ({
					challengeResponse: v.response
						? pendingChallengeResponseResource.deserialize(v.response, teamId, {
								gameId,
								slideIndex: currentView.slideIndex,
						  })
						: undefined,
					promptScores: v.promptScores,
				})),
			})),
		}
	},
	serialize({
		status,
		currentView,
		completedChallenges,
		startTime,
		musicStartTime,
	}) {
		const a = musicStartTime
			? (serializeFBDate(musicStartTime) as number)
			: null
		return {
			status,
			startTime: serializeFBDate(startTime),
			musicStartTime: a,
			currentView:
				currentView.type === 'info'
					? {
							type: 'info',
							slideIndex: currentView.slideIndex,
							query: serializeAny(currentView.query),
							music: currentView.music ?? null,
							startTime: serializeFBDate(currentView.startTime),
							last: currentView.last,
					  }
					: {
							type: 'challenge',
							slideIndex: currentView.slideIndex,
							index: currentView.challengeIndex,
							description: serializeAny(currentView.challenge),
							responses: serializeObjectMap(
								currentView.responsesByTeam,
								serializeAny
							),
							music: currentView.music ?? null,
							startTime: serializeFBDate(currentView.startTime),
							last: currentView.last,
					  },
			completedChallenges: serializeObjectMap(completedChallenges, (v) => ({
				challenge: serializeAny(v.challengeDescription),
				responses: serializeObjectMap(v.responsesByTeam, (v) => ({
					// @ts-ignore
					response: v?.challengeResponse
						? pendingChallengeResponseResource.serialize(v.challengeResponse)
						: null,
					// @ts-ignore // TODO
					promptScores: v.promptScores,
				})),
			})),
		}
	},
}
