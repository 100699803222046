import { Howl } from 'howler'
import createListeners, { ListenerFunction } from '../../utils/createListeners'

const crossFadeDuration = 1000

export type SongStatus =
	| 'playing'
	| 'stopped'
	| 'loading'
	| 'locked'
	| undefined

export default class SoundManager {
	private currentSong?: {
		sound: Howl
		dispose(): void
	}

	private songStatus: SongStatus
	private songStatusListeners = createListeners<SongStatus>()

	private setSongStatus(songStatus: SongStatus, key?: string) {
		this.songStatus = songStatus
		this.songStatusListeners.dispatch(songStatus)
	}

	addSongStatusListener(fn: ListenerFunction<SongStatus>) {
		fn(this.songStatus)
		return this.songStatusListeners.add(fn)
	}

	setSong(
		source: string | undefined,
		startTime?: number,
		loop = false,
		uid?: string
	) {
		const prevSong = this.currentSong
		if (prevSong) {
			const { sound: prevSound, dispose } = prevSong
			dispose()
			// prevSound.fade(prevSound.volume(), 0, crossFadeDuration)

			let h: any

			const after = () => {
				prevSound.stop()
				clearTimeout(h)
			}

			// Just in case. I don't think this happens, but this is
			// just in case to prevent sounds playing longer than they should.
			//
			// Edit: This does happen...
			h = setTimeout(() => {
				if (process.env.NODE_ENV !== 'production') {
					console.error('Song didnt stop after fade!')
				}
				prevSound.stop()
			}, crossFadeDuration * 2)

			// prevSound.once('fade', after)
			after()
			this.currentSong = undefined
			this.setSongStatus(undefined, uid)
		}

		// Now start the new one
		if (source) {
			const song = new Howl({
				src: source,
			})

			const disposeAnchor = startTime
				? anchorSound(song, startTime, loop, uid)
				: undefined

			// Note: This doesn't really work because
			// as soon as we call seek() it resets the volume
			// if (prevSound) {
			// song.fade(0, 1, crossFadeDuration)
			// console.log('fade in')
			// }

			song.loop(loop)
			// ;[
			// 	'load',
			// 	'loaderror',
			// 	'play',
			// 	'playerror',
			// 	'end',
			// 	'pause',
			// 	'stop',
			// 	'mute',
			// 	'volume',
			// 	'rate',
			// 	'seek',
			// 	'fade',
			// 	'unlock',
			// ].forEach((key) => {
			// 	song.on(key, () => {
			// 		console.log('--on', key, uid)
			// 	})
			// })

			this.setSongStatus('loading', uid)

			let disposed = false

			const onLoad = () => {
				if (disposed) return
				this.setSongStatus('locked', uid)
			}
			const onPlay = () => {
				if (disposed) return
				this.setSongStatus('playing', uid)
			}

			const onStop = () => {
				if (disposed) return
				this.setSongStatus('stopped', uid)
			}

			song.on('load', onLoad)
			song.on('play', onPlay)
			song.on('stop', onStop)

			song.play()

			const dispose = () => {
				disposed = true
				disposeAnchor?.()
				song.off('load', onLoad)
				song.off('play', onPlay)
				song.off('stop', onStop)
			}

			const thisSong = {
				sound: song,
				dispose,
			}

			this.currentSong = thisSong

			return () => {
				if (this.currentSong === thisSong) {
					this.setSong(undefined, undefined, undefined, 'CC-' + uid)
				}
			}
		} else {
			return () => {}
		}
	}
}

function anchorSound(sound: Howl, anchor: number, loop: boolean, uid?: string) {
	// return () => {}
	let adjustHandle: any
	let startLaterHandle: any
	let restartHandle: any

	let disposed = false

	const getTargetPosition = () => {
		const now = new Date().getTime()
		return (now - anchor) / 1000
	}

	const adjust = () => {
		let targetPosition = getTargetPosition()

		const duration = sound.duration()

		if (duration) {
			if (targetPosition > duration) {
				if (loop) {
					targetPosition = targetPosition % duration
				} else {
					sound.stop()
				}
			}
		}

		const realPosition = sound.seek() as number

		if (Math.abs(targetPosition - realPosition) > 0.1) {
			sound.seek(targetPosition > 0 ? targetPosition : 0)
		}

		adjustHandle = setTimeout(adjust, 1500)
	}

	const onPlay = () => {
		if (disposed) {
			// NOTE: This check is very important because Howler will fire
			// the 'play' event even after the dispose function below is called
			// which removes the event listener. Without this check we get
			// songs playing twice.
			return
		}
		// Just to be safe
		clearTimeout(adjustHandle)
		clearTimeout(startLaterHandle)

		const target = getTargetPosition()
		if (target >= 0) {
			adjust()
		} else {
			sound.stop()

			// console.log('stop but play again in ' + -target * 1000)

			// We need to do this in a setTimeout because
			// calling sound.stop() fires the 'stop' event
			// after a timeout which would clear the
			// handle we're making here.
			restartHandle = setTimeout(() => {
				startLaterHandle = setTimeout(() => {
					// console.log('AFTER TIMEOUT PLay')
					sound.play()
				}, -target * 1000)
			})
		}
	}

	const onPause = () => {
		clearTimeout(adjustHandle)
		clearTimeout(startLaterHandle)
	}

	const onStop = () => {
		clearTimeout(adjustHandle)
		clearTimeout(startLaterHandle)
	}

	sound.on('play', onPlay)
	sound.on('pause', onPause)
	sound.on('stop', onStop)

	return () => {
		// console.log('dispose anchor', uid)
		clearTimeout(adjustHandle)
		clearTimeout(startLaterHandle)
		clearTimeout(restartHandle)
		sound.off('play', onPlay)
		sound.off('pause', onPause)
		sound.off('stop', onStop)
		disposed = true
	}
}
