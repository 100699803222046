import React, { useEffect, useState } from 'react'
import UserNameFormatter from '../../formatters/UserNameFormatter'
import { GameChallengeDescription } from '../../gameplay/core/types'
import Button from '../../ui/Button'
import LargeHeader from '../../components/LargeHeader'
import CardContent from '../../ui/CardContent'
import Card from '../../ui/Card'
import playSound from '../../game/playSound'
import sharedStyles from '../../ui/sharedStyles'
import { useBGColor } from '../../views/FancyBackground'
export function BuzzTeamResponsesView({
	userId,
	teamId,
	challengeDecription,
	onScoreAnswer,
	onClearBuzz,
	onEndQuestion,
}: {
	userId: string
	teamId: string
	challengeDecription: GameChallengeDescription
	// pendingResponses: TeamChallengeResponse[] | undefined
	// challengePropmpts: ChallengePrompt[]
	// onRemoveResponse(teamId: string): void
	onScoreAnswer(teamId: string, index: number, correct: boolean): void
	// isAnswerCorrect(teamId: string, promptIndex: number): boolean | undefined
	onClearBuzz(): void
	onEndQuestion(): void
}) {
	const [shouldEndQuestion, setShouldEndQuestion] = useState(false)
	// let response: TeamChallengeResponse | undefined
	// let prompt: ChallengePrompt | undefined
	// if (pendingResponses) {
	// 	const index = pendingResponses.findIndex((r) => r.teamId === teamId)
	// 	if (index >= 0) {
	// 		response = pendingResponses[index]
	// 		prompt = challengePropmpts[index]
	// 	}
	// }
	useEffect(() => {
		if (shouldEndQuestion) {
			setShouldEndQuestion(false)
			onEndQuestion()
		}
	}, [onEndQuestion, shouldEndQuestion])

	const onGood = () => {
		// Set all the answers as correct
		challengeDecription.prompts.forEach((prompt, i) => {
			onScoreAnswer(teamId, i, true)
		})

		setShouldEndQuestion(true)
	}

	const onBad = () => {
		// Set all the answers as incorrect
		challengeDecription.prompts.forEach((prompt, i) => {
			onScoreAnswer(teamId, i, false)
		})

		// We want the state to update before we submit
		setTimeout(() => {
			onClearBuzz()
		}, 100)
	}

	// Let the host know someone buzzed in
	useEffect(() => {
		playSound('ding')
	}, [])

	useBGColor(sharedStyles.colors.blue)

	return (
		<Card mb={3}>
			<CardContent>
				<LargeHeader
					mb={5}
					title={
						<>
							<UserNameFormatter value={userId} /> just buzzed!
						</>
					}
				/>
				<Button tone="green" mb={4} onClick={onGood}>
					Right!
				</Button>
				<Button tone="red" onClick={onBad}>
					Wrong!
				</Button>
			</CardContent>
		</Card>
	)
}
