import React from 'react'
import { gameTeamResource } from '../entities/Team'
import useRecord from '../firebase/useRecord'

export default function TeamNameFormatter({
	value: id,
	gameId,
}: {
	value: string
	gameId: string
}) {
	// TODO: Only watch the subkey we need
	const team = useRecord([gameTeamResource, { gameId }], id)

	return <>{team?.name}</>
}
