import { useState } from 'react'
import useSession from '../../auth/useSession'
import Button from '../../ui/Button'
import Input from '../../ui/Input'
import React from 'react'
import Alert from '../../ui/Alert'
import Box from '../../ui/Box'
import LogoProxyView from '../../game/logo/LogoProxyView'

interface Props {
	code: string | undefined
	onCodeChange(code: string): void
	status: 'initial' | 'pending' | 'invalid'
}

export default function SelectGameScreen({
	code,
	onCodeChange,
	status,
}: Props) {
	const userCodeState = useState<string | undefined>(code)
	const userNameState = useState<string>()

	const session = useSession()

	return (
		<>
			<Box css={{ textAlign: 'center' }} mb={5}>
				<LogoProxyView name="selectScreen" />
			</Box>
			{(!code || status !== 'initial') && (
				<Input mb={3} valueState={userCodeState} placeholder="Game code" />
			)}
			{!session.loggedIn && (
				<Input mb={3} valueState={userNameState} placeholder="Your name" />
			)}
			{status === 'invalid' && <Alert mb={2}>Invalid game code entered</Alert>}
			<Button
				disabled={status === 'pending'}
				tone="pink"
				onClick={() => {
					let userName: string | undefined, userCode: string | undefined
					if (!session.loggedIn) {
						// Must log in
						userName = userNameState[0]
						if (!userName) return
					}
					if (!code || status === 'invalid') {
						// Must provide code
						userCode = userCodeState[0]

						if (!userCode) return
					}

					// Do what you need to do
					if (userName && !session.loggedIn) {
						session.logInAsGuest(userName)
					}
					if (userCode) {
						onCodeChange(userCode)
					}
				}}
			>
				Join Game &rarr;
			</Button>
		</>
	)
}
