import {
	ChallengePrompt,
	GameChallenge,
	GameChallengeDescription,
} from '../core/types'

export default function getChallengeFromDescription(
	challengeDescription: GameChallengeDescription,
	defaultPromptPoints: number
): GameChallenge {
	return {
		settings: {
			...challengeDescription.settings,
			buzzIn: challengeDescription.settings?.buzzIn ?? false,
		},
		query: challengeDescription.query,
		prompts: challengeDescription.prompts.map((promptDescription) => {
			const out: ChallengePrompt = {
				type: promptDescription.type,
				data: promptDescription.data,
				points:
					promptDescription.points ??
					challengeDescription.settings?.defaultPromptPoints ??
					defaultPromptPoints,
			}
			return out
		}),
	}
}
