const base = '/assets/sounds'

const sounds = {
	ding: 'game-start.mp3',
	yourBuzz: 'your-buzz.mp3',
	shortBuzz: 'short-buzz.mp3',
	warmChime: 'warm-chime.mp3',
	shortPop: 'short-pop.mp3',
	softDing: 'soft-ding.mp3',
}

export type SoundName = keyof typeof sounds

export default function playSound(key: SoundName) {
	const url = `${base}/${sounds[key]}`
	const sound = new Howl({
		src: url,
	})

	sound.play()
}
