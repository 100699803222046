import {
	FBCollection,
	FBId,
	FBResourceAndOptions,
	FBValue,
	MapDatesAndNumbers,
	NewRecord,
} from './types'

type GetPath<T extends FBResourcePathOptions> = (options: T) => string

export interface FBResourcePathOptions {
	[key: string]: string | number
}

export interface FBResource<T, V extends FBValue, O = void> {
	path: O extends FBResourcePathOptions ? GetPath<O> : string
	deserialize(value: V, id: FBId, options: O): T
	serialize(value: NewRecord<T>): MapDatesAndNumbers<V>
}

export type FBResourceRecordType<T> = T extends FBResource<infer U, any, any>
	? U
	: never
export type FBResourceValueType<T> = T extends FBResource<any, infer U, any>
	? U
	: never

// function isX<T, V extends FBValue, O>(
// 	value: FBResourceAndOptions<T, V, O>
// ): value is FBResource<T, V, O> {
// 	return Array.isArray(value) === false
// }

export function normalizeResourceAndOptions<T, V extends FBValue, O>(
	o: FBResourceAndOptions<T, V, O>
): [FBResource<T, V, O>, string, O] {
	if (Array.isArray(o)) {
		const [resource, options] = o
		return [
			resource,
			resource.path instanceof Function
				? resource.path(options)
				: resource.path,
			options,
		]
	} else {
		return [
			(o as unknown) as any,
			((o as unknown) as any).path as string,
			(undefined as unknown) as any,
		]
	}
}

export function deserializeRecord<T, V extends FBValue, O>(
	resource: FBResource<T, V, O>,
	id: FBId,
	value: V,
	options: O
) {
	if (value === null) return undefined
	return resource.deserialize(value, id, options)
}

export function deserializeRecords<T, V extends FBValue, O>(
	resource: FBResource<T, V, O>,
	values: FBCollection<V>,
	options: O
) {
	if (values === null) return undefined
	return Object.keys(values).map((id) =>
		resource.deserialize(values[id], id, options)
	)
}

export function serializeRecord<T, V extends FBValue, O>(
	resource: FBResource<T, V, O>,
	value: NewRecord<T> | undefined
) {
	if (value === undefined) return null
	return resource.serialize(value)
}
