import { Dispatch, SetStateAction, useState } from 'react'
import { EqualityFunction } from './EqualityFunction'
import isStrictEqual from './isStrictEqual'
import toggleValue from './toggleValue'

export type StateLink<T> = [T, Dispatch<SetStateAction<T>>]

function useControlledState<T>(
	stateValue: StateLink<T> | undefined
): StateLink<T | undefined>
function useControlledState<T>(
	stateValue: StateLink<T> | undefined,
	defaultValue: T
): StateLink<T>
function useControlledState<T>(
	stateValue: StateLink<T> | undefined,
	defaultValue?: T
) {
	const localStateValue = useState<T | undefined>(defaultValue)
	return stateValue ?? localStateValue
}

export { useControlledState }

export function getStateLinkWithDefaultValue<T>(
	[value, setValue]: StateLink<T>,
	defaultValue: T
): StateLink<T> {
	return [value === undefined ? defaultValue : value, setValue]
}

/**
 * Converts a regular setter to a reducer.
 * To create a one-way link don't pass a second parameter.
 */
export function createStateLink<T>(
	value: T,
	setValue?: (newValue: T) => void
): StateLink<T> {
	return [
		value,
		(newValueOrReducer) => {
			const newValue =
				newValueOrReducer instanceof Function
					? newValueOrReducer(value)
					: newValueOrReducer
			return setValue?.(newValue)
		},
	]
}

export function getWatchedStateLink<T>(
	[value, setValue]: StateLink<T>,
	fn: (newValue: T) => void
): StateLink<T> {
	return [
		value,
		(newValue) => {
			if (newValue instanceof Function) {
				setValue((prevValue) => {
					const v = newValue(prevValue)
					fn(v)
					return v
				})
			} else {
				fn(newValue)
				setValue(newValue)
			}
		},
	]
}

function invertBoolean(value: boolean, invert: boolean | undefined) {
	return invert ? !value : value
}

interface Options<T> {
	invert?: boolean
	equalityFunction?: EqualityFunction<T>
}

export function invertBooleanLink(link: StateLink<boolean>) {
	return mapStateLink(
		link,
		(value) => !value,
		(value) => !value
	)
}

// For checkboxes
export function mapListLinkToSelectedLink<T>(
	listLink: StateLink<T[]>,
	selectionValue: T,
	{ equalityFunction = isStrictEqual, invert }: Options<T> = {}
): StateLink<boolean> {
	return mapStateLink(
		listLink,
		(list) => {
			return invertBoolean(
				list?.findIndex((value) => equalityFunction(value, selectionValue)) >=
					0,
				invert
			)
		},
		(selected, prevList) => {
			const shouldUse = invertBoolean(selected, invert)
			return toggleValue(prevList, selectionValue, shouldUse)
		}
	)
}

// For radio boxes
export function mapValueLinkToSelectedLink<T>(
	valueLink: StateLink<T>,
	selectionValue: T,
	optionsOrEqualityFunction?: Options<T> | EqualityFunction<T>
): StateLink<boolean> {
	const { equalityFunction = isStrictEqual, invert } =
		optionsOrEqualityFunction instanceof Function
			? { equalityFunction: optionsOrEqualityFunction, invert: false }
			: optionsOrEqualityFunction || {}

	return mapStateLink(
		valueLink,
		(value) => {
			return invertBoolean(equalityFunction(value, selectionValue), invert)
		},
		(selected, prevValue) => {
			const shouldUse = invertBoolean(selected, invert)
			return shouldUse ? selectionValue : prevValue
		}
	)
}

export function mapStateLink<T, U>(
	[value, setValue]: StateLink<T>,
	toValue: (value: T) => U,
	fromValue: (newValue: U, prev: T) => T
): StateLink<U> {
	return [
		toValue(value),
		(newValueOrReducer) => {
			setValue((prevValue) => {
				const o =
					newValueOrReducer instanceof Function
						? newValueOrReducer(toValue(prevValue))
						: newValueOrReducer
				return fromValue(o, prevValue)
			})
		},
	]
}
