import React, { ReactNode } from 'react'
import CardContent from './CardContent'
import styled from './styled'
import Box from './Box'

interface Props {
	title?: ReactNode
	accessory?: ReactNode
}

const CardHeader = styled<Props>(
	({ children, css, title, accessory, ...props }) => {
		return (
			<CardContent
				css={[
					{
						display: 'flex',
						justifyContent: 'space-between',
					},
					css,
				]}
				{...props}
			>
				{(title ?? children) && (
					<Box css={{ fontSize: 20, fontWeight: 700 }}>{title ?? children}</Box>
				)}
				{accessory && <Box>{accessory}</Box>}
			</CardContent>
		)
	}
)

export default CardHeader
