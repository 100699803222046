import { useLayoutEffect } from 'react'
import {
	FBId,
	FBResourceAndOptions,
	FBValue,
} from '../libraries/firebase-utils/types'
import watchRecord, {
	watchRecords,
} from '../libraries/firebase-utils/watchRecord'
import useDatabase from './useDatabase'
import { useMemoFBResourceAndOptions } from './useRecord'

export function useWatchRecord<T, V extends FBValue, O>(
	r: FBResourceAndOptions<T, V, O>,
	id: FBId | undefined,
	onChange: ((value: T | undefined) => void) | undefined
) {
	r = useMemoFBResourceAndOptions(r)
	const db = useDatabase()
	useLayoutEffect(() => {
		if (!onChange) return
		// We would do this if much earlier but hooks...
		if (id) {
			return watchRecord(db, r, id, onChange)
		}
	}, [db, id, onChange, r])
}

export function useWatchRecords<T, V extends FBValue, O>(
	r: FBResourceAndOptions<T, V, O>,
	onChange: ((value: T[] | undefined) => void) | undefined
) {
	r = useMemoFBResourceAndOptions(r)
	const db = useDatabase()
	useLayoutEffect(() => {
		if (!onChange) return
		return watchRecords(db, r, onChange)
	}, [db, onChange, r])
}
