import { useEffect, useRef } from 'react'

export default function usePrevious<T>(value: T): T | undefined
export default function usePrevious<T>(value: T, defaultValue: T): T
export default function usePrevious<T>(value: T, defaultValue?: T) {
	const ref = useRef<T | undefined>(defaultValue)
	useEffect(() => {
		ref.current = value
	})
	return ref.current
}

export function usePreviousWith<T>(
	fn: (prev?: T) => T | undefined
): T | undefined
export function usePreviousWith<T>(fn: (prev: T) => T, defaultValue: T): T
export function usePreviousWith<T>(
	fn: (prev?: T) => T | undefined,
	defaultValue?: T
): T | undefined {
	const lastValid = useRef<T | undefined>(defaultValue)
	const value = fn(lastValid.current)

	useEffect(() => {
		lastValid.current = value
	}, [value])

	return value
}
