import React from 'react'
import {
	BrowserRouter as Router,
	Route,
	Switch,
	useHistory,
} from 'react-router-dom'
import { SessionProvider } from './auth/SessionProvider'
import FirebaseProvider from './firebase/FirebaseProvider'
import { Main } from './game/MainView'
import SoundManagerProvider from './libraries/sound-manager/SoundManagerProvider'

export default function App() {
	return (
		<FirebaseProvider>
			<SessionProvider>
				<SoundManagerProvider>
					<Router>
						<Routes />
					</Router>
				</SoundManagerProvider>
			</SessionProvider>
		</FirebaseProvider>
	)
}

function Routes() {
	const history = useHistory()
	const setGameId = (id: string) => history.push('/' + id)
	const onCreateGame = (id: string) => history.push(`/${id}/host`)

	const sharedProps = {
		onGameIdChange: setGameId,
		onCreate: onCreateGame,
	}

	return (
		<Switch>
			<Route
				path="/create"
				exact
				render={() => <Main mode="create" {...sharedProps} />}
			/>
			<Route
				path="/:id/host"
				render={({ match }) => (
					<Main
						mode="host"
						gameId={match.params.id}
						allowedToHostAll={true}
						{...sharedProps}
					/>
				)}
			/>
			<Route
				path="/:id/spectate"
				render={({ match }) => (
					<Main mode="spectate" gameId={match.params.id} {...sharedProps} />
				)}
			/>
			<Route
				path="/:id"
				render={({ match }) => (
					<Main mode="play" gameId={match.params.id} {...sharedProps} />
				)}
			/>
			<Route path="/">
				<Main mode="play" {...sharedProps} />
			</Route>
		</Switch>
	)
}
