import React, { useCallback, useState } from 'react'
import { FaChevronRight } from 'react-icons/fa'
import LargeHeader from '../../components/LargeHeader'
import MessagePane from '../../components/MessagePane'
import { Game } from '../../entities/Game'
import { setUserTeam } from '../../entities/PlayerSession'
import { TBUser } from '../../entities/TBUser'
import { gameTeamResource } from '../../entities/Team'
import useDatabase from '../../firebase/useDatabase'
import { useRecords } from '../../firebase/useRecord'
import useRecordActions from '../../firebase/useRecordActions'
import UserNameFormatter from '../../formatters/UserNameFormatter'
import playSound from '../../game/playSound'
import Button from '../../ui/Button'
import Card from '../../ui/Card'
import CardContent from '../../ui/CardContent'
import Input from '../../ui/Input'
import TeamItem from '../../views/TeamItem'

interface Props {
	game: Game
	user: TBUser
}

export default function SelectTeamScreen({ game, user }: Props) {
	const [mode, setMode] = useState<'join' | 'create'>('join')
	const teams = useRecords([gameTeamResource, { gameId: game.id }])
	const customTeamNameState = useState<string>()

	const db = useDatabase()
	const recordActions = useRecordActions()

	const createTeam = useCallback(
		async (teamName: string) => {
			const team = await recordActions.addRecord(
				[gameTeamResource, { gameId: game.id }],
				{
					name: teamName,
					memberIds: [],
				}
			)

			await setUserTeam(db, game.id, user.id, team.id)
		},
		[db, game.id, recordActions, user.id]
	)

	const joinTeam = useCallback(
		(teamId: string) => {
			return setUserTeam(db, game.id, user.id, teamId)
		},
		[db, game.id, user.id]
	)

	const onCreateClick = useCallback(() => {
		const teamName = customTeamNameState[0]?.trim()
		if (!teamName || teamName.length < 3) return

		createTeam(teamName)
	}, [createTeam, customTeamNameState])

	return (
		<>
			<LargeHeader
				mb={4}
				title={game.name}
				subtitle={
					<>
						By <UserNameFormatter value={game.creatorId} />
					</>
				}
			/>
			{teams === undefined ? (
				<MessagePane>Loading teams</MessagePane>
			) : teams && teams.length > 0 && mode === 'join' ? (
				<>
					<MessagePane mb={4}>Which team would you like to join?</MessagePane>
					{teams.map((team) => (
						<Card
							buttonMode
							key={team.id}
							mb={3}
							onClick={() => {
								playSound('shortPop')
								joinTeam(team.id)
							}}
						>
							<CardContent>
								<TeamItem team={team} accessory={<FaChevronRight />} />
							</CardContent>
						</Card>
					))}
					<Button tone="grayLight" onClick={() => setMode('create')}>
						Create a new team
					</Button>
				</>
			) : (
				<>
					<Input
						valueState={customTeamNameState}
						label="What would you like to call your team?"
						placeholder="Enter your team name"
						autoFocus
						mb={3}
					/>
					<Button mb={2} tone="blue" onClick={onCreateClick}>
						Create Team &rarr;
					</Button>
					{teams && teams.length > 0 && (
						<Button flat onClick={() => setMode('join')}>
							Cancel
						</Button>
					)}
				</>
			)}
		</>
	)
}
