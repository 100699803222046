import {
	deserializeFBDate,
	serializeFBDate,
} from '../libraries/firebase-utils/FBDate'
import {
	deserializeIdMap,
	FBIdMap,
	serializeIdMap,
} from '../libraries/firebase-utils/FBIdMap'
import { FBResource } from '../libraries/firebase-utils/FBResource'
import { FBId } from '../libraries/firebase-utils/types'
import { TBUser } from './TBUser'
import {
	deserializeAny,
	serializeAny,
} from '../libraries/firebase-utils/serializing'

export interface GameSettings {
	pointMultiplier?: number
	descriptionId: string
}

export interface Game {
	readonly id: string
	name: string
	ended: boolean
	creatorId: FBId
	hostIds: FBId[]
	createdOn: Date
	settings: GameSettings
	assets: string[][]
}

export function canUserHostGame(game: Game, user: TBUser) {
	return game.hostIds.includes(user.id)
}

export const gameResource: FBResource<
	Game,
	{
		name: string
		ended: boolean
		creator: FBId
		hosts: FBIdMap
		createdOn: number
		settings: string
		assets: string
	}
> = {
	path: 'games',
	deserialize: (
		{ name, ended, creator: creatorId, hosts, createdOn, settings, assets },
		id
	) => ({
		id,
		name,
		ended,
		creatorId,
		hostIds: hosts && deserializeIdMap(hosts),
		createdOn: deserializeFBDate(createdOn),
		settings: deserializeAny(settings),
		assets: deserializeAny(assets),
	}),
	serialize: ({
		name,
		ended,
		creatorId: creator,
		hostIds,
		createdOn,
		settings,
		assets,
	}) => ({
		name,
		ended,
		creator,
		hosts: serializeIdMap(hostIds),
		createdOn: serializeFBDate(createdOn),
		settings: serializeAny(settings),
		assets: serializeAny(assets),
	}),
}
