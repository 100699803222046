import { Asset } from '../libraries/assets-manager/Asset'

function getPathExt(path: string) {
	const index = path.lastIndexOf('.')
	if (index >= 0) {
		return path.slice(index + 1)
	}
	return undefined
}

export default function getFileTypeForPath(path: string) {
	const ext = getPathExt(path)
	if (!ext) return undefined
	return getFileTypeForExt(ext)
}

function getFileTypeForExt(ext: string): Asset['type'] | undefined {
	ext = ext.toLowerCase()

	if (ext === 'jpg' || ext === 'jpeg' || ext === 'png' || ext === 'gif') {
		return 'image'
	} else if (ext === 'mp3') {
		return 'sound'
	} else {
		return undefined
	}
}
