import React, { ReactNode, useMemo } from 'react'
import { Redirect } from 'react-router-dom'
import SelectGameScreen from '../screens/select/SelectGameScreen'
import JsonView from '../views/JsonView'
import SelectTeamScreen from '../screens/select/SelectTeamScreen'
import PlayGameScreen from '../screens/play/PlayGameScreen'
import CreateGameScreen from '../screens/host/CreateGameScreen'
import HostGameScreen from '../screens/host/HostGameScreen'
import Box from '../ui/Box'
import ActivityIndicator from '../ui/ActivityIndicator'
import { ScoreFormattingProvider } from './ScoreFormatter'
import ActiveGameInfoContext from '../gameplay/ActiveGameInfoContext'
import { Game } from '../entities/Game'
import { GameState } from './GameState'

export function Screens({ gameState }: { gameState: GameState }) {
	switch (gameState.type) {
		case 'initialLoad':
		case 'gameSelectedLoadingInfo':
			return (
				<Box css={{ textAlign: 'center' }}>
					<ActivityIndicator />
				</Box>
			)
		case 'selectGame':
			return (
				<SelectGameScreen
					code={gameState.code}
					onCodeChange={gameState.setCode}
					status={gameState.status}
				/>
			)
		case 'selectTeam':
			return <SelectTeamScreen game={gameState.game} user={gameState.user} />
		case 'playing':
		case 'spectating':
		case 'hostGame':
			return (
				<GameplayProvider game={gameState.game}>
					{gameState.type === 'playing' ? (
						<PlayGameScreen
							game={gameState.game}
							gameplayState={gameState.gameplayState}
							team={gameState.team}
							user={gameState.user}
						/>
					) : gameState.type === 'spectating' ? (
						<PlayGameScreen
							game={gameState.game}
							gameplayState={gameState.gameplayState}
							user={gameState.user}
						/>
					) : (
						<HostGameScreen
							game={gameState.game}
							description={gameState.gameDescription}
						/>
					)}
				</GameplayProvider>
			)
		case 'createGame':
			return (
				<CreateGameScreen
					user={gameState.user}
					onCreateGame={gameState.createGame}
				/>
			)
		case 'selectedGameNotPlayable':
			return <Redirect to={`/${gameState.game.id}/host`} />
		default:
			return <JsonView value={gameState} />
	}
}

function GameplayProvider({
	game,
	children,
}: {
	children: ReactNode
	game: Game
}) {
	const info = useMemo(
		() => ({
			game,
		}),
		[game]
	)

	return (
		<ActiveGameInfoContext.Provider value={info}>
			<ScoreFormattingProvider multiplier={game.settings.pointMultiplier}>
				{children}
			</ScoreFormattingProvider>
		</ActiveGameInfoContext.Provider>
	)
}
