import { useEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export default function useResizeObserver(
	node: Element | undefined | null,
	onChange: () => void
) {
	useEffect(() => {
		if (!node) return

		if (typeof ResizeObserver === 'function') {
			const resizeObserver = new ResizeObserver(onChange)
			resizeObserver.observe(node)
			return () => resizeObserver.disconnect()
		} else {
			window.addEventListener('resize', onChange)
			return () => window.removeEventListener('resize', onChange)
		}
	}, [node, onChange])
}
