export type RichText = string | any[]

export default function stringifyRichText(text: RichText | undefined) {
	if (!text) return undefined
	if (typeof text === 'string') {
		return text
	} else {
		// TODO
		// console.log(text)
		return undefined
	}
}
