import React, { useEffect, useMemo, useState } from 'react'
import Container from '../ui/Container'
import FancyBackground from '../views/FancyBackground'
import LogoProvider from './logo/LogoProvider'
import MainMenu from './MainMenu'
import { Screens } from './Screens'
import { WithGameState } from './WithGameState'
import Box from '../ui/Box'
import { GameState, getGameStateGame } from './GameState'
import { Game } from '../entities/Game'
import useRecord from '../firebase/useRecord'
import { gameplayStateResource } from '../entities/GameplayState'
import assetsManager, {
	AssetStatus,
} from '../libraries/assets-manager/assetsManager'
import useWatchValue from '../utils/useWatchValue'
import useDatabase from '../firebase/useDatabase'
import useUser from '../auth/useUser'
interface Props {
	gameId?: string
	mode: 'play' | 'create' | 'host' | 'spectate'
	allowedToHostAll?: boolean
	onGameIdChange(gameId: string): void
	onCreate(gameId: string): void
}
export function Main({
	gameId,
	allowedToHostAll,
	onGameIdChange,
	mode,
	onCreate,
}: Props) {
	return (
		<WithGameState
			mode={mode}
			gameId={gameId}
			onGameIdChange={onGameIdChange}
			onCreate={onCreate}
			allowedToHostAll={allowedToHostAll ?? false}
		>
			{(gameState) => <MainWithGameState gameState={gameState} />}
		</WithGameState>
	)
}

function MainWithGameState({ gameState }: { gameState: GameState }) {
	usePreloading(getGameStateGame(gameState))

	return (
		<FancyBackground>
			<Box css={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
				<LogoProvider>
					<MainMenu gameState={gameState} />
					<Container css={{ paddingBottom: 20 }}>
						<Screens gameState={gameState} />
					</Container>
				</LogoProvider>
			</Box>
		</FancyBackground>
	)
}

function usePreloading(game: Game | undefined, amount = 5) {
	const gameplayState = useRecord(gameplayStateResource, game?.id)

	const slideIndex = useMemo(() => {
		// Don't do anything while gameplayState is loading
		if (gameplayState === undefined) return undefined
		return gameplayState ? gameplayState.currentView.slideIndex : -1
	}, [gameplayState])

	const assetsToLoad = useMemo(() => {
		if (!game) return undefined
		if (slideIndex === undefined) return undefined

		const assetsToLoad: { slideIndex: number; assets: string[] }[] = []

		const assets = game.assets

		let i = slideIndex + 1
		while (i < assets.length && assetsToLoad.length < amount) {
			const slideAssets = assets[i]
			if (slideAssets.length > 0) {
				assetsToLoad.push({
					slideIndex: i,
					assets: slideAssets,
				})
			}

			++i
		}

		return assetsToLoad
	}, [amount, game, slideIndex])

	useEffect(() => {
		if (!assetsToLoad) return
		if (!game) return

		// Start it with a 1-second delay so that other resources can load first
		const h = setTimeout(() => {
			for (const a of assetsToLoad) {
				assetsManager.add(a.assets)
			}
		}, 1000)

		// Unload all previous slides
		if (slideIndex != null && slideIndex > 0) {
			for (let i = 0; i <= slideIndex - 1; ++i) {
				const slideAssets = game.assets[i]
				for (const url of slideAssets) {
					if (assetsManager.remove(url)) {
						// console.log('Unloaded', i)
					}
				}
			}
		}

		return () => clearTimeout(h)
	}, [assetsToLoad, game, slideIndex])

	// Watch the assets for the current slide and write it to the db

	const slideAssets = useMemo(() => {
		if (!game) return
		if (slideIndex == null) return undefined
		if (slideIndex <= 0) return undefined

		return {
			thisSlide: {
				index: slideIndex,
				assets: game.assets[slideIndex],
			},
			nextSlide:
				slideIndex < game.assets.length - 2
					? {
							index: slideIndex + 1,
							assets: game.assets[slideIndex + 1],
					  }
					: undefined,
		}
	}, [game, slideIndex])

	useUpdateServer(game?.id, slideAssets?.thisSlide)
	useUpdateServer(game?.id, slideAssets?.nextSlide)
}

function useUpdateServer(
	gameId: string | undefined,
	options: { index: number; assets: string[] } | undefined
) {
	const db = useDatabase()
	const user = useUser()

	const assetsStatus = useAssetsStatus(options)

	useWatchValue(assetsStatus, () => {
		if (!user) return
		if (!gameId) return
		if (!assetsStatus) return

		const key = `/playerAssets/${user.id}/${gameId}/${assetsStatus.index}`
		const value = assetsStatus
			? removeUndefinedKeys(assetsStatus.statuses)
			: null

		// Update the server
		// console.log('update server', key, value, options, assetsStatus)
		db.ref(key).set(value)
	})
}

function removeUndefinedKeys(object: any) {
	const out: any = []
	for (const key in object) {
		const value = object[key]
		// if (value !== undefined) {
		out.push({
			url: key,
			status: value || null,
		})
		// out[encodeURIComponent(key)] = value ?? null
		// }
	}
	return out
}

function useAssetsStatus(
	options: { index: number; assets: string[] } | undefined
) {
	const [statuses, setStatuses] = useState<{
		index: number
		statuses: { [url: string]: AssetStatus }
	}>()

	useEffect(() => {
		if (!options) return

		const fns: (() => void)[] = []
		options.assets.forEach((url) => {
			fns.push(
				assetsManager.watchUrl(url, (status) => {
					setStatuses((obj) => {
						if (obj) {
							if (obj.index === options.index) {
								return {
									index: obj.index,
									statuses: {
										...obj.statuses,
										[url]: status,
									},
								}
							}
						}

						return {
							index: options.index,
							statuses: {
								[url]: status,
							},
						}
					})
				})
			)
		})

		return () => {
			fns.forEach((fn) => {
				fn()
			})
		}
	}, [options])

	if (statuses?.index === options?.index) return statuses
	return undefined
}
