import { useMemo } from 'react'
import addRecord from '../libraries/firebase-utils/addRecord'
import getRecord, { getRecords } from '../libraries/firebase-utils/getRecord'
import setRecord, {
	SetRecordAction,
} from '../libraries/firebase-utils/setRecord'
import {
	FBId,
	FBResourceAndOptions,
	FBValue,
	NewRecord,
} from '../libraries/firebase-utils/types'
import useDatabase from './useDatabase'

export default function useRecordActions() {
	const db = useDatabase()
	return useMemo(() => {
		return {
			setRecord: <T, V extends FBValue, O>(
				r: FBResourceAndOptions<T, V, O>,
				id: FBId,
				action: SetRecordAction<T>
			) => setRecord(db, r, id, action),
			getRecord: <T, V extends FBValue, O>(
				r: FBResourceAndOptions<T, V, O>,
				id: FBId
			) => getRecord(db, r, id),
			getRecords: <T, V extends FBValue, O>(r: FBResourceAndOptions<T, V, O>) =>
				getRecords(db, r),
			addRecord: <T, V extends FBValue, O>(
				r: FBResourceAndOptions<T, V, O>,
				record: NewRecord<T>
			) => addRecord(db, r, record),
		}
	}, [db])
}
