import { FBResource } from '../libraries/firebase-utils/FBResource'
import getRecord from '../libraries/firebase-utils/getRecord'

export interface PlayerSession {
	readonly userId: string
	gameId?: string
	teamId?: string
}

export const playerSessionResource: FBResource<
	PlayerSession,
	{
		gameId: string | null
		teamId: string | null
	}
> = {
	path: 'playerSessions',
	deserialize({ gameId, teamId }, userId) {
		return {
			userId,
			gameId: gameId ?? undefined,
			teamId: teamId ?? undefined,
		}
	},
	serialize({ gameId, teamId }) {
		return {
			gameId: gameId ?? null,
			teamId: teamId ?? null,
		}
	},
}

export async function setUserTeam(
	db: firebase.database.Database,
	gameId: string,
	userId: string,
	teamId: string | undefined
) {
	const playerSession = await getRecord(db, playerSessionResource, userId)

	const prevTeamId = playerSession?.teamId
	if (prevTeamId !== teamId) {
		const updates = {} as any

		if (prevTeamId) {
			// Remove from previous team
			updates[`/teams/${gameId}/${prevTeamId}/members/${userId}`] = null
		}

		// Update the user
		updates[`/playerSessions/${userId}/teamId`] = teamId ?? null

		if (teamId) {
			// Add the user to the team
			// addRecord(db, gameTeamResource)
			// Add the user to the new team
			// TODO: This replaces the previous user.
			updates[`/teams/${gameId}/${teamId}/members/${userId}`] = true
		}

		await db.ref().update(updates)
	}
}
