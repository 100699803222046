import styled from './styled'
import Box from './Box'
import React from 'react'

interface Props {
	contentEnabled?: boolean
}

const Disabled = styled<Props>(
	({ contentEnabled, css, children, ...props }) => {
		return (
			<Box
				css={[
					!contentEnabled && {
						opacity: 0.5,
						pointerEvents: 'none',
					},
					css,
				]}
				{...props}
			>
				{children}
			</Box>
		)
	}
)

export default Disabled
