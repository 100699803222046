import React from 'react'
import JsonView from '../views/JsonView'
import { ChallengePromptHandler, GameQueryHandler } from './core/handlerTypes'
import inputPrompt from './promptHandlers/inputPrompt'
import simpleQuery from './queryHandlers/simpleQuery'
import voicePrompt from './promptHandlers/voicePrompt'
import multipleChoicePrompt from './promptHandlers/multipleChoicePrompt'
import messageQuery from './queryHandlers/messageQuery'
import { GameQuery, ChallengePromptDescription } from './core/types'

const queryHandlers = [simpleQuery, messageQuery]
const promptHandlers = [inputPrompt, voicePrompt, multipleChoicePrompt]

export function getQueryHandler(type: string): GameQueryHandler<unknown> {
	return queryHandlers.find((h) => h.type === type) ?? defaultQueryHandler
}

export function getPromptHandler(
	type: string
): ChallengePromptHandler<unknown, unknown, unknown> {
	return (
		promptHandlers.find((h) => h.type === type) ??
		((defaultPromptHandler as unknown) as any)
	)
}

export function getQueryAssets(query: GameQuery) {
	return getQueryHandler(query.type).getAssetUrls(query.data)
}

export function getPromptAssets(prompt: ChallengePromptDescription) {
	return getPromptHandler(prompt.type).getAssetUrls(prompt.data)
}

////

const defaultQueryHandler: GameQueryHandler<any> = {
	type: 'default',
	render: (data) => <JsonView value={data} />,
	getAssetUrls: () => [],
}

const defaultPromptHandler: ChallengePromptHandler<any, any, any> = {
	type: 'default',
	interactive: false,
	submitImmediately: false,
	getAssetUrls: () => [],
	renderInputView({ data, points }, responseLink) {
		return (
			<>
				<div>{points} points</div>
				<JsonView value={data} />
				{/* TODO: We can add a text area for inputting json */}
			</>
		)
	},
	renderUserResponse(res) {
		return <JsonView value={res} />
	},
	renderCorrectAnswer(answer) {
		return <JsonView value={answer} />
	},
}
