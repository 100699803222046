import React, { useMemo } from 'react'
import {
	gameplayStateResource,
	CompletedChallengeTeamInfo,
} from '../../entities/GameplayState'
import useRecord from '../../firebase/useRecord'
import TeamNameFormatter from '../../formatters/TeamNameFormatter'
import ActivityIndicator from '../../ui/ActivityIndicator'
import ScoreFormatter from '../../game/ScoreFormatter'

export function LeaderBoard({
	gameId,
	showLastRoundPoints,
}: {
	gameId: string
	showLastRoundPoints?: boolean
}) {
	const gameplayState = useRecord(gameplayStateResource, gameId)

	const info = useMemo(() => {
		if (!gameplayState) return undefined

		const { completedChallenges, currentView } = gameplayState
		const currentSlideIndex = currentView.slideIndex

		const byTeam: {
			[teamId: string]: { score: number; pointsLastRound: number }
		} = {}

		Object.keys(completedChallenges).forEach((key) => {
			const slideIndex = parseFloat(key)
			const c = completedChallenges[slideIndex]

			for (const teamId in c.responsesByTeam) {
				let i = byTeam[teamId]
				if (!i) {
					i = {
						pointsLastRound: 0,
						score: 0,
					}
					byTeam[teamId] = i
				}

				const res = c.responsesByTeam[teamId]

				const challengePoints = res
					? calcPromptScoresPoints(res.promptScores)
					: 0

				i.score += challengePoints

				if (slideIndex === currentSlideIndex) {
					i.pointsLastRound = challengePoints
				}
			}
		})

		return Object.keys(byTeam)
			.map((teamId) => {
				const p = byTeam[teamId]
				return {
					teamId,
					...p,
				}
			})
			.sort((a, b) => b.score - a.score)
	}, [gameplayState])

	if (!info) return <ActivityIndicator />

	return (
		<table style={{ width: '100%', tableLayout: 'fixed', textAlign: 'left' }}>
			{/* <thead>
				<tr>
					<th>Team</th>
					<th></th>
					<th>Score</th>
				</tr>
			</thead> */}
			<tbody>
				{info.map((info, i) => (
					<tr key={i}>
						<td>
							{i + 1}. <TeamNameFormatter gameId={gameId} value={info.teamId} />
						</td>
						{/* <td>
							<ScoreFormatter value={info.score - info.pointsLastRound} />
						</td> */}
						{showLastRoundPoints && (
							<td>
								{info.pointsLastRound > 0 ? '+' : ''}
								<ScoreFormatter value={info.pointsLastRound} />
							</td>
						)}
						<td style={{ textAlign: 'right' }}>
							<strong>
								<ScoreFormatter value={info.score} />
							</strong>
						</td>
					</tr>
				))}
			</tbody>
		</table>
	)
}

export function calcPromptScoresPoints(
	promptScores: CompletedChallengeTeamInfo['promptScores']
) {
	return promptScores.reduce((total, s) => total + s.points, 0)
}
