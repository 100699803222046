import { useState, useEffect } from 'react'

export default function useCountdown(endTime: number) {
	const setDummy = useState(false)[1]

	const now = new Date().getTime()
	const diff = (endTime - now) / 1000 + 1

	useEffect(() => {
		if (diff < 0) return

		const h = setInterval(() => {
			setDummy((d) => !d)
		}, 40)

		return () => clearInterval(h)
	}, [diff, setDummy])

	return diff
}
