import {
	deserializeFBDate,
	serializeFBDate,
} from '../libraries/firebase-utils/FBDate'
import { FBResource } from '../libraries/firebase-utils/FBResource'
import {
	deserializeAny,
	serializeAny,
} from '../libraries/firebase-utils/serializing'

export interface TeamChallengeResponse {
	readonly gameId: string
	readonly slideIndex: number
	readonly teamId: string
	authorId: string
	createdOn: Date
	promptResponses: unknown[]
}

export const pendingChallengeResponseResource: FBResource<
	TeamChallengeResponse,
	{
		author: string
		timestamp: number
		responses: string
	},
	{
		gameId: string
		slideIndex: number
	}
> = {
	path: ({ gameId, slideIndex }) => {
		return `pendingChallengeResponses/${gameId}/${slideIndex}`
	},
	deserialize: (
		{ author, timestamp, responses },
		teamId,
		{ gameId, slideIndex }
	) => {
		return {
			teamId,
			gameId,
			slideIndex,
			authorId: author,
			createdOn: deserializeFBDate(timestamp),
			promptResponses: deserializeAny(responses),
		}
	},
	serialize: ({ authorId, createdOn, promptResponses }) => {
		return {
			author: authorId,
			// We do this because firebase doesn't save arrays with holes
			// well.
			responses: serializeAny(promptResponses),
			timestamp: serializeFBDate(createdOn),
		}
	},
}
