import React from 'react'
import Box from './Box'
import styled from './styled'
import theme from './theme'

const style = {
	margin: '0 auto',
	width: '100%',
	maxWidth: 700,
	marginBottom: theme.space[3],
	paddingLeft: theme.space[3],
	paddingRight: theme.space[3],
}

const Container = styled(({ css, children }) => (
	<Box css={[style, css]}>{children}</Box>
))

export default Container
