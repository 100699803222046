import React, { ReactNode, useCallback, useState, useContext } from 'react'
import LargeHeader from '../../components/LargeHeader'
import type { Game } from '../../entities/Game'
import {
	GameplayState,
	isGameplayStateInPlay,
} from '../../entities/GameplayState'
import GameplayMusic from '../../game/GameplayMusic'
import { GameDescription } from '../../gameplay/core/GameDescription'
import { GameSlideDescription } from '../../gameplay/core/GameSlideDescription'
import { GameQuery } from '../../gameplay/core/types'
import { getQueryHandler } from '../../gameplay/handlers'
import ActivityIndicator from '../../ui/ActivityIndicator'
import Alert from '../../ui/Alert'
import Box from '../../ui/Box'
import Button from '../../ui/Button'
import CardHeader from '../../ui/CardHeader'
import TeamsView from '../../views/TeamsView'
import getSlideIndexOfType from './getSlideIndexOfType'
import HostActiveChallengeView from './HostActiveChallengeView'
import HostChallengeEndedView from './HostChallengeEndedView'
import HostSlideView from './HostSlideView'
import { SlideControls } from './SlideControls'
import useHostActions from './useHostActions'
import Select from '../../ui/Select'
import { ActiveGameInfo } from '../../gameplay/ActiveGameInfo'
import ActiveGameInfoContext from '../../gameplay/ActiveGameInfoContext'

export default function HostGameScreen({
	game,
	description,
}: {
	game: Game
	description: GameDescription
}) {
	const gameId = game.id
	const [viewSlideIndex, setViewSlideIndex] = useState<number | undefined>()

	const slides = description.slides

	const info = useContext(ActiveGameInfoContext)!

	const {
		gameplayState,
		gameplaySlideDescription,
		gameplaySlideIndex,
		setGameplaySlideIndex,
		setAnsweringPaused,
		resetQuestion,
		endQuestion,
		resetGame,
		endGame,
	} = useHostActions(slides, gameId)

	const incrementCurrentView = useCallback(
		(amount: number) => {
			if (gameplaySlideIndex == null) return
			const nextIndex = gameplaySlideIndex + amount
			if (nextIndex < 0) return
			if (nextIndex > slides.length - 1) return
			setGameplaySlideIndex(nextIndex)
		},
		[gameplaySlideIndex, setGameplaySlideIndex, slides.length]
	)

	const onEndGame = useCallback(() => {
		if (window.confirm('Are you sure?')) {
			endGame()
		}
	}, [endGame])

	if (gameplayState === undefined) return <ActivityIndicator />

	function renderGameplayState(gameplayState: GameplayState) {
		if (isGameplayStateInPlay(gameplayState.status)) {
			let el: ReactNode

			if (gameplayState.currentView.type === 'challenge') {
				if (!gameplaySlideDescription) return <>No slide</>
				if (gameplaySlideDescription.type !== 'challenge') {
					return <>No challenge as expected</>
				}
				if (gameplayState.currentView.type !== 'challenge')
					return <>Not expected</>

				el = (
					<HostActiveChallengeView
						gameId={gameId}
						challengeDescription={gameplaySlideDescription.challengeDescription}
						challengeView={gameplayState.currentView}
						respondingDisabled={gameplayState.status === 'respondingInactive'}
						onRespondingDisabledChange={setAnsweringPaused}
						onEndQuestion={(responses, scoring) => {
							endQuestion(responses, scoring)
						}}
						onResetQuestion={resetQuestion}
					/>
				)
			} else {
				el = (
					<SlideControls
						currentView={gameplayState.currentView}
						onEndGame={onEndGame}
						onIncrement={incrementCurrentView}
						numSlides={slides.length}
					/>
				)
			}

			return (
				<>
					<HostSlideView
						slides={slides}
						view={gameplayState.currentView}
						slideIndex={gameplaySlideIndex!}
					/>
					{el}
				</>
			)
		} else if (gameplayState.status === 'questionEnded') {
			if (gameplayState.currentView.type === 'challenge') {
				if (gameplaySlideDescription?.type !== 'challenge') return <>Mismatch</>

				return (
					<HostChallengeEndedView
						challengeView={gameplayState.currentView}
						lastSlide={
							gameplaySlideIndex
								? gameplaySlideIndex >= slides.length - 1
								: false
						}
						numSlides={slides.length}
						onNext={(peek) => {
							const nextIndex =
								gameplaySlideIndex == null ? 0 : gameplaySlideIndex + 1

							if (peek) {
								setViewSlideIndex(nextIndex)
							} else {
								setGameplaySlideIndex(nextIndex)
								setViewSlideIndex(undefined)
							}
						}}
						onResetQuestion={resetQuestion}
						promptDescriptions={
							gameplaySlideDescription.challengeDescription.prompts
						}
						prompts={gameplayState.currentView.challenge.prompts}
						gameId={gameId}
					/>
				)
			} else {
				return <>Question ended but it's not a challenge</>
			}
		} else if (gameplayState.status === 'gameEnded') {
			return <>Game has ended</>
		}
	}

	return (
		<>
			<LargeHeader
				mb={4}
				title={`Game Code: ${game.id}`}
				subtitle={game.name}
			/>
			<Select
				mb={3}
				selectedState={[
					viewSlideIndex ?? gameplaySlideIndex ?? undefined,
					setViewSlideIndex,
				]}
				options={slides.map((slide, i) => {
					const label = renderViewLabel(slide, info)?.slice(0, 20)

					const realLabel = `${
						slide.type === 'challenge'
							? `Question ${getSlideIndexOfType(slides, slide.type, i) + 1}`
							: ''
					} ${label && slide.type === 'challenge' ? ' - ' : ''}${label ?? ''}`

					return {
						label: realLabel,
						value: i,
					}
				})}
			/>
			{gameplaySlideIndex === viewSlideIndex || viewSlideIndex == null ? (
				gameplayState ? (
					<Box mb={3}>{renderGameplayState(gameplayState)}</Box>
				) : (
					<Button tone="green" onClick={() => setGameplaySlideIndex(0)}>
						Start the game
					</Button>
				)
			) : (
				<>
					<Alert mb={2} onClick={() => setViewSlideIndex(undefined)}>
						This is not the current question. Press here to go back.
					</Alert>
					<Button
						mb={3}
						onClick={() => {
							if (
								gameplayState &&
								gameplayState.currentView.type === 'challenge' &&
								isGameplayStateInPlay(gameplayState.status)
							) {
								if (
									!window.confirm(
										"Are you sure? The current slide hasn't been scored yet."
									)
								) {
									return
								}
							}

							setGameplaySlideIndex(viewSlideIndex)
							setViewSlideIndex(undefined)
						}}
						tone="purple"
					>
						Present this slide to players
					</Button>

					<HostSlideView
						slides={slides}
						slideIndex={viewSlideIndex}
						// view={gameplayState?.currentView}
					/>
				</>
			)}
			<Box mb={3}>
				<CardHeader title="Teams" />
				<TeamsView gameId={gameId} />
			</Box>
			{gameplayState && (
				<>
					<GameplayMusic gameplayState={gameplayState} />
					<Button color="red" onClick={resetGame}>
						Reset Game
					</Button>
				</>
			)}
		</>
	)
}

function renderViewLabel(view: GameSlideDescription, info: ActiveGameInfo) {
	let query: GameQuery

	if (view.type === 'info') {
		query = view.query
	} else {
		query = view.challengeDescription.query
	}

	return getQueryHandler(query.type).getSummary?.(query.data, info)
}
