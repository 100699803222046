import { ChallengePromptHandler } from '../core/handlerTypes'

const voicePrompt: ChallengePromptHandler<undefined, undefined, string> = {
	type: 'voice',
	interactive: false,
	submitImmediately: false,
	getAssetUrls: () => [],
	renderInputView: ({ data, points }, responseLink, disabled) => {
		return null
	},
	renderUserResponse(response) {
		return null
	},
	renderCorrectAnswer(answer) {
		return answer
	},
}

export default voicePrompt
