import { FBResource } from '../libraries/firebase-utils/FBResource'

export interface Profile {
	readonly userId: string
	name: string
}

export const profileResource: FBResource<
	Profile,
	{
		name: string
	}
> = {
	path: 'profiles',
	deserialize({ name }, userId) {
		return {
			userId,
			name,
		}
	},
	serialize({ name }) {
		return {
			name,
		}
	},
}
