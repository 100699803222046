import firebase from 'firebase/app'
import React from 'react'
import { Game } from '../../entities/Game'
import { GameplayStateChallengeView } from '../../entities/GameplayState'
import { pendingChallengeResponseResource } from '../../entities/PendingChallengeResponse'
import { TBUser } from '../../entities/TBUser'
import { Team } from '../../entities/Team'
import { useEditableRecord } from '../../firebase/useRecord'
import playSound from '../../game/playSound'
import { getBuzzState, useBuzz } from '../../game/useBuzz'
import BuzzActionView from './BuzzActionView'
import PlayerChallengeView from './PlayerChallengeView'

const buzzInCountdown = 6 * 1000

export function ActiveChallengeView({
	game,
	user,
	team,
	view,
	respondingDisabled,
}: {
	view: GameplayStateChallengeView
	respondingDisabled: boolean
	game: Game
	user: TBUser
	team: Team
}) {
	const [response, setResponse] = useEditableRecord(
		[
			pendingChallengeResponseResource,
			{ gameId: game.id, slideIndex: view.slideIndex },
		],
		team.id
	)

	const buzzStartTime = view.startTime.getTime() + buzzInCountdown

	const submit = (responses: any[]) => {
		const allAnswered = view.challenge.prompts.every((prompt, i) => {
			return responses[i] !== undefined
		})

		if (!allAnswered) {
			if (
				!window.confirm(
					"You haven't answered all the questions. Are you sure you want to send it in?"
				)
			) {
				return
			}
		}

		playSound('warmChime')

		setResponse({
			authorId: user.id,
			createdOn: firebase.database.ServerValue.TIMESTAMP,
			promptResponses: responses,
		})
	}

	const buzzRequired = view.challenge.settings.buzzIn
	const { buzzedIn, buzzIn, didUserAlreadyBuzz } = useBuzz(
		game.id,
		view.slideIndex
	)
	const buzzState = buzzRequired
		? getBuzzState(buzzedIn, didUserAlreadyBuzz, user.id)
		: undefined

	// const s = useSpring({
	// 	opacity: 1,
	// 	transform: 'translateY(0)',
	// 	from: { opacity: 0, transform: 'translateY(-20px)' },
	// 	delay: 300 + 800 + 400,
	// 	config: { duration: 300 },
	// })

	// TODO: Only animate if the game started less than a few moments ago
	// to avoid animations when the page refreshes or a new user
	// enters a question in progress, or the host changing the question.
	return (
		<>
			<PlayerChallengeView
				challenge={view.challenge}
				hostId={game.creatorId}
				respondingDisabled={
					respondingDisabled ||
					(buzzState && buzzState.type !== 'yourBuzz') ||
					false
				}
				hidePrompts={!!buzzState}
				onSubmit={submit}
				challengeIndex={view.challengeIndex}
				submittedResponses={response && response.promptResponses}
			/>
			{buzzState && !respondingDisabled && (
				<BuzzActionView
					state={buzzState}
					startTime={buzzStartTime}
					onBuzzIn={() => buzzIn(user.id, team.id)}
				/>
			)}
		</>
	)
}
