import { useState, useRef, useCallback, useMemo, useEffect } from 'react'
import delay from './delayPromise'
import CancelToken from './CancelToken'

export type AsyncTaskStatus = 'pending' | 'completed' | 'error' | undefined

export type AsyncTask<T, E = any, P = any> =
	| {
			status: undefined
	  }
	| {
			status: 'pending'
			payload: P
	  }
	| {
			status: 'completed'
			payload: P
			result: T
	  }
	| {
			status: 'error'
			payload: P
			error: E
	  }

type AsyncTaskWrapper<T, E = any, P = any> = AsyncTask<T, E, P> & {
	start: (
		promise: Promise<T> | ((cancelToken: CancelToken) => Promise<T>),
		options?: AsyncTaskOptions<T, E, P>
	) => Promise<boolean>
	reset: () => void
}

interface AsyncTaskOptions<T, E = any, P = any> {
	onSuccess?: (value: T) => void
	successDelay?: number | true
	clearOnSuccess?: boolean
	onError?: (error: E) => void
	clearOnError?: boolean
	payload?: P
}

export default function useAsyncTask<
	T = any,
	E = any,
	P = void
>(): AsyncTaskWrapper<T, E, P> {
	const [status, setStatus] = useState<AsyncTaskStatus>()
	const [result, setResult] = useState<T | E>()
	const [payload, setPayload] = useState<P | undefined>(undefined)
	const runningTaskRef = useRef<{
		options: AsyncTaskOptions<T, E, P>
		cancelToken: CancelToken
	}>()

	const clear = useCallback(() => {
		setStatus(undefined)
		setResult(undefined)
		setPayload(undefined)
		if (runningTaskRef.current) {
			runningTaskRef.current.cancelToken.cancel()
			runningTaskRef.current = undefined
		}
	}, [])

	// Clear when the component unmounts
	useEffect(() => clear, [clear])

	const start = useCallback(
		async (
			promiseOrFn: Promise<T> | ((cancelToken: CancelToken) => Promise<T>),
			options: AsyncTaskOptions<T, E, P> = {}
		) => {
			clear()

			const cancelToken = new CancelToken()
			cancelToken.onCancel(() => {
				if (
					runningTaskRef.current &&
					runningTaskRef.current.cancelToken === cancelToken
				) {
					clear()
				}
			})
			let promise =
				typeof promiseOrFn === 'function'
					? promiseOrFn(cancelToken)
					: promiseOrFn

			setStatus('pending')
			setResult(undefined)
			setPayload(options.payload)
			runningTaskRef.current = { options, cancelToken }

			try {
				const data = await promise
				if (cancelToken.canceled) return false
				setResult(data)
				setStatus('completed')

				if (options.successDelay) {
					await delay(
						options.successDelay === true ? 650 : options.successDelay
					)
					if (cancelToken.canceled) return false
				}

				if (options.clearOnSuccess) {
					clear()
				}

				if (options.onSuccess) {
					options.onSuccess(data)
				}

				return true
			} catch (error) {
				setResult(error)
				setStatus('error')

				if (options.clearOnError) {
					clear()
				}

				if (options.onError) {
					options.onError(error)
				}

				return false
			}
		},
		[clear]
	)

	const task: AsyncTaskWrapper<T, E, P> = useMemo(() => {
		if (status === undefined) {
			return {
				start,
				status: undefined,
				reset: clear,
			}
		} else if (status === 'pending') {
			return {
				start,
				reset: clear,
				status: 'pending',
				payload: payload!,
			}
		} else if (status === 'completed') {
			return {
				start,
				reset: clear,
				status: 'completed',
				payload: payload!,
				result: result as T,
			}
		} else if (status === 'error') {
			return {
				start,
				reset: clear,
				status: 'error',
				payload: payload!,
				error: result as E,
			}
		} else {
			throw new Error('Unknown status ' + status)
		}
	}, [status, start, clear, payload, result])

	return task
}
