import { Avatar, Menu, MenuItem } from '@material-ui/core'
import React, { useCallback, useState, useEffect, useContext } from 'react'
import useSession from '../auth/useSession'
import Box from '../ui/Box'
import Container from '../ui/Container'
import { RevealMenu } from '../ui/RevealMenu'
import { GameState, getGameStateGame } from './GameState'
import LogoProxyView from './logo/LogoProxyView'
import { SongStatus } from '../libraries/sound-manager/SoundManager'
import SoundManagerContext from '../libraries/sound-manager/SoundManagerContext'
import SongAnalyzer from '../SongAnalyzer'

const eqUrl = require('./equalizer.gif')

interface Props {
	gameState: GameState
}

export default function MainMenu({ gameState }: Props) {
	const session = useSession()

	const shouldShowLogo = gameState.type !== 'initialLoad'

	//
	const manager = useContext(SoundManagerContext)
	const [songStatus, setSongStatus] = useState<SongStatus>()
	useEffect(() => {
		if (manager) {
			return manager.addSongStatusListener(setSongStatus)
		}
	}, [manager])
	//

	const currentGame = getGameStateGame(gameState)

	const shareCode = useCallback(() => {
		if (!currentGame) return
		// @ts-ignore
		if (typeof navigator !== 'undefined' && navigator.share) {
			// @ts-ignore
			navigator.share({
				title: currentGame.name,
				url: 'https://triviabang.com/' + currentGame.id,
			})
		}
	}, [currentGame])

	const leaveGame =
		(gameState.type === 'playing' && gameState.leaveGame) || undefined

	return (
		<Container>
			<Box
				pt={4}
				// pb={2}
				css={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<div>
					{shouldShowLogo && <LogoProxyView scale={0.3} name="mainMenu" />}
				</div>
				{/* {currentGame && (
					<Box onClick={shareCode} css={{ fontWeight: 600, opacity: 0.5 }}>
						Code: {currentGame.id}
					</Box>
				)} */}
				<Box css={{ fontWeight: 600, opacity: 0.5 }}>
					{songStatus === 'loading' ? (
						'Loading music...'
					) : songStatus === 'locked' ? (
						'Tap to start music'
					) : songStatus === 'playing' ? (
						<>
							<img alt="" src={eqUrl} />
							{/* <SongAnalyzer /> */}
						</>
					) : null}
				</Box>
				<RevealMenu
					renderMenu={(props, close) =>
						session.loggedIn && (
							<Menu {...props}>
								{session.user.profile.name} ·{' '}
								{session.user.guest ? '(guest)' : ''}
								{leaveGame && (
									<MenuItem
										onClick={() => {
											leaveGame()
											close()
										}}
									>
										Leave game
									</MenuItem>
								)}
								<MenuItem
									onClick={() => {
										if (!session.loggedIn) return
										// TODO: It's pretty important that when
										// a user logs out we remove them from teams they're in.
										leaveGame?.()
										session.logOut()
										close()
									}}
								>
									Log out
								</MenuItem>
							</Menu>
						)
					}
				>
					<Avatar
						style={!session.loggedIn ? { visibility: 'hidden' } : undefined}
					>
						{session.loggedIn && session.user.displayName?.[0].toUpperCase()}
					</Avatar>
				</RevealMenu>
			</Box>
		</Container>
	)
}
