import { FunctionComponent, PropsWithChildren, createElement } from 'react'
import theme, { ThemeSpace } from './theme'
import { InterpolationWithTheme } from '@emotion/core'

interface OuterProps {
	css?: InterpolationWithTheme<any>
	mt?: ThemeSpace
	mr?: ThemeSpace
	mb?: ThemeSpace
	ml?: ThemeSpace
	p?: ThemeSpace
	pt?: ThemeSpace
	pb?: ThemeSpace
}

interface InnerProps {
	css: any
}

export default function styled<P extends {} = {}>(
	component: FunctionComponent<PropsWithChildren<P> & InnerProps>
) {
	const Comp: FunctionComponent<PropsWithChildren<P> & OuterProps> = ({
		css,
		mt,
		mr,
		mb,
		ml,
		p,
		pt,
		pb,
		...props
	}) => {
		// @ts-ignore
		return createElement(component, {
			...props,
			css: [
				{
					marginTop: mt && theme.space[mt],
					marginRight: mr && theme.space[mr],
					marginBottom: mb && theme.space[mb],
					marginLeft: ml && theme.space[ml],
					padding: p && theme.space[p],
					paddingTop: pt && theme.space[pt],
					paddingBottom: pb && theme.space[pb],
				},
				css,
			],
		})
	}

	return Comp
}
