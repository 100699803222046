import React, { useEffect } from 'react'
import LargeHeader from '../../components/LargeHeader'
import playSound from '../../game/playSound'
import Box from '../../ui/Box'
import sharedStyles from '../../ui/sharedStyles'
import theme from '../../ui/theme'
import useCountdown from '../../utils/useCountdown'

export function IntroView({ endTime }: { endTime: number }) {
	useEffect(() => {
		playSound('warmChime')
	}, [])

	// We add one so it doesn't reach 0
	const seconds = useCountdown(endTime) + 1

	return (
		<LargeHeader
			title="The game is starting"
			subtitle={
				<Box
					css={{
						marginTop: theme.space[5],
						width: 200,
						height: 200,
						display: 'inline-flex',
						alignItems: 'center',
						justifyContent: 'center',
						color: sharedStyles.colors.grayDark,
						border: '3px solid',
						fontSize: 50,
						borderRadius: '50%',
						fontWeight: 800,
					}}
				>
					<div>{seconds.toFixed(2)}</div>
				</Box>
			}
		/>
	)
}
