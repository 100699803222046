// @ts-ignore
import BlockContent from '@sanity/block-content-to-react'
import React from 'react'
import { RichText } from '../ui/RichText'

export default function ({ value }: { value?: RichText }) {
	if (value == null) return null
	if (typeof value === 'string') return <>{value}</>
	return <BlockContent blocks={value} />
}
