import styled from '../ui/styled'
import { ReactNode } from 'react'
import Card from '../ui/Card'
import CardHeader from '../ui/CardHeader'
import React from 'react'
import CardContent from '../ui/CardContent'

interface Props {
	title?: ReactNode
	media?: ReactNode
}

const MessagePane = styled<Props>(({ css, children, title, media }) => {
	return (
		<Card
			css={[
				css,
				{
					background: 'rgba(255,255,255,0.8)',
					boxShadow:
						'0 5px 50px 0 rgba(0,0,0,0.09), 0 3px 2px 0 rgba(0,0,0,0.1)',
					overflow: 'hidden',
					// backdropFilter: 'blur(10px)',
				},
			]}
		>
			{title && <CardHeader css={{ paddingBottom: 0 }} title={title} />}
			{children && (
				<CardContent
					css={{
						fontWeight: 500,
						fontSize: 20,
					}}
				>
					{children}
				</CardContent>
			)}
			{media}
		</Card>
	)
})

export default MessagePane
