import styled from './styled'
import Box from './Box'
import React, { ComponentProps } from 'react'
import sharedStyles from './sharedStyles'
import playSound from '../game/playSound'

interface Props extends ComponentProps<typeof Box> {
	color?: keyof typeof sharedStyles.colors
}

const IconButton = styled<Props>(
	({ color = 'grayDark', children, css, ...props }) => {
		return (
			<Box
				onMouseDown={() => playSound('shortPop')}
				css={[
					{
						display: 'inline-flex',
						alignItems: 'center',
						justifyContent: 'center',
						position: 'relative',
						cursor: 'pointer',
						':before': {
							content: '""',
							position: 'absolute',
							top: -20,
							left: -20,
							right: -20,
							bottom: -20,
						},
					},
					{
						color: sharedStyles.colors[color],
					},
					css,
				]}
				{...props}
			>
				{children}
			</Box>
		)
	}
)

export default IconButton
