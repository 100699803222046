import React from 'react'
import Box from '../../ui/Box'
import stringifyRichText, { RichText } from '../../ui/RichText'
import sharedStyles from '../../ui/sharedStyles'
import RichTextView from '../../views/RichTextView'
import { GameQueryHandler } from '../core/handlerTypes'
import { LeaderBoard } from '../../screens/play/LeaderBoard'
import Card from '../../ui/Card'
import CardContent from '../../ui/CardContent'

const messageQuery: GameQueryHandler<{
	title?: string
	subtitle?: string
	body?: RichText
	showLeaderboard?: boolean
}> = {
	type: 'message',
	getAssetUrls: () => [],
	render: ({ title, subtitle, body, showLeaderboard }, { game }) => {
		return (
			<Box
				css={{
					textAlign: 'center',
					maxWidth: 500,
					margin: '50px auto 0',
					padding: '20px 0',
				}}
			>
				{title && (
					<Box
						css={{
							fontSize: 40,
							fontWeight: 800,
							color: sharedStyles.colors.grayDark,
						}}
						mb={2}
					>
						{title}
					</Box>
				)}
				{subtitle && (
					<Box
						css={{
							fontSize: 30,
							fontWeight: 600,
							color: sharedStyles.colors.purple,
						}}
						mb={4}
					>
						{subtitle}
					</Box>
				)}
				{body && (
					<Box
						css={{
							fontSize: 20,
							lineHeight: 1.6,
							fontWeight: 500,
							color: sharedStyles.colors.grayDark,
						}}
					>
						<RichTextView value={body} />
					</Box>
				)}
				{showLeaderboard && (
					<Card mt={4}>
						<CardContent>
							<LeaderBoard gameId={game.id} />
						</CardContent>
					</Card>
				)}
			</Box>
		)
	},
	getSummary({ title, subtitle, body }) {
		return [title, subtitle, body]
			.map(stringifyRichText)
			.filter((x) => !!x)
			.join(' · ')
	},
}

export default messageQuery
