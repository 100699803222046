import { GameDescription } from '../../gameplay/core/GameDescription'
import {
	GameSlideDescription,
	SlideMusicDescription,
} from '../../gameplay/core/GameSlideDescription'
import {
	ChallengePromptDescription,
	ChallengeSettings,
} from '../../gameplay/core/types'
import createPromptDescription from '../../gameplay/helpers/createChallengePrompt'
import createGameQuery from '../../gameplay/helpers/createGameQuery'
import inputPrompt from '../../gameplay/promptHandlers/inputPrompt'
import multipleChoicePrompt from '../../gameplay/promptHandlers/multipleChoicePrompt'
import voicePrompt from '../../gameplay/promptHandlers/voicePrompt'
import messageQuery from '../../gameplay/queryHandlers/messageQuery'
import simpleQuery from '../../gameplay/queryHandlers/simpleQuery'
import { RichText } from '../../ui/RichText'
import { getRemoteAssetUrl } from './getRemoteAssetUrl'
import {
	RemoteCommonInput,
	RemoteGameDescription,
	RemoteImageRef,
	RemoteQuestionBase,
	WithOptionalBonus,
} from './remoteTypes'

const defaultPromptPoints = 1
const defaultBonusPoints = 1

export default function parseRemoteGameDescription(
	remote: RemoteGameDescription
): GameDescription {
	const slides: GameSlideDescription[] = []
	remote.rounds.forEach((round, i) => {
		slides.push(
			createRoundIntroSlide(
				'Round ' + (i + 1),
				round.title,
				round.description,
				getRemoteAssetUrl(round.music) ??
					(round.playDefaultMusic ? '/assets/music/casual.mp3' : undefined)
			)
		)

		const roundPointsMult = round.pointsMultiplier ?? 1

		round.questions.forEach((q) => {
			const slide = parseQuestion(q, roundPointsMult)
			if (slide) {
				slides.push(slide)
			}
		})

		if (i < remote.rounds.length - 1) {
			slides.push({
				type: 'info',
				query: createGameQuery(messageQuery, {
					title: "That's the end of round " + (i + 1),
					showLeaderboard: true,
				}),
			})
		}
	})

	return {
		slides,
	}
}

function createCommonChallenge(
	q: RemoteQuestionBase,
	prompts: (ChallengePromptDescription | undefined | false | '')[],
	settings?: ChallengeSettings
): GameSlideDescription {
	return {
		type: 'challenge',
		music: createCommonMusic(q),
		challengeDescription: {
			query: createCommonQuery(q),
			prompts: prompts.filter((p) => !!p) as ChallengePromptDescription[],
			settings,
		},
	}
}

function createCommonInputPrompt({
	answer,
	possibleAnswers,
	title,
	points,
}: RemoteCommonInput) {
	const a = createAnswersArray(answer, possibleAnswers)
	return createPromptDescription(
		inputPrompt,
		{
			title: title ?? 'Answer',
		},
		a,
		points ?? defaultPromptPoints
	)
}

function createCommonBonusPrompt({ bonus }: WithOptionalBonus) {
	if (!bonus) return undefined

	const { question, points, ...answers } = bonus

	return createCommonInputPrompt({
		...answers,
		title: question ?? 'Bonus',
		points: points ?? defaultBonusPoints,
	})
}

function parseQuestion(
	q: RemoteGameDescription['rounds'][number]['questions'][number],
	pointsMultipler: number
): GameSlideDescription | undefined {
	if (q._type === 'song-question') {
		return createCommonChallenge(q, [
			createCommonInputPrompt({
				...q,
				points: 2 * pointsMultipler,
			}),
			createSimpleInputPrompt('Song title', q.songName, 1 * pointsMultipler),
			createSimpleInputPrompt('Song artist', q.artist, 1 * pointsMultipler),
			createCommonBonusPrompt(q),
		])
	} else if (q._type === 'character-question') {
		return createCommonChallenge(q, [
			createSimpleInputPrompt(
				"Character's name",
				q.characterName,
				2 * pointsMultipler
			),
			createSimpleInputPrompt('Movie or show', q.showName, 1 * pointsMultipler),
			q.actor && createSimpleInputPrompt('Actor', q.actor, 1 * pointsMultipler),
			createCommonBonusPrompt(q),
		])
	} else if (q._type === 'multiple-choice-question') {
		return createCommonChallenge(q, [
			createPromptDescription(
				multipleChoicePrompt,
				[q.answer, ...q.wrongAnswers],
				0,
				defaultPromptPoints * pointsMultipler
			),
		])
	} else if (q._type === 'buzz') {
		return createCommonChallenge(
			q,
			[
				createPromptDescription(
					voicePrompt,
					undefined,
					q.answer,
					defaultPromptPoints * pointsMultipler
				),
			],
			{
				buzzIn: true,
				penalizeWrongAnswers: true,
			}
		)
	} else if (q._type === 'custom-input-question') {
		return createCommonChallenge(
			q,
			q.prompts.map((p) =>
				createCommonInputPrompt({
					...p,
					points: (p.points ?? defaultPromptPoints) * pointsMultipler,
				})
			)
		)
	} else {
		if (process.env.NODE_ENV !== 'production') {
			console.log('Unknown question type', q)
		}
	}
}

// Low level

function createAnswersArray(
	answer: string,
	possibleAnswers: string[] | undefined
) {
	if (!possibleAnswers) return [answer]
	return [answer, ...possibleAnswers]
}

function createSimpleInputPrompt(
	title: string,
	answer: string,
	points?: number
) {
	return createPromptDescription(
		inputPrompt,
		{
			title,
		},
		[answer],
		points ?? defaultPromptPoints
	)
}

// Helpers

function createRoundIntroSlide(
	title: string,
	subtitle?: string,
	body?: RichText,
	song?: string
): GameSlideDescription {
	return {
		type: 'info',
		query: createGameQuery(messageQuery, {
			title,
			subtitle,
			body,
		}),
		music: song
			? {
					url: song,
					loop: true,
					fill: true,
			  }
			: undefined,
	}
}

function createCommonMusic({
	music,
	loopMusic,
}: Pick<RemoteQuestionBase, 'music' | 'loopMusic'>):
	| SlideMusicDescription
	| undefined {
	return music
		? {
				url: getRemoteAssetUrl(music)!,
				loop: loopMusic ?? false,
				fill: false,
		  }
		: undefined
}

function createCommonQuery({
	question,
	image,
}: {
	question?: RichText
	image?: RemoteImageRef
	bonus?: WithOptionalBonus['bonus']
}) {
	// TODO: Say there's a bonus.
	return createGameQuery(simpleQuery, {
		text: question,
		media: getRemoteAssetUrl(image),
	})
}
