import React, { useEffect } from 'react'
import { animated, useSpring } from 'react-spring'
import LargeHeader from '../../components/LargeHeader'
import { GameChallenge } from '../../gameplay/core/types'
import ChallengeView from '../../views/ChallengeView'
import playSound from '../../game/playSound'

interface Props {
	hostId: string
	respondingDisabled?: boolean
	challenge: GameChallenge
	hidePrompts?: boolean
	onSubmit?(responses: any[]): void
	challengeIndex: number
	submittedResponses?: any[] | undefined | null
}

export default function PlayerChallengeView({
	hostId,
	respondingDisabled,
	challenge,
	hidePrompts,
	onSubmit,
	challengeIndex,
	submittedResponses,
}: Props) {
	useEffect(() => {
		playSound('ding')
	}, [])

	return (
		<>
			<animated.div
				style={useSpring({
					opacity: 1,
					transform: 'translateY(0)',
					from: { opacity: 0, transform: 'translateY(-20px)' },
					config: { duration: 300 },
				})}
			>
				<LargeHeader mb={3} title={<>Question #{challengeIndex + 1}</>} />
			</animated.div>
			<animated.div
				style={useSpring({
					opacity: 1,
					transform: 'translateY(0)',
					from: { opacity: 0, transform: 'translateY(-20px)' },
					delay: 300 + 800,
					config: { duration: 300 },
				})}
			>
				<ChallengeView
					respondingDisabled={respondingDisabled}
					hostId={hostId}
					submittedResponses={submittedResponses}
					challenge={challenge}
					hidePrompts={hidePrompts}
					onSubmit={onSubmit}
				/>
				{/* Hack to offset for buzz */}
				<div style={{ height: 200 }} />
			</animated.div>
		</>
	)
}
