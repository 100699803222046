import React from 'react'
import { GameplayStateView } from '../../entities/GameplayState'
import { GameSlideDescription } from '../../gameplay/core/GameSlideDescription'
import Card from '../../ui/Card'
import CardContent from '../../ui/CardContent'
import QueryView from '../../views/QueryView'
import HostAnswersView from './HostAnswersView'

interface Props {
	slides: GameSlideDescription[]
	slideIndex: number
	view?: GameplayStateView
}

export default function HostSlideView({ slides, slideIndex, view }: Props) {
	const slide = slides[slideIndex]
	// const index = getSlideIndexOfType(slides, slide.type, slideIndex)

	if (slide.type === 'challenge' && (!view || view.type === 'challenge')) {
		return (
			<>
				<QueryView query={slide.challengeDescription.query} />
				<Card mb={2}>
					<CardContent>
						<HostAnswersView
							promptDescriptions={slide.challengeDescription.prompts}
							prompts={view?.challenge.prompts}
						/>
					</CardContent>
				</Card>
			</>
		)
	} else if (slide.type === 'info') {
		return (
			<>
				<QueryView query={slide.query} />
			</>
		)
	}
	return null
}
