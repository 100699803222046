import { sort } from 'ramda'
import React, { ReactNode } from 'react'
import { FaCheck } from 'react-icons/fa'
import { CompletedChallengeInfo } from '../../entities/GameplayState'
import { Team } from '../../entities/Team'
import ScoreFormatter from '../../game/ScoreFormatter'
import Box from '../../ui/Box'
import Card from '../../ui/Card'
import CardContent from '../../ui/CardContent'
import CardHeader from '../../ui/CardHeader'
import sharedStyles from '../../ui/sharedStyles'
import { calcPromptScoresPoints } from './LeaderBoard'

interface Props {
	teams: Team[]
	info: CompletedChallengeInfo
	activeTeamId?: string
}

export default function ChallengeTeamAnswersView({
	teams,
	info,
	activeTeamId,
}: Props) {
	if (activeTeamId) {
		teams = sort((a, b) => {
			if (a.id === activeTeamId) return -1
			if (b.id === activeTeamId) return 1
			return 0
		}, teams)
	}

	return (
		<>
			{teams.map((team) => {
				const responses = info.responsesByTeam[team.id]

				const totalPoints = responses
					? calcPromptScoresPoints(responses.promptScores)
					: 0

				// We only want to say it was a perfect score
				// if there was more than one prompt.
				const perfectScore =
					responses &&
					responses.promptScores.length > 1 &&
					responses.promptScores.every((r) => r.correct)

				const responsesList =
					responses?.challengeResponse?.promptResponses.map((response, i) => {
						const score = responses.promptScores[i]

						return (
							<Box
								key={i}
								mb={2}
								css={{
									display: 'flex',
									justifyContent: 'space-between',
								}}
							>
								<Box>
									<strong>{i + 1}. </strong>
									{response ? <>{response} </> : <Muted>No response</Muted>}
									{score.correct && (
										<>
											&nbsp;
											<FaCheck color={sharedStyles.colors.green} />
										</>
									)}
								</Box>
								<Box>
									<Muted>{formatPointsDelta(score.points)}</Muted>
								</Box>
							</Box>
						)
					}) ?? []

				return (
					<Card
						mb={2}
						key={team.id}
						css={
							team.id === activeTeamId
								? { border: '3px solid ' + sharedStyles.colors.blue }
								: undefined
						}
					>
						<CardHeader
							title={
								<>
									{team.name}
									{perfectScore ? (
										<> - Perfect score 🎉</>
									) : (
										totalPoints > 0 &&
										responsesList.length <= 1 && (
											<>
												&nbsp;&nbsp;
												<FaCheck color={sharedStyles.colors.green} />
											</>
										)
									)}
								</>
							}
							css={{
								borderBottom: '1px solid ' + sharedStyles.colors.grayLight,
							}}
							accessory={
								<Box css={{ fontSize: 22, fontWeight: 'bold' }}>
									{formatPointsDelta(totalPoints)}
								</Box>
							}
						/>
						{info.challengeDescription.prompts.length > 1 &&
							(responsesList.length > 0 ? (
								<CardContent>{responsesList}</CardContent>
							) : (
								<CardContent>
									<Muted>No responses</Muted>
								</CardContent>
							))}
					</Card>
				)
			})}
		</>
	)
}

function formatPointsDelta(points: number) {
	return points >= 0 ? (
		<>
			+<ScoreFormatter context="n-points" value={points} />
		</>
	) : (
		<ScoreFormatter context="n-points" value={points} />
	)
}

function Muted({ children }: { children?: ReactNode }) {
	return <span style={{ opacity: 0.4 }}>{children}</span>
}
