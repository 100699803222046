/** @jsx jsx */

import { jsx } from '@emotion/core'
import { ReactNode, ComponentProps, useCallback } from 'react'
import sharedStyles from './sharedStyles'
import styled from './styled'
import theme from './theme'
import playSound from '../game/playSound'

const tones = {
	blue: {
		backgroundColor: 'blue',
		color: 'white',
	},
	pink: {
		backgroundColor: 'pink',
		color: 'white',
	},
	green: {
		backgroundColor: 'green',
		color: 'white',
	},
	red: {
		backgroundColor: 'red',
		color: 'white',
	},
	purple: {
		backgroundColor: 'purple',
		color: 'white',
	},
	grayLight: {
		backgroundColor: 'grayLight',
		color: 'grayDark',
	},
} as const

interface Props extends ComponentProps<'button'> {
	children: ReactNode
	active?: boolean
	tone?: keyof typeof tones
	flat?: boolean
}

const Button = styled<Props>(
	({
		children,
		disabled,
		active,
		tone: toneName = 'grayLight',
		css,
		flat,
		onMouseDown,
		...props
	}) => {
		const tone = tones[toneName]

		const handleMouseDown = useCallback(
			(e: any) => {
				playSound('shortPop')
				onMouseDown?.(e)
			},
			[onMouseDown]
		)

		return (
			<button
				css={[
					{
						border: 0,
						fontSize: 'inherit',
						fontFamily: 'inherit',
						display: 'block',
						width: '100%',
						outline: 'none',
						WebkitTapHighlightColor: 'transparent',
					},
					sharedStyles.boxBase,
					sharedStyles.controlBase,
					{
						padding: theme.space[sharedStyles.padding],
					},
					!flat && {
						boxShadow: sharedStyles.boxShadow,
					},
					{
						color: sharedStyles.colors[tone.color],
					},
					!disabled && {
						cursor: 'pointer',
					},
					!disabled
						? [
								!active
									? {
											backgroundColor:
												sharedStyles.colors[tone.backgroundColor],
											':hover': {
												backgroundColor: sharedStyles.darkenColor(
													tone.backgroundColor,
													0.5
												),
											},
											':active': {
												backgroundColor: sharedStyles.darkenColor(
													tone.backgroundColor,
													1
												),
											},
									  }
									: {
											backgroundColor: sharedStyles.darkenColor(
												tone.backgroundColor,
												1
											),
											':hover': {
												backgroundColor: sharedStyles.darkenColor(
													tone.backgroundColor,
													0.5
												),
											},
											':active': {
												backgroundColor: sharedStyles.darkenColor(
													tone.backgroundColor,
													2
												),
											},
									  },
								sharedStyles.buttonAnimationStyle,
						  ]
						: {
								backgroundColor: active ? '#666666' : '#aaaaaa',
						  },
					css,
				]}
				disabled={disabled}
				onMouseDown={handleMouseDown}
				{...props}
			>
				{children}
			</button>
		)
	}
)

export default Button
