import React from 'react'
import { GameplayState, isGameplayStateInPlay } from '../entities/GameplayState'
import Song from '../libraries/sound-manager/Song'

export default function GameplayMusic({
	gameplayState,
}: {
	gameplayState: GameplayState
}) {
	const {
		currentView: { music },
		status,
	} = gameplayState

	if (!music) return null
	if (!music.fill && !isGameplayStateInPlay(status)) return null

	const startTime =
		gameplayState.musicStartTime ?? gameplayState.currentView.startTime

	return (
		<Song
			key={music.key}
			source={music.url}
			startTime={startTime.getTime() + music.startOffset}
			loop={music.loop}
		/>
	)
}
