import SoundManager from './SoundManager'
import { useState, ReactNode } from 'react'
import React from 'react'
import SoundManagerContext from './SoundManagerContext'

export default function SoundManagerProvider({
	children,
}: {
	children: ReactNode
}) {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [manager, setManager] = useState(() => new SoundManager())

	return (
		<SoundManagerContext.Provider value={manager}>
			{children}
		</SoundManagerContext.Provider>
	)
}
