import {
	FunctionComponent,
	useEffect,
	useContext,
	useMemo,
	useState,
	useRef,
} from 'react'
import React from 'react'
import { LogoContext, logoNaturalSize, LogoProxy } from './LogoProvider'
import useElementRef from '../../utils/useElementRef'

interface Props {
	scale?: number
	name: string
}

function useLogoProxy(name: string) {
	const registerProxy = useContext(LogoContext)
	const [proxy, setProxy] = useState<LogoProxy>()
	const nameRef = useRef(name)

	useEffect(() => {
		if (!registerProxy) return

		const proxy = registerProxy(nameRef.current)
		setProxy(proxy)

		return () => {
			proxy.deregister()
			setProxy(undefined)
		}
	}, [registerProxy])

	return proxy
}

const LogoProxyView: FunctionComponent<Props> = ({ scale = 1, name }) => {
	const [el, ref] = useElementRef<HTMLDivElement>()
	const proxy = useLogoProxy(name)

	useEffect(() => {
		if (el && proxy) {
			const fn = () => {
				const rect = el.getBoundingClientRect()
				proxy.setTransform({
					x: rect.left,
					y: rect.top,
					scale,
				})
			}
			const h = setInterval(fn, 100)
			fn()

			return () => clearInterval(h)
		}
	}, [el, proxy, scale])

	const sizeStyle = useMemo(() => {
		if (!proxy) return
		return {
			width: logoNaturalSize.width * scale,
			height: logoNaturalSize.height * scale,
		}
	}, [proxy, scale])

	return <div style={{ display: 'inline-block', ...sizeStyle }} ref={ref} />
}

export default LogoProxyView
