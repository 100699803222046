import styled from './styled'
import Box from './Box'
import React, { ReactNode } from 'react'

interface Props {
	title?: ReactNode
	subtitle?: ReactNode
	accessory?: ReactNode
}

const Item = styled<Props>(({ title, subtitle, accessory, css }) => {
	return (
		<Box css={[{ display: 'flex', alignItems: 'center' }, css]}>
			<Box css={{ flexGrow: 1 }}>
				{title && <Box css={{ fontWeight: 'bold' }}>{title}</Box>}
				{subtitle && <Box>{subtitle}</Box>}
			</Box>
			{accessory && <Box>{accessory}</Box>}
		</Box>
	)
})

export default Item
