import {
	deserializeRecord,
	normalizeResourceAndOptions,
	serializeRecord,
} from './FBResource'
import getRecord from './getRecord'
import { FBId, FBResourceAndOptions, FBValue, NewRecord } from './types'

export type SetRecordAction<T> =
	| NewRecord<T>
	| undefined
	| ((prevValue: T | undefined) => NewRecord<T> | undefined)

async function setRecord<T, V extends FBValue, O>(
	db: firebase.database.Database,
	r: FBResourceAndOptions<T, V, O>,
	id: FBId,
	action: NewRecord<T> | ((prevValue: T | undefined) => NewRecord<T>)
): Promise<T>
async function setRecord<T, V extends FBValue, O>(
	db: firebase.database.Database,
	r: FBResourceAndOptions<T, V, O>,
	id: FBId,
	action: SetRecordAction<T>
): Promise<T | undefined>
async function setRecord<T, V extends FBValue, O>(
	db: firebase.database.Database,
	r: FBResourceAndOptions<T, V, O>,
	id: FBId,
	action: SetRecordAction<T>
) {
	const [resource, path, options] = normalizeResourceAndOptions(r)

	const ref = db.ref(path + '/' + id)

	if (action instanceof Function) {
		const {
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			committed,
			snapshot,
		} = await ref.transaction((prevValue) => {
			const prevRecord = prevValue
				? deserializeRecord(resource, id, prevValue, options)
				: undefined
			const newValue = action(prevRecord)
			return serializeRecord(resource, newValue)
		})

		return deserializeRecord(resource, id, snapshot.val(), options)
	} else {
		// This doesn't return anything
		await ref.set(action ? serializeRecord(resource, action) : null)

		// For consistency (and it's also useful) we return the created resource.
		return await getRecord(db, r, id)
	}
}

export default setRecord
