import React, {
	createContext,
	useMemo,
	FunctionComponent,
	useContext,
} from 'react'

interface Props {
	value: number
	context?: 'n' | 'n-points'
}

export default function ScoreFormatter({ value, context = 'n' }: Props) {
	// Later we can use context if needed.

	const { multiplier = 1, currencyCode } = useContext(ScoreFormattingContext)

	const format = useMemo(() => {
		if (typeof Intl === 'undefined') return (number: number) => number + ''

		const formatter = new Intl.NumberFormat('en-US')

		return (number: number) => formatter.format(number)
	}, [])

	const negative = value < 0

	let formatted = format(Math.abs(value) * multiplier)

	if (!currencyCode && context === 'n-points') {
		formatted += ' points'
	}

	return (
		<>
			{negative ? '-' : ''}
			{currencyCode}
			{formatted}
		</>
	)
}

interface ScoreFormatting {
	multiplier?: number
	currencyCode?: string
}

const ScoreFormattingContext = createContext<ScoreFormatting>({})

export const ScoreFormattingProvider: FunctionComponent<ScoreFormatting> = ({
	multiplier,
	children,
}) => {
	const context = useMemo<ScoreFormatting>(
		() => ({
			multiplier,
		}),
		[multiplier]
	)

	return (
		<ScoreFormattingContext.Provider value={context}>
			{children}
		</ScoreFormattingContext.Provider>
	)
}
