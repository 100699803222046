import React, { ReactElement } from 'react'
import { TeamChallengeResponse } from '../../entities/PendingChallengeResponse'
import { Team } from '../../entities/Team'
import { ChallengePrompt } from '../../gameplay/core/types'
import { TeamPromptResponseView } from './TeamPromptResponseView'
export function TeamResponsesView({
	pendingResponses,
	onRemoveResponse,
	onScoreAnswer,
	isAnswerCorrect,
	challengePropmpts,
	teams,
	gameId,
}: {
	gameId: string
	pendingResponses: TeamChallengeResponse[] | undefined
	challengePropmpts: ChallengePrompt[]
	onRemoveResponse(teamId: string): void
	onScoreAnswer(teamId: string, index: number, correct: boolean): void
	isAnswerCorrect(teamId: string, promptIndex: number): boolean | undefined
	teams: Team[]
}): ReactElement {
	return (teams.map((team, i) => {
		const pendingResponse = pendingResponses?.find((p) => p.teamId === team.id)
		return (
			<TeamPromptResponseView
				key={team.id}
				teamId={team.id}
				gameId={gameId}
				response={pendingResponse}
				onRemoveResponse={onRemoveResponse}
				prompts={challengePropmpts}
				isAnswerCorrect={isAnswerCorrect}
				onScoreAnswer={onScoreAnswer}
			/>
		)
	}) as unknown) as ReactElement
}
