import { FunctionComponent, ReactNode } from 'react'
import { Team } from '../entities/Team'
import React from 'react'
import Item from '../ui/Item'
import UserNameFormatter from '../formatters/UserNameFormatter'
import joinElements from '../utils/joinElements'
import { BsPersonFill } from 'react-icons/bs'
import Box from '../ui/Box'

const TeamItem: FunctionComponent<{
	team: Team
	accessory?: ReactNode
	emptySubtitle?: string
}> = ({ team, accessory, emptySubtitle = 'No members' }) => {
	return (
		<Item
			title={team.name}
			accessory={accessory}
			subtitle={
				<>
					<Box
						component="span"
						css={{ verticalAlign: 'middle', opacity: 0.5 }}
						mr={1}
					>
						<BsPersonFill />
					</Box>
					{team.memberIds.length > 0
						? joinElements(
								team.memberIds.map((id) => (
									<UserNameFormatter key={id} value={id} />
								)),
								<>,&nbsp;</>
						  )
						: emptySubtitle}
				</>
			}
		/>
	)
}

export default TeamItem
