import firebase from 'firebase/app'

export function deserializeFBDate(time: number) {
	return new Date(time)
}

export function serializeFBDate(
	date: Date | typeof firebase.database.ServerValue.TIMESTAMP
) {
	if (date instanceof Date) return date.getTime()
	return date
}
