import React, { ReactNode } from 'react'
import { BuzzState } from '../../game/useBuzz'
import sharedStyles from '../../ui/sharedStyles'
import Box from '../../ui/Box'
import UserNameFormatter from '../../formatters/UserNameFormatter'
import theme from '../../ui/theme'
import useWatchValue from '../../utils/useWatchValue'
import playSound from '../../game/playSound'
import { useBGColor } from '../../views/FancyBackground'
import useCountdown from '../../utils/useCountdown'

export default function BuzzActionView({
	state,
	onBuzzIn,
	startTime,
}: {
	state: BuzzState
	onBuzzIn: () => void
	startTime: number
}) {
	let color: string
	let label: ReactNode
	let textColor = sharedStyles.colors.white
	let interactive = false
	let affectBackground = true

	const timeToOpen = useCountdown(startTime)

	if (state.type === 'canBuzz') {
		if (timeToOpen <= 0) {
			color = sharedStyles.colors.hotPink
			label = 'Buzz in'
			interactive = true
		} else {
			color = sharedStyles.colors.grayDark
			// textColor = sharedStyles.colors.grayDark
			label = 'You can buzz in ' + timeToOpen.toFixed(2)
		}
	} else if (state.type === 'yourBuzz') {
		label = 'Answer now'
		color = sharedStyles.colors.blue
	} else if (state.type === 'otherBuzzed') {
		label = (
			<>
				<UserNameFormatter value={state.userId} /> buzzed in
			</>
		)
		color = sharedStyles.colors.grayDark
	} else if (state.type === 'alreadyBuzzed') {
		label = 'Incorrect'
		color = sharedStyles.colors.red
	} else {
		color = 'black'
	}

	useBGColor(affectBackground ? color : undefined)

	useWatchValue(state.type, (prev) => {
		if (state.type === 'yourBuzz') {
			playSound('yourBuzz')
		} else if (state.type === 'alreadyBuzzed') {
			// Got it wrong
			playSound('shortBuzz')
		} else if (state.type === 'canBuzz') {
			playSound('warmChime')
		}
	})

	return (
		<Box
			css={containerStyle}
			onClick={state.type === 'canBuzz' && interactive ? onBuzzIn : undefined}
		>
			<Box
				css={[
					buzzViewStyle,
					{
						border: '4px solid rgba(0,0,0,0.05)',
					},
					interactive && {
						borderColor: sharedStyles.colors.white,
						cursor: 'pointer',
					},
					{ backgroundColor: color, color: textColor },
				]}
			>
				<Box>{label}</Box>
			</Box>
		</Box>
	)
}
const containerStyle = {
	position: 'fixed',
	bottom: 0,
	left: 0,
	width: '100%',
	height: 180,
	display: 'flex',
	padding: theme.space[4],
} as any
const buzzViewStyle = [
	{
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: 30,
		fontWeight: 'bold',
		width: '100%',
		padding: 30,
		textAlign: 'center',
		userSelect: 'none',
	},
	sharedStyles.boxBase,
] as any
