import React, { ReactNode } from 'react'
import { GameplayState } from '../../entities/GameplayState'
import GameplayMusic from '../../game/GameplayMusic'
import QueryView from '../../views/QueryView'
import { ActiveChallengeView } from './ActiveChallengeView'
import PlayerChallengeView from './PlayerChallengeView'
import { Props } from './PlayGameScreen'
import { PostChallengeView } from './PostChallengeView'
export function ActiveGameView({
	game,
	user,
	team,
	gameplayState,
}: Props & {
	gameplayState: GameplayState
}) {
	let el: ReactNode

	if (
		gameplayState.status === 'respondingActive' ||
		gameplayState.status === 'respondingInactive'
	) {
		const view = gameplayState.currentView
		el = (
			<>
				{view.type === 'info' ? (
					<QueryView query={view.query} />
				) : team ? (
					// Player
					<ActiveChallengeView
						game={game}
						user={user}
						team={team}
						view={view}
						key={view.slideIndex}
						respondingDisabled={gameplayState.status === 'respondingInactive'}
					/>
				) : (
					// Viewer
					// TODO: Also show BuzzActionView here
					// to display who has the current buzz.
					<PlayerChallengeView
						key={view.slideIndex}
						challenge={view.challenge}
						challengeIndex={view.challengeIndex}
						hostId={game.creatorId}
					/>
				)}
			</>
		)
	} else if (gameplayState.status === 'questionEnded') {
		const view = gameplayState.currentView
		if (view.type !== 'challenge') {
			// This shouldn't happen
			return <>Nothing to show</>
		}

		el = (
			<PostChallengeView
				info={gameplayState.completedChallenges[view.slideIndex]}
				game={game}
				teamId={team?.id}
				challengeIndex={view.challengeIndex}
				endMode={view.last}
				showLeaderboard={view.last}
				buzzMode={view.challenge.settings.buzzIn}
			/>
		)
	} else if (gameplayState.status === 'gameEnded') {
		el = <>Game ended</>
	} else {
		el = <>Unknown state</>
	}

	return (
		<>
			<GameplayMusic gameplayState={gameplayState} />
			{el}
		</>
	)
}
