import { ReactElement, useContext } from 'react'
import { GameQuery } from '../gameplay/core/types'
import { getQueryHandler } from '../gameplay/handlers'
import ActiveGameInfoContext from '../gameplay/ActiveGameInfoContext'

interface Props {
	query: GameQuery
}

export default function QueryView({ query }: Props) {
	const info = useContext(ActiveGameInfoContext)!
	return getQueryHandler(query.type).render(query.data, info) as ReactElement
}
