import {
	deserializeRecord,
	deserializeRecords,
	normalizeResourceAndOptions,
} from './FBResource'
import { FBId, FBResourceAndOptions, FBValue } from './types'

export default async function getRecord<T, V extends FBValue, O>(
	db: firebase.database.Database,
	r: FBResourceAndOptions<T, V, O>,
	id: FBId
) {
	const [resource, path, options] = normalizeResourceAndOptions(r)

	const snapshot = await db.ref(path + '/' + id).once('value')
	return deserializeRecord(resource, id, snapshot.val(), options)
}
export async function getRecords<T, V extends FBValue, O>(
	db: firebase.database.Database,
	r: FBResourceAndOptions<T, V, O>
) {
	const [resource, path, options] = normalizeResourceAndOptions(r)

	const snapshot = await db.ref(path).once('value')
	return deserializeRecords(resource, snapshot.val(), options)
}
