import { useState, useCallback } from 'react'

export default function useElementRef<T>() {
	const [element, setElement] = useState<T | null>(null)

	const ref = useCallback((el: T | null) => {
		setElement(el)
	}, [])

	return [element, ref] as const
}
